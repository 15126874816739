import axios from "axios";

export const getApplicationRoles = async ({ userId }: { userId: string }) => {
  const whereQuery = {
    account: userId,
    application: process.env.REACT_APP_APPLICATION_ID,
  };

  return await axios.get(
    process.env.REACT_APP_HELLOAR_API + "application_roles",
    {
      params: {
        where: whereQuery,
      },
    }
  );
};
