import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";
import { useAppDispatch } from "../hooks/hooks";
import { Order } from "../types/types";
import { patchOrder } from "../utils/ordersApi";
import {
  configDataConstants,
  ordersConstants,
  tableInfoConstants,
  tableTypeConstants,
} from "../store/actions/types";
import { ConfData3 as workstation } from "../const/const";
import { ConfData3 as meeting } from "../const/meetingTables";
import { ConfData3 as conference } from "../const/conferenceTables";
import { ConfData3 as discussion } from "../const/discussionTables";
import { ConfData3 as cabinTable } from "../const/cabinTablesNew";
import { ConfData3 as neoWorkstation } from "../const/neoWorkstations";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import { makeSessionRequest } from "../utils/makeSessionRequest";

function Table({ columns, data, order }: any) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const deleteProduct = async (order: Order, productIndex: number) => {
    const productToDelete = order.cart_items[productIndex];
    const filteredProducts = order.cart_items.filter(
      (product: any, index: number) => index !== productIndex
    );
    order.cart_items = filteredProducts;
    try {
      const data = await patchOrder(order);
      dispatch({
        type: ordersConstants.UPDATE_ORDER,
        payload: {
          ...order,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        },
      });
      toast.success("Product deleted successfully");
      makeSessionRequest("deleted_product", {
        orderId: order._id,
        product: productToDelete,
      });
    } catch (error) {
      toast.error("Failed to delete product.");
    }
  };

  const updateProduct = async (order: Order, productIndex: number) => {
    makeSessionRequest("editing_product", {
      orderId: order._id,
      product: order.cart_items[productIndex],
    });
    const currentTableType = order.cart_items[productIndex].configuration.type;
    const currentTableLength =
      order.cart_items[productIndex].configuration.Length;
    const currentTableWidth =
      order.cart_items[productIndex].configuration.Width;
    if (currentTableType === "Meeting Table") {
      dispatch({ type: configDataConstants.SET_CONFIG_DATA, payload: meeting });
    } else if (currentTableType === "Conference Table") {
      dispatch({
        type: configDataConstants.SET_CONFIG_DATA,
        payload: conference,
      });
    } else if (currentTableType === "Discussion Table") {
      dispatch({
        type: configDataConstants.SET_CONFIG_DATA,
        payload: discussion,
      });
    } else if (currentTableType === "Cabin Table") {
      dispatch({
        type: configDataConstants.SET_CONFIG_DATA,
        payload: cabinTable,
      });
    } else if (
      currentTableType.includes("main partition") ||
      currentTableType.includes("main + return partition")
    ) {
      dispatch({
        type: configDataConstants.SET_CONFIG_DATA,
        payload: neoWorkstation,
      });
    } else {
      dispatch({
        type: configDataConstants.SET_CONFIG_DATA,
        payload: workstation,
      });
    }
    dispatch({ type: ordersConstants.SET_CURRENT_ORDER, payload: order });
    dispatch({
      type: tableTypeConstants.SET_TABLE_TYPE,
      payload: currentTableType.includes("SHARING")
        ? "Workstation"
        : currentTableType.includes("main partition") ||
          currentTableType.includes("main + return partition")
        ? "Neo WKS"
        : currentTableType,
    });
    dispatch({
      type: tableInfoConstants.SET_TABLE_INFO,
      payload: `${currentTableLength}W x ${currentTableWidth}D`,
    });
    history.push("/configurator/" + productIndex);
  };

  return (
    <table {...getTableProps()} className=" border border-b">
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr
            className=" border-0 border-b-2 text-left px-2"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any) => (
              <th className="p-4 px-8" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          return (
            <tr className="text-left border-0 border-t" {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td className="py-2 px-8" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
              <td>
                <img
                  src={editIcon}
                  className="cursor-pointer mr-4"
                  alt=""
                  onClick={() => updateProduct(order, i)}
                />
              </td>
              <td>
                <img
                  src={deleteIcon}
                  className="cursor-pointer mr-4"
                  alt=""
                  onClick={() => deleteProduct(order, i)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ProductsTable({ order }: { order: Order }) {
  const { cart_items } = order;
  const [isUpdatingQty, setIsUpdatingQty] = useState(false);
  const dispatch = useAppDispatch();
  const data = useMemo(
    () =>
      cart_items.map((product) => {
        return {
          ...product,
          totalPrice: (product.bom.totalPrice || "---") + " ₹",
        };
      }),
    [cart_items]
  );

  const changeQuantity = useCallback(
    (productIdx: number, increment: number) => {
      const updatedOrder = { ...order };
      const updatedProduct = { ...updatedOrder.cart_items[productIdx] };
      if (updatedProduct.quantity + increment < 1) {
        return;
      }
      if (increment > 1) {
        if (increment !== updatedProduct.quantity) {
          updatedProduct.quantity = increment;
        } else return;
      } else {
        updatedProduct.quantity = updatedProduct.quantity + increment;
      }
      // updatedProduct.cost =
      //   updatedProduct.quantity * (updatedProduct.cost || 0);
      updatedOrder.cart_items[productIdx] = updatedProduct;

      try {
        setIsUpdatingQty(true);
        patchOrder(updatedOrder).then((data) => {
          dispatch({
            type: ordersConstants.UPDATE_ORDER,
            payload: JSON.parse(JSON.stringify(updatedOrder)),
          });
          toast.success("Product updated successfully");
          makeSessionRequest("update_quantity", {
            orderId: order._id,
            productIndex: productIdx,
            quantity: updatedOrder.cart_items[productIdx].quantity,
          });
          setIsUpdatingQty(false);
        });
      } catch (error) {
        toast.error("Failed to update product.");
        makeSessionRequest("update_quantity_failed", {
          orderId: order._id,
          productIndex: productIdx,
          quantity: updatedOrder.cart_items[productIdx].quantity,
        });
        setIsUpdatingQty(false);
      }
    },
    [dispatch, order]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        Cell: ({ row }: any) => (
          <div className="max-w-md">
            <div className="font-bold mb-2">
              {row.original.configuration.name}
            </div>
            <div className="flex flex-col">
              Description:
              <textarea
                disabled
                style={{ minHeight: "6rem", resize: "none" }}
                className="h-24 w-72 overflow-scroll border px-2 max-h-24"
              >
                {row.original.product.split(", ").join("\n")}
              </textarea>
            </div>
          </div>
        ),
      },
      {
        Header: () => "Qty",
        id: "qty",
        Cell: ({ row, index }: any) => {
          return (
            <span className="flex ">
              <button
                disabled={isUpdatingQty}
                onClick={() => changeQuantity(row.index, -1)}
                className="px-2 mr-1 bg-gray-400"
              >
                -
              </button>
              <input
                id={row.original.thumbnail}
                type="number"
                placeholder={row.original.quantity}
                onChange={(e) => {
                  let timer: any;
                  console.log(timer);
                  if (timer) {
                    clearTimeout(timer);
                  }
                  timer = setTimeout(
                    () => changeQuantity(row.index, Number(e.target.value)),
                    800
                  );
                }}
                className={"w-16 text-center placeholder-black"}
                onFocus={() => {
                  const ele: any = document.getElementById(
                    row.original.thumbnail
                  );
                  ele.placeholder = "";
                }}
                onBlur={() => {
                  const ele: any = document.getElementById(
                    row.original.thumbnail
                  );
                  ele.placeholder = row.original.quantity;
                }}
              />{" "}
              {/*add input element here*/}
              <button
                disabled={isUpdatingQty}
                onClick={() => changeQuantity(row.index, 1)}
                className="px-2 ml-1 bg-gray-400"
              >
                +
              </button>
            </span>
          );
        },
      },
      {
        Header: "Total Price",
        // accessor: "cost",
        Cell: ({ row }: any) => (
          <span>Rs {row.original.quantity * row.original.cost}</span>
        ),
      },
      {
        Header: () => "Thumbnail",
        id: "expander",
        Cell: ({ row }: any) => (
          <span>
            {/* <img
              className="w-24 ml-auto mr-auto"
              src={row.original.thumbnail}
              alt=""
            /> */}
            -
          </span>
        ),
      },
    ],
    [changeQuantity, isUpdatingQty]
  );
  if (!data || data.length === 0) return <div>No products added</div>;

  return <Table columns={columns} data={data} order={order} />;
}

export default ProductsTable;
