// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "4 Seater",
            size: 4,
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/meet/rec.png",
            productId: "664f063a9cb5c9d4cb118d4e",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "6 Seater",
            size: 6,
            Length: 2100,
            Depth: 1050,
            thumbnail: "/assets/system/meeting2.png",
            productId: "66521e3116d83b3e289b3c97",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "8 Seater",
            size: 8,
            Length: 2400,
            Depth: 1200,
            thumbnail: "/assets/system/meeting3.png",
            productId: "665220f05d2b44bc65588941",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "10 Seater",
            size: 10,
            Length: 2500,
            Depth: 1300,
            thumbnail: "/assets/system/meeting3.png",
            productId: "665223f374d489b5a574992b",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "12 Seater",
            size: 12,
            Length: 2600,
            Depth: 1400,
            thumbnail: "/assets/system/meeting3.png",
            productId: "66522995a7cd89e8c2ecf122",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "14 Seater",
            size: 14,
            Length: 2700,
            Depth: 1500,
            thumbnail: "/assets/system/meeting3.png",
            productId: "66522bd278daed4e166133e8",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
        ],
      },
    ],
  },
  {
    "name": "Legs",
    "multiSelect": false,
    "options": [
      {
        "name": "Meeting Table",
        "required": false,
        "options": [
          {
            "name": "Legs",
            "required": false,
            "options": [
              {
                "name": "Eco",
                "thumbnail": "/assets/components/Colors-D3.png",
                "rules": [
                  {
                    "type": "replacePart",
                    "node": "left_leg",
                    "pid": "https://content.helloviewer.io/uploads/4d06dd8b-e056-4d52-ae50-2f32c3d1158d/24_4_2024/1716539829926/ECO-LEFT-LEG.glb"
                  },
                  {
                    "type": "replacePart",
                    "node": "right_leg",
                    "pid": "https://content.helloviewer.io/uploads/4412d201-ffc9-4d53-8f08-174adb5ce5b7/24_4_2024/1716539791391/ECO-RIGHT-LEG.glb"
                  },
                  {
                    "type": "show",
                    "node": "connecter"
                  },
                  {
                    "type": hide,
                    "node": "IOconnecter"
                  }
                ]
              },
              {
                "name": "@Work",
                "thumbnail": "/assets/components/Connect-D3.png",
                "rules": [
                  {
                    "type": "replacePart",
                    "node": "left_leg",
                    "pid": "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb"
                  },
                  {
                    "type": "replacePart",
                    "node": "right_leg",
                    "pid": "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb"
                  },
                  {
                    "type": hide,
                    "node": "connecter"
                  },
                  {
                    "type": show,
                    "node": "IOconnecter"
                  }
                ]
              },
              {
                "name": "Scope",
                "thumbnail": "/assets/components/Collaborate-D3.png",
                "rules": [
                  {
                    "type": "replacePart",
                    "node": "left_leg",
                    "pid": "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb"
                  },
                  {
                    "type": "replacePart",
                    "node": "right_leg",
                    "pid": "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb"
                  },
                  {
                    "type": show,
                    "node": "connecter"
                  },
                  {
                    "type": hide,
                    "node": "IOconnecter"
                  }
                ]
              },
              {
                "name": "Io",
                "thumbnail": "/assets/components/Synergy-D3.png",
                "rules": [
                  {
                    "type": "hide",
                    "node": "connecter"
                  },
                  {
                    "type": show,
                    "node": "IOconnecter"
                  },
                
                  {
                    "type": "replacePart",
                    "node": "left_leg",
                    "pid": "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb"
                  },
                  {
                    "type": "replacePart",
                    "node": "right_leg",
                    "pid": "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb"
                  },
                ]
              },
              // {
              //   "name": "Pole",
              //   "thumbnail": "/assets/components/Perform-D3.png",
              //   "rules": [
              //     {
              //       "type": "replacePart",
              //       "node": "left_leg",
              //       "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
              //     },
              //     {
              //       "type": "replacePart",
              //       "node": "right_leg",
              //       "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
              //     },
              //     {
              //       "type": "hide",
              //       "node": "connecter"
              //     }
              //   ],
              
              // },
              // {
              //   "name": "Styl",
              //   "thumbnail": "/assets/components/SwitchAngularSharing.png",
              //   "rules": [
              //     {
              //       "type": "replacePart",
              //       "node": "left_leg",
              //       "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
              //     },
              //     {
              //       "type": "replacePart",
              //       "node": "right_leg",
              //       "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
              //     },
              //     {
              //       "type": "hide",
              //       "node": "connecter"
              //     }
              //   ]
              // },
              // {
              //   "name": "Europa",
              //   "thumbnail": "/assets/components/SwitchAngularSharing.png",
              //   "rules": [
              //     {
              //       "type": "replacePart",
              //       "node": "left_leg",
              //       "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
              //     },
              //     {
              //       "type": "replacePart",
              //       "node": "right_leg",
              //       "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
              //     },
              //     {
              //       "type": "hide",
              //       "node": "connecter"
              //     }
              //   ]
              // }
            ]
          }
        
      
    ]
  },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      
    
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/snake.png",
                rules: [
                  {
                    type: hide,
                    node: ["gorment","FlipU"],
                  
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                    
                  },
                ],
              },
         
              {
                name: "Flip-Up",
                thumbnail: "/assets/components/snake.png",
                rules: [
                  {
                    type: show,
                    node: ["FlipU"],
                    
                  },
                  {
                    type: hide,
                    node: ["Table_top"],
                  
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          
          {
              "name": "Mangfall_Beech",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg"
                  }
              ]
          },
          {
              "name": "Acacia",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg"
                  }
              ]
          },
          {
              "name": "Urban_Teak",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg"
                  }
              ]
          },
          {
              "name": "SILVER_GREY_DIFFUSE",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png"
                  }
              ]
          },
          {
              "name": "Off_White_diffuse",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png"
                  }
              ]
          },
          {
              "name": "Graphite_Grey_diffuse",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png"
                  }
              ]
          },
          {
              "name": "Cairo_Walnut_BaseColor",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png"
                  }
              ]
          },
          {
              "name": "Maldau_Acacia_BaseColor",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png"
                  }
              ]
          },
          {
              "name": "plain_DefaultMaterial_BaseColor",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png"
                  }
              ]
          },
          {
              "name": "Thansau_Maple_BaseColor",
              "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
              "rules": [
                  {
                      "type": "applyMaterial",
                      "node": "Wood",
                      "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png"
                  }
              ]
          }
      ]
    },
  ]
},
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: false,

          options: [
 
          
            {
                "name": "Graphite_grey",
                "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                "rules": [
                    {
                        "type": "applyMaterial",
                        "node": "Legs",
                        "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg"
                    }
                ]
            },
            {
                "name": "Pure_white",
                "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
                "rules": [
                    {
                        "type": "applyMaterial",
                        "node": "Legs",
                        "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg"
                    }
                ]
            },
            {
                "name": "Nimbus_grey",
                "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
                "rules": [
                    {
                        "type": "applyMaterial",
                        "node": "Legs",
                        "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg"
                    }
                ]
            },
            {
                "name": "Super_matt_black",
                "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
                "rules": [
                    {
                        "type": "applyMaterial",
                        "node": "Legs",
                        "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg"
                    }
                ]
            }
        ]
      },
    ]
  }
]);

export type ConfigData = typeof ConfData3;
