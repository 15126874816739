import React, { useEffect, useRef, useState } from "react";
import CategorySelector from "../Layouts/CategorySelector";
import Preview from "../Layouts/Preview";
import PrimaryBar from "../Layouts/PrimaryBar";
import SecondaryBar from "../Layouts/SecondaryBar";
import Header from "../Layouts/Header";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  configDataConstants,
  configurationConstants,
  tableInfoConstants,
  tableTypeConstants,
} from "../store/actions/types";
import { Feature, Option, Order } from "../types/types";
import { useParams } from "react-router-dom";
import useUserConfiguration from "../utils/updateUserConfiguration";
import { ConfData3 as workStation } from "../const/const";
import { makeSessionRequest } from "../utils/makeSessionRequest";

function Configurator() {
  const [secondaryBarHidden, setSecondaryBarHidden] = useState(true);
  const [highlightSecondaryBar, setHighlightSecondaryBar] = useState(false);
  const [showCategorySelector, setShowCategorySelector] = useState(true);
  const [isStartingFromScratch, setIsStartingFromScratch] = useState<
    undefined | boolean
  >(undefined);
  const [windowWidth, setWindowWidth] = useState<number | undefined>();
  const productViewerRef = useRef<undefined | any>();
  let { productId } = useParams<{ productId: string | undefined }>();

  const dispatch = useAppDispatch();

  const { updateUserConfiguration } = useUserConfiguration();

  const currentOrder: Order = useAppSelector(
    (state) => state.orders.currentOrder
  );

  const userConfiguration2: any = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );
  useEffect(() => {
    if (userConfiguration2.Legs !== "Colors") {
      // console.log(userConfiguration2, "user2");
      dispatch({
        type: configurationConstants.DISABLE_FEATURES,
        payload: ["Color Options"],
      });
    }
  }, [dispatch, userConfiguration2]);

  const specs = useAppSelector((state) => state.configData.original);
  // const table = useAppSelector((state) => state.tableType.table);

  useEffect(() => {
    makeSessionRequest("configurator_view");
  }, []);

  useEffect(() => {
    let userConfiguration: any;
    if (currentOrder?.cart_items.length === 0) {
      setIsStartingFromScratch(false);
    }
    if (productId) {
      setIsStartingFromScratch(false);
      userConfiguration =
        currentOrder?.cart_items &&
        productId &&
        currentOrder.cart_items[
          parseInt(productId || `${currentOrder.cart_items.length - 1 || 0}`)
        ]?.configuration;
    } else if (
      isStartingFromScratch === false &&
      currentOrder?.cart_items.length > 0
    ) {
      userConfiguration =
        currentOrder?.cart_items && currentOrder.cart_items[0]?.configuration;
      console.log(userConfiguration, "userconfig2");
      dispatch({
        type: tableInfoConstants.SET_TABLE_INFO,
        payload:
          userConfiguration.Length && userConfiguration.Depth
            ? `${userConfiguration.Length}W x ${userConfiguration.Width}D`
            : `${userConfiguration.Dia}Dia`,
      });
      dispatch({
        type: tableTypeConstants.SET_TABLE_TYPE,
        payload:
          userConfiguration.type === "SHARING" ||
          userConfiguration.type === "NON SHARING"
            ? "Workstation"
            : userConfiguration.type,
      });
    } else if (currentOrder && currentOrder.cart_items.length === 0) {
      userConfiguration =
        currentOrder?.cart_items &&
        currentOrder.cart_items[
          parseInt(productId || `${currentOrder.cart_items.length - 1 || 0}`)
        ]?.configuration;
    }

    if (!userConfiguration) {
      return;
    } else {
      if (userConfiguration.Legs === "Colors") {
        dispatch({
          type: configurationConstants.ENABLE_FEATURES,
          payload: ["Color Options"],
        });
      }
    }

    const Feature = specs.find((s) => s.name === "System");
    const subFeature = (Feature?.options as Feature[])?.find(
      (s) => s.name === userConfiguration.type
    );
    const option = (subFeature?.options as Option[])?.find(
      (s) => s.size === userConfiguration.noOfSeats
    );

    option &&
      updateUserConfiguration("System", {
        name: subFeature?.name || "",
        option,
      } as any);

    dispatch({
      type: configurationConstants.UPDATE_CONFIGURATION2,
      payload: { ...userConfiguration },
    });
    setShowCategorySelector(false);

    //causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, currentOrder, specs, isStartingFromScratch]);

  useEffect(() => {
    if (currentOrder && currentOrder.cart_items.length === 0) {
      dispatch({
        type: configDataConstants.SET_CONFIG_DATA,
        payload: workStation,
      });
    }
  }, [currentOrder, dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: configurationConstants.SET_CONFIGURATION,
        payload: null,
      });
      dispatch({
        type: configurationConstants.RESET_CONFIGURATION,
      });
    };
  }, [currentOrder?.cart_items, dispatch]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    return () => {};
  }, []);

  const blinkSecondaryBar = () => {
    setHighlightSecondaryBar(true);
    const interval = 150;
    setTimeout(() => {
      setHighlightSecondaryBar(false);
    }, interval);
    setTimeout(() => {
      setHighlightSecondaryBar(true);
    }, interval * 2);
    setTimeout(() => {
      setHighlightSecondaryBar(false);
    }, interval * 3);
  };

  const closeSecondaryBar = () => {
    setSecondaryBarHidden(true);
  };

  if ((windowWidth && windowWidth < 400) || window.innerWidth < 400) {
    return (
      <div className="flex h-screen items-center justify-center">
        please switch to landscape mode
      </div>
    );
  }

  return (
    <div
      style={{ backgroundColor: "#fff", height: window.innerHeight }}
      className="App"
    >
      <div className="flex overflow-hidden overflow-y-auto w-screen flex-col h-full relative">
        {showCategorySelector ? (
          <CategorySelector
            setShowCategorySelector={setShowCategorySelector}
            setIsStartingFromScratch={setIsStartingFromScratch}
            isStartingFromScratch={isStartingFromScratch}
          />
        ) : (
          <div className="h-full flex items-center">
            <Header productViewerRef={productViewerRef} />
            <Preview productViewerRef={productViewerRef} />
           
            <PrimaryBar
              secondaryBarHidden={secondaryBarHidden}
              setSecondaryBarHidden={setSecondaryBarHidden}
              blinkSecondaryBar={blinkSecondaryBar}
            />
            <SecondaryBar
              closeSecondaryBar={closeSecondaryBar}
              secondaryBarHidden={secondaryBarHidden}
              highlightSecondaryBar={highlightSecondaryBar}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Configurator;
