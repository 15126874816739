
import axios from 'axios';

const baseUrl = process.env.REACT_APP_HELLOAR_API;

const getAccessToken = () => {
    return window.localStorage.getItem('accessToken');
  };

const getAuthHeader = async () => {
  const auth = getAccessToken();
  return auth;
};

export const fetchProduct = async (productId, embedApplication = false) => {
    try {
      const authHeader = await getAuthHeader();
  
      const embeddedQuery = { application: 1 };
      const config = {
        url: `${baseUrl}application_products/${productId}`,
        method: 'get',
        params: { ...(embedApplication && { embedded: embeddedQuery }) },
        headers: { Authorization: authHeader }
      };
      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      if (err.response.status === 404) {
        const errMsg = 'This product does not exist on the server';
        throw errMsg;
      } else {
        throw err.response ? err.response : "Can't connect to server :(";
      }
    }
  };
  