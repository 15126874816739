import { userConstants } from "../actions/types";

type UserState = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  isAuthenticated: boolean;
  verified: boolean;
  profilePicture: string;
  role?: number;
};

const initialState: UserState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  token: "",
  isAuthenticated: false,
  profilePicture: "",
  verified: false,
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case userConstants.SET_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        firstName: "",
        lastName: "",
        email: "",
        token: "",
        isAuthenticated: false,
        profilePicture: "",
        verified: false,
      };
    default:
      return state;
  }
};
