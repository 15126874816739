// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "CABIN MAIN TABLE",
            size: 1,

            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/cabintable/main.png",
            productId: "667d5d5b8ffa2e9a7e5386e7",
          },
          {
            name: "CABIN MAIN TABLE WITH RETURN TABLE",
            size: 2,

            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/cabintable/mainR.png",
            productId: "66825d66515770a29fb78ce1",
          },
        ],
      },
    ],
  },
  // {
  //   name: "Dimensions",
  //   multiSelect: true,
  //   options: [
  //     {
  //       name: "Cabin Table",
  //       multiselect: false,
  //       options: [
  //         {
  //           name: "Width",
  //           required: false,
  //           dropDown: true,
  //           options: [
  //             { name: "900 mm", value: 900 },
  //             { name: "1050 mm", value: 1050 },
  //             { name: "1200 mm", value: 1200 },
  //             { name: "1350 mm", value: 1350 },
  //             { name: "1500 mm", value: 1500 },
  //             { name: "1650 mm", value: 1650 },
  //             { name: "1800 mm", value: 1800 },
  //             { name: "1950 mm", value: 1950 },
  //             { name: "2100 mm", value: 2100 },
  //             { name: "2400 mm", value: 2400 },
  //           ],
  //         },
  //         {
  //           name: "Depth",
  //           required: false,
  //           dropDown: true,
  //           options: [
  //             { name: "600 mm", value: 600 },
  //             { name: "750 mm", value: 750 },
  //             { name: "900 mm", value: 900 },
  //           ],
  //         },
  //         {
  //           name: "Height",
  //           required: false,
  //           dropDown: true,
  //           options: [{ name: "750 mm", value: 750 }],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        multiselect: false,

        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              { name: "900 mm", value: 900 },
              { name: "1050 mm", value: 1050 },
              { name: "1200 mm", value: 1200 },
              { name: "1350 mm", value: 1350 },
              { name: "1500 mm", value: 1500 },
              { name: "1650 mm", value: 1650 },
              { name: "1800 mm", value: 1800 },
              { name: "1950 mm", value: 1950 },
              { name: "2100 mm", value: 2100 },
              { name: "2400 mm", value: 2400 },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Cabin Table",
                        values: ["CABIN MAIN TABLE"],
                      },
                    ],
                    node: "all",
                    pid: "https://content.helloviewer.io/uploads/1a781db7-4b1d-472b-91c6-0100a16dba93/1_7_2024/1722509734962/600-mm-CABIN-MAIN-TABLES.glb",
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "chamfer_edge",
                  //     "chamferedge_flipup",
                  //     "connectors",
                  //     "legs_30*60_strip",
                  //     "legs_40*40_loop",
                  //     "legs_40*40_slanted",
                  //     "legs_deskpro_slant",
                  //     "legs_deskpro_straight",
                  //     "legs_Livo",
                  //     "legs_proceed",
                  //     "legs_sleek",
                  //     "legs_triangular_loop",
                  //     "legs_triangular_slant",
                  //     "legs_triangular_straight",
                  //     "legs_curvivo",
                  //     "metalmodesty",
                  //     "metalmodesty_proceed",
                  //     "metalmodesty_side",
                  //     "metalmodesty_slant",
                  //     "metalmodesty_sleek",
                  //     "metalmodesty_sleek_livo",
                  //     "woodmodesty",
                  //     "woodmodesty_proceed",
                  //     "woodmodesty_side",
                  //     "woodmodesty_slant",
                  //     "woodmodesty_sleek",
                  //     "woodmodesty_sleek_livo",
                  //     "cpu_stand",
                  //     "DUAL_MONITOR_ARM_1",
                  //     "grommet",
                  //     "hangingcpustand",
                  //     "hangingmetalbaghook",
                  //     "keyboardmousetray",
                  //     "keyboardtray",
                  //     "POWER_DESKPORT",
                  //     "PVC_SNAKE_RISER",
                  //     "ROUND_POWER_DESKPORT",
                  //     "SINGLE_MONITOR_ARM_1",
                  //     "sliderpowerdeskport",
                  //   ],

                  // },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Cabin Table",
                        values: ["CABIN MAIN TABLE WITH RETURN TABLE"],
                      },
                    ],
                    node: "all",
                    pid: "https://content.helloviewer.io/uploads/5437101c-24c7-4e62-acea-29b7f8904ade/1_7_2024/1722509483935/600MM-CABIN-MAIN-RETURN-TABLES--.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "chamfer_edge",
                      "chamferedge_flipup",
                      "connectors",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_Livo",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_curvivo",
                      "metalmodesty",
                      "metalmodesty_proceed",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "metalmodesty_sleek_livo",
                      "woodmodesty",
                      "woodmodesty_proceed",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                      "woodmodesty_sleek_livo",
                      "cpu_stand",
                      "DUAL_MONITOR_ARM_1",
                      "grommet",
                      "hangingcpustand",
                      "hangingmetalbaghook",
                      "keyboardmousetray",
                      "keyboardtray",
                      "POWER_DESKPORT",
                      "PVC_SNAKE_RISER",
                      "ROUND_POWER_DESKPORT",
                      "SINGLE_MONITOR_ARM_1",
                      "sliderpowerdeskport",
                      "flipup",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "metalmodesty_gable",
                      "woodmodesty_gable",
                      "vertical_raiser",
                    ],
                  },
                ],
              },
              {
                name: "750 mm",
                value: 750,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Cabin Table",
                        values: ["CABIN MAIN TABLE"],
                      },
                    ],
                    node: "all",
                    pid: "https://content.helloviewer.io/uploads/cda3c11c-7aab-4ffb-9855-b0e559f30b10/1_7_2024/1722509682785/CABIN-MAIN-TABLES.glb",
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "chamfer_edge",
                  //     "chamferedge_flipup",
                  //     "connectors",
                  //     "legs_30*60_strip",
                  //     "legs_40*40_loop",
                  //     "legs_40*40_slanted",
                  //     "legs_deskpro_slant",
                  //     "legs_deskpro_straight",
                  //     "legs_Livo",
                  //     "legs_proceed",
                  //     "legs_sleek",
                  //     "legs_triangular_loop",
                  //     "legs_triangular_slant",
                  //     "legs_triangular_straight",
                  //     "legs_curvivo",
                  //     "metalmodesty",
                  //     "metalmodesty_proceed",
                  //     "metalmodesty_side",
                  //     "metalmodesty_slant",
                  //     "metalmodesty_sleek",
                  //     "metalmodesty_sleek_livo",
                  //     "woodmodesty",
                  //     "woodmodesty_proceed",
                  //     "woodmodesty_side",
                  //     "woodmodesty_slant",
                  //     "woodmodesty_sleek",
                  //     "woodmodesty_sleek_livo",
                  //     "cpu_stand",
                  //     "DUAL_MONITOR_ARM_1",
                  //     "grommet",
                  //     "hangingcpustand",
                  //     "hangingmetalbaghook",
                  //     "keyboardmousetray",
                  //     "keyboardtray",
                  //     "POWER_DESKPORT",
                  //     "PVC_SNAKE_RISER",
                  //     "ROUND_POWER_DESKPORT",
                  //     "SINGLE_MONITOR_ARM_1",
                  //     "sliderpowerdeskport"

                  //   ],
                  // },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Cabin Table",
                        values: ["CABIN MAIN TABLE WITH RETURN TABLE"],
                      },
                    ],
                    node: "all",
                    pid: "https://content.helloviewer.io/uploads/0f421b45-f6ac-412d-a9a2-646295c279ea/1_7_2024/1722509576445/CABIN-MAIN-RETURN-TABLES--.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "chamfer_edge",
                      "chamferedge_flipup",
                      "connectors",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_Livo",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_curvivo",
                      "metalmodesty",
                      "metalmodesty_proceed",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "metalmodesty_sleek_livo",
                      "woodmodesty",
                      "woodmodesty_proceed",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                      "woodmodesty_sleek_livo",
                      "cpu_stand",
                      "DUAL_MONITOR_ARM_1",
                      "grommet",
                      "hangingcpustand",
                      "hangingmetalbaghook",
                      "keyboardmousetray",
                      "keyboardtray",
                      "POWER_DESKPORT",
                      "PVC_SNAKE_RISER",
                      "ROUND_POWER_DESKPORT",
                      "SINGLE_MONITOR_ARM_1",
                      "sliderpowerdeskport",
                      "flipup",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "metalmodesty_gable",
                      "woodmodesty_gable",
                      "vertical_raiser",
                    ],
                  },
                ],
              },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Cabin Table",
                        values: ["CABIN MAIN TABLE"],
                      },
                    ],
                    node: "all",
                    pid: "https://content.helloviewer.io/uploads/cda3c11c-7aab-4ffb-9855-b0e559f30b10/1_7_2024/1722509682785/CABIN-MAIN-TABLES.glb",
                  },
                  // {
                  //   type: hide,
                  //   node: [s
                  //     "chamfer_edge",
                  //     "chamferedge_flipup",
                  //     "connectors",
                  //     "legs_30*60_strip",
                  //     "legs_40*40_loop",
                  //     "legs_40*40_slanted",
                  //     "legs_deskpro_slant",
                  //     "legs_deskpro_straight",
                  //     "legs_Livo",
                  //     "legs_proceed",
                  //     "legs_sleek",
                  //     "legs_triangular_loop",
                  //     "legs_triangular_slant",
                  //     "legs_triangular_straight",
                  //     "legs_curvivo",
                  //     "metalmodesty",
                  //     "metalmodesty_proceed",
                  //     "metalmodesty_side",
                  //     "metalmodesty_slant",
                  //     "metalmodesty_sleek",
                  //     "metalmodesty_sleek_livo",
                  //     "woodmodesty",
                  //     "woodmodesty_proceed",
                  //     "woodmodesty_side",
                  //     "woodmodesty_slant",
                  //     "woodmodesty_sleek",
                  //     "woodmodesty_sleek_livo",
                  //     "cpu_stand",
                  //     "DUAL_MONITOR_ARM_1",
                  //     "grommet",
                  //     "hangingcpustand",
                  //     "hangingmetalbaghook",
                  //     "keyboardmousetray",
                  //     "keyboardtray",
                  //     "POWER_DESKPORT",
                  //     "PVC_SNAKE_RISER",
                  //     "ROUND_POWER_DESKPORT",
                  //     "SINGLE_MONITOR_ARM_1",
                  //     "sliderpowerdeskport"

                  //   ],
                  // },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Cabin Table",
                        values: ["CABIN MAIN TABLE WITH RETURN TABLE"],
                      },
                    ],
                    node: "all",
                    pid: "https://content.helloviewer.io/uploads/0f421b45-f6ac-412d-a9a2-646295c279ea/1_7_2024/1722509576445/CABIN-MAIN-RETURN-TABLES--.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "chamfer_edge",
                      "chamferedge_flipup",
                      "connectors",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_Livo",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_curvivo",
                      "metalmodesty",
                      "metalmodesty_proceed",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "metalmodesty_sleek_livo",
                      "woodmodesty",
                      "woodmodesty_proceed",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                      "woodmodesty_sleek_livo",
                      "cpu_stand",
                      "DUAL_MONITOR_ARM_1",
                      "grommet",
                      "hangingcpustand",
                      "hangingmetalbaghook",
                      "keyboardmousetray",
                      "keyboardtray",
                      "POWER_DESKPORT",
                      "PVC_SNAKE_RISER",
                      "ROUND_POWER_DESKPORT",
                      "SINGLE_MONITOR_ARM_1",
                      "sliderpowerdeskport",
                      "flipup",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "metalmodesty_gable",
                      "woodmodesty_gable",
                      "vertical_raiser",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [{ name: "750 mm", value: 750 }],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    required: false,
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "vertical_raiser",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup", "vertical_raiser"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: [
                      "grommet",
                      "straight_edge",
                      "cabletray_flipup",
                      "vertical_raiser",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: [
                      "chamferedge_flipup",
                      "cabletray_flipup",
                      "vertical_raiser",
                    ],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: [
                      "grommet",
                      "chamfer_edge",
                      "cabletray_flipup",
                      ,
                      "vertical_raiser",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "vertical_raiser",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup", "vertical_raiser"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: [
                      "grommet",
                      "straight_edge",
                      "cabletray_flipup",
                      ,
                      "vertical_raiser",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "vertical_raiser",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: [
                      "chamferedge_flipup",
                      "cabletray_flipup",
                      "vertical_raiser",
                    ],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: [
                      "grommet",
                      "chamfer_edge",
                      "cabletray_flipup",
                      "vertical_raiser",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "PRELAM GABLE END",
                disablefeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                  {
                    type: "show",
                    node: ["legs_gable_end"],
                  },
                  {
                    type: "hide",
                    node: ["connectors"],
                  },
                ],
              },
              {
                name: "40*40 Loop Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_loop", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",

                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Strip Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Livo Highlighter's Color"],
                enableFeatures: ["Strip PowderCoat Color", "Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_strip", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_loop",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "PROOCED",
                thumbnail: "/assets/afc/proceed.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_proceed", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO (LOOP)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },

              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },

              {
                name: "LIVO",
                thumbnail: "/assets/afc/livo.png",
                enableFeatures: ["Livo Highlighter's Color", "Side Modesty"],
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_Livo", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_curvivo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "CURVIVO",
                thumbnail: "/assets/afc/curvivo.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_curvivo", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },

              {
                name: "TRIANGULAR LEG (STRAIGHT)",
                thumbnail: "/assets/afc/triangular-leg-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_straight", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (SLANT)",
                thumbnail: "/assets/afc/triangular-leg-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_slant", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (LOOP)",
                thumbnail: "/assets/afc/triangular-leg-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                enableFeatures: ["Side Modesty"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_loop", "connectors"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },

          ///////
          {
            name: "Strip PowderCoat Color",
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Modesty Options",

    options: [
      {
        name: "Cabin Table",
        required: false,

        options: [
          {
            name: "MID Modesty",
            nodeName: [
              "metalmodesty",
              "woodmodesty",
              "woodmodesty_gable",
              "metalmodesty_gable",
            ],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty",
                      "woodmodesty",
                      "woodmodesty_gable",
                      "metalmodesty_gable",
                    ],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "/assets/afc/wmm.png",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "woodmodesty",
                      "metalmodesty_gable",
                      "woodmodesty_gable",
                      "metalmodesty",
                    ],
                  },
                  // {
                  //   type: "show",
                  //   node: ["metalmodesty"],
                  // },

                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PRELAM GABLE END"],
                      },
                    ],
                    node: ["metalmodesty", "woodmodesty", "woodmodesty_gable"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PRELAM GABLE END"],
                      },
                    ],
                    node: ["metalmodesty_gable"],
                  },
                  {
                    type: "show",
                    node: ["metalmodesty"],
                  },
                ],
              },
              {
                name: "Mid Wood",
                thumbnail: "/assets/afc/wwm.png",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty",
                      "metalmodesty_gable",
                      "woodmodesty_gable",
                      "metalmodesty_gable",
                    ],
                  },

                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PRELAM GABLE END"],
                      },
                    ],
                    node: [
                      "woodmodesty",
                      "metalmodesty",
                      "metalmodesty_gable",
                      "metalmodesty",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PRELAM GABLE END"],
                      },
                    ],
                    node: ["woodmodesty_gable"],
                  },
                  {
                    type: "show",
                    node: ["woodmodesty"],
                  },
                ],
              },
            ],
          },
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "metalmodesty_proceed",
                      "woodmodesty_sleek_livo",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "metalmodesty_proceed",
                      "woodmodesty_sleek_livo",
                    ],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PRELAM GABLE END"],
                      },
                    ],
                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                  // {
                  //   type: "show",
                  //   condition: [
                  //     {
                  //       feature: "Legs",
                  //       values: ["PRELAM GABLE END"],
                  //     },
                  //   ],
                  //   node: ["metalmodesty_gable"],
                  // },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                  // {
                  //   type: "show",
                  //   condition: [
                  //     {
                  //       feature: "Legs",
                  //       values: ["PRELAM GABLE END"],
                  //     },
                  //   ],
                  //   node: ["woodmodesty_gable"],
                  // },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Accessories",
    multiSelect: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "ROUND_POWER_DESKPORT",
                ],
              },
              {
                type: "show",
                node: ["vertical_raiser"],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },
          {
            name: "SLIDER POWER DESKPORT WITH WIFI CHARGER 350/560X100MM",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["sliderpowerdeskport"],
              },
              {
                type: "hide",
                node: ["ROUND_POWER_DESKPORT", "POWER_DESKPORT"],
              },
            ],
          },
          {
            name: "ROUND POWER DESKPORT",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["ROUND_POWER_DESKPORT"],
              },
              {
                type: "hide",
                node: ["sliderpowerdeskport", "POWER_DESKPORT"],
              },
            ],
          },
          {
            name: "POWER DESKPORT",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["POWER_DESKPORT"],
              },
              {
                type: "hide",
                node: ["sliderpowerdeskport", "ROUND_POWER_DESKPORT"],
              },
            ],
          },
          {
            name: "PVC SNAKE RISER",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["vertical_raiser"],
              },
              {
                type: "show",
                node: ["PVC_SNAKE_RISER"],
              },
            ],
          },
          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["SINGLE_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["DUAL_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_1",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: [""],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
