// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Prelam Pedestals",
        required: false,
        options: [
          {
            name: "2 EQUAL DRAWER",
            size: 1,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
            ],
            enableOptions: [
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/prepedestal/2equal.png",
            productId: "665963d018985f8904c7234e",
          },
          {
            name: "1 DRAWER + 1 FILLING",
            size: 2,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
            ],
            enableOptions: [
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/prepedestal/2nonequal.png",
            productId: "66598012e2a473e1165fb164",
          },

          // {
          //   name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
          //   size: 4,
          //   Length: 1800,
          //   Depth: 900,
          //   enableOptions: [
          //     "Caramel",
          //     "Clouds",
          //     "Dago Green",
          //     "Dago Orange",
          //     "Dolphin",
          //     "Flame",
          //     "Granada",
          //     "Greysto",
          //     "Lemon",
          //     "Med Blue",
          //     "Mink",
          //     "Olive",
          //     "P Green",
          //     "Peppermint",
          //     "Pink",
          //     "Planet",
          //     "Sangria",
          //     "Stone",
          //     "Tangerine",
          //     "Waterfall",

          //     "2 Individual KeyLocks",
          //     "2 Individual Numeric Locks",
          //   ],
          //   thumbnail: "/assets/afc/thumnail/prepedestal/1d1fcushion.png",
          //   productId: "6659830bdbbacc615ccfa11f",
          // },
          {
            name: "2 DRAWERS + 1 FILLING",
            size: 5,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/prepedestal/2d1f.png",
            productId: "6659899f7996f28d95c018f9",
          },

          {
            name: "4 EQUAL DRAWERS",
            size: 6,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/prepedestal/4Lequal.png",
            productId: "66598cfe995a9d8aa5533257",
          },
          // {
          //   name: "4 EQUAL DRAWER WITH LEVELLER (4 key options)",
          //   size: 7,
          //   Length: 1800,
          //   Depth: 900,
          //   disableOptions: [
          //     "Caramel",
          //     "Clouds",
          //     "Dago Green",
          //     "Dago Orange",
          //     "Dolphin",
          //     "Flame",
          //     "Granada",
          //     "Greysto",
          //     "Lemon",
          //     "Med Blue",
          //     "Mink",
          //     "Olive",
          //     "P Green",
          //     "Peppermint",
          //     "Pink",
          //     "Planet",
          //     "Sangria",
          //     "Stone",
          //     "Tangerine",
          //     "Waterfall",
          //     "2 Individual KeyLocks",
          //     "2 Individual Numeric Locks",
          //   ],
          //   thumbnail: "/assets/afc/thumnail/prepedestal/4Lequal4key.png",
          //   productId: "665990fdbf0995044261c4f8",
          // },
        ],
      },
    ],
  },
  {
    name: "Leg",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Pedestals",
        required: false,
        options: [
          // {
          //   name: "None",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: hide,
          //       node: ["castor", "leveller"],
          //     },
          //   ],
          // },
          {
            name: "Castor",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["castor", "sidewood"],
              },
              {
                type: hide,
                node: ["leveller", "sidewood_1"],
              },
            ],
          },
          {
            name: "Leveller",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["leveller", "sidewood_1"],
              },
              {
                type: hide,
                node: ["castor", "sidewood"],
              },
            ],
          },
        ],
      },
      // {
      //   name: "Metal Pedestals",
      //   required: false,
      //   options: [
      //     {
      //       name: "None",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: hide,
      //           node: ["castor", "sidemetal_1"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Castor",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["castor", "sidemetal"],
      //         },
      //         {
      //           type: hide,
      //           node: ["sidemetal_1"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Leveller",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["sidemetal_1"],
      //         },
      //         {
      //           type: hide,
      //           node: ["castor", "sidemetal"],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },

  {
    name: "Handle",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Pedestals",
        required: false,
        options: [
          {
            name: "I Handle",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["ihandle", "front_1"],
              },
              {
                type: hide,
                node: [
                  "groovehandle",
                  "flushhandle",
                  "dhandle",
                  "horizontalgroove",
                ],
              },
            ],
          },
          {
            name: "Front Finger Groove",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["groovehandle"],
              },
              {
                type: hide,
                node: [
                  "front_1",
                  "ihandle",
                  "dhandle",
                  "flushhandle",
                  "horizontalgroove",
                ],
              },
            ],
          },
          {
            name: "Side Finger Groove",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["horizontalgroove"],
              },
              {
                type: hide,
                node: [
                  "front_1",
                  "ihandle",
                  "dhandle",
                  "flushhandle",
                  "groovehandle",
                ],
              },
            ],
          },
          {
            name: "Flush Handle",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["flushhandle"],
              },
              {
                type: hide,
                node: [
                  "groovehandle",
                  "front_1",
                  "ihandle",
                  "dhandle",
                  "horizontalgroove",
                ],
              },
            ],
          },
          // {
          //   name: "D Handle",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: show,
          //       node: ["dhandle", "front_1"],
          //     },
          //     {
          //       type: hide,
          //       node: [
          //         "groovehandle",
          //         "ihandle",
          //         "flushhandle",
          //         "horizontalgroove",
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    name: "Lock",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Pedestals",
        required: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: hide,
                node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "Key Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["lock_1"],
              },
              {
                type: hide,
                node: ["lock_2", "numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "2 Individual KeyLocks",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["lock_1", "lock_2"],
              },
              {
                type: hide,
                node: ["numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "Numeric Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["numberlock_1"],
              },
              {
                type: hide,
                node: ["lock_1", "lock_2", "numberlock_2"],
              },
            ],
          },
          {
            name: "2 Individual Numeric Locks",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["numberlock_1", "numberlock_2"],
              },
              {
                type: hide,
                node: ["lock_1", "lock_2"],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Laminate Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Pedestals",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Frosty White",
            thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/white.jpg",
              },
            ],
          },
          {
            name: "Slate Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/highland.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Light",
            thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Dark",
            thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
              },
            ],
          },
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
              },
            ],
          },
          {
            name: "Flowery Wenge",
            thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
              },
            ],
          },
          {
            name: "Persian Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/persian.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["frontwood", "sidewood", "topwood", "topwood.001"],
                textureUrl: "/assets/textures/Wood/2k/urban.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
    ],
  },
  // {
  //   name: "Carcass Wood",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Prelam Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "Black",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Charcoal",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Classic Planked Walnut",
  //           thumbnail:
  //             "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Everest White",
  //           thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Highland Pine",
  //           thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Lyonus Walnut",
  //           thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Moldau Acacia",
  //           thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sonama Oak",
  //           thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Gothic Grey",
  //           thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Warm Grey",
  //           thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Thansau Maple",
  //           thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Urban Teak",
  //           thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: ["sidewood", "topwood", "topwood.001"],
  //               textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "Top Wood",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Prelam Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "Black",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Charcoal",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Classic Planked Walnut",
  //           thumbnail:
  //             "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Everest White",
  //           thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Highland Pine",
  //           thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Lyonus Walnut",
  //           thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Moldau Acacia",
  //           thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sonama Oak",
  //           thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Gothic Grey",
  //           thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Warm Grey",
  //           thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Thansau Maple",
  //           thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Urban Teak",
  //           thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "topwood",
  //               textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     // {
  //     //   name: "Metal Pedestals",
  //     //   required: false,
  //     //   options: [
  //     //     {
  //     //       name: "Black",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Charcoal",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Classic Planked Walnut",
  //     //       thumbnail:
  //     //         "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Everest White",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Highland Pine",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Lyonus Walnut",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Moldau Acacia",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Sonama Oak",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Gothic Grey",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Warm Grey",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Thansau Maple",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //     {
  //     //       name: "Urban Teak",
  //     //       thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //     //       rules: [
  //     //         {
  //     //           type: applyMaterial,
  //     //           node: "topwood",
  //     //           textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //     //         },
  //     //       ],
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  // {
  //   name: "Cushion",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Prelam Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "Caramel",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Clouds",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dago Green",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dago Orange",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dolphin",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Flame",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Granada",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Greysto",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Lemon",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Med Blue",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Mink",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Olive",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "P Green",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Peppermint",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Pink",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Planet",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sangria",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Stone",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Tangerine",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Waterfall",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric.002",
  //               textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]);

export type ConfigData = typeof ConfData3;
