// import React from "react";
// import { useAppSelector } from "../hooks/hooks";
// import { Order, OrderItem } from "../types/types";
// import rightArrowIcon from "../assets/icons/right-arrow.svg";
// import { useHistory } from "react-router";
// import logo from "../assets/logo.svg";
// import { useState } from "react";
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   PDFViewer,
//   Image,
//   Font,
// } from "@react-pdf/renderer";
// import { getUserFromLocalStorage } from "../utils/localStorage";

// const gst = 18 / 100;

// // Create styles

// Font.register({
//   family: "Open Sans",
//   fonts: [
//     {
//       src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
//     },
//     {
//       src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
//       fontWeight: 600,
//     },
//   ],
// });

// const ComponentBOM = ({
//   product,
//   selectedDiscount,
// }: {
//   product: OrderItem;
//   selectedDiscount: number | undefined;
// }) => {
//   const productCost = product.bom.reduce(
//     (acc: any, curr: any) => acc + (curr?.price * curr.quantity || 0),
//     0
//   );
//   // const productCostWithGst = Math.round(productCost + productCost * gst);
//   return (
//     <View style={{ marginTop: 50 }}>
//       <View style={{ fontWeight: "bold", marginBottom: 10, fontSize: 14 }}>
//         <Text>{product.bom.name}</Text>
//       </View>
//       <View style={{ border: "2px solid #dddddd", borderRadius: 10 }}>
//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             padding: 20,
//             borderBottom: "2px solid #dddddd",
//           }}
//         >
//           <Text style={{ fontWeight: "bold", width: "50%", textAlign: "left" }}>
//             Component
//           </Text>
//           <Text
//             style={{ fontWeight: "bold", width: "25%", textAlign: "center" }}
//           >
//             Qty
//           </Text>
//           <Text
//             style={{ fontWeight: "bold", width: "25%", textAlign: "right" }}
//           >
//             Price
//           </Text>
//         </View>
//         {product.bom.map((component: any, index: number) => {
//           const componentCost = component.price * component.quantity;
//           // const componentCostWithGst = Math.round(
//           //   componentCost + componentCost * gst
//           // );
//           return (
//             <View
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 padding: 20,

//                 borderBottom:
//                   product.bom.length - 1 === index ? "" : "2px solid #dddddd",
//               }}
//               key={index}
//             >
//               <Text
//                 style={{
//                   width: "50%",
//                   textAlign: "left",
//                 }}
//               >
//                 {component.name}
//               </Text>
//               <Text style={{ width: "25%", textAlign: "center" }}>
//                 {component.quantity}
//               </Text>
//               <Text
//                 style={{
//                   width: "25%",
//                   textAlign: "right",
//                 }}
//               >
//                 {"INR " + componentCost}
//               </Text>
//             </View>
//           );
//         })}
//       </View>
//       <View>
//         <Text
//           style={{
//             textAlign: "right",
//             fontWeight: "bold",
//             marginTop: 10,
//           }}
//         >
//           Total Price:{" INR "}
//           {productCost}
//         </Text>
//         {/* {selectedDiscount && selectedDiscount > 0 && (
//           <Text
//             style={{
//               textAlign: "right",
//               fontWeight: "bold",
//               marginTop: 10,
//             }}
//           >
//             Total Price after {selectedDiscount}% discount:{" INR "}
//             {Math.round(
//               productCostWithGst - productCostWithGst * (selectedDiscount / 100)
//             )}
//           </Text>
//         )} */}
//       </View>
//     </View>
//   );
// };

// const Page1 = ({
//   order,
//   selectedDiscount,
//   selectedDamageAndFreight,
// }: {
//   order: Order;
//   selectedDiscount: number;
//   selectedDamageAndFreight: number;
// }) => {
//   const user = getUserFromLocalStorage();
//   const orderTotal = order.cart_items.reduce(
//     (acc, curr) => acc + (curr.cost! * curr.quantity || 0),
//     0
//   );

//   // const orderTotalWithGst = Math.round(orderTotal + orderTotal * gst);
//   const discountedPrice: number = Number(
//     (orderTotal * (selectedDiscount / 100)).toFixed(2)
//   );
//   const freightAndDamage: number = Number(
//     ((orderTotal - discountedPrice) * (selectedDamageAndFreight / 100)).toFixed(
//       2
//     )
//   );
//   const amountPayable: number = Number(
//     orderTotal - discountedPrice + freightAndDamage
//   );
//   const amountPayableWithGst: number = Number(
//     (amountPayable + amountPayable * gst).toFixed(2)
//   );

//   return (
//     <Page
//       size="A4"
//       style={{
//         backgroundColor: "white",
//         padding: 30,
//         fontFamily: "Open Sans",
//         fontSize: 13,
//       }}
//     >
//       <View
//         style={{
//           color: "black",
//           display: "flex",
//           flexDirection: "row",
//           paddingBottom: 20,
//           borderBottom: "2 solid #9a9a9a",
//           marginBottom: 30,
//           alignItems: "center",
//         }}
//       >
//         <Text
//           style={{
//             fontWeight: "bold",
//             fontSize: 18,
//           }}
//         >
//           Quotation
//         </Text>
//         <Image
//           src={logo}
//           style={{
//             height: "30px",
//             objectFit: "contain",
//             marginLeft: "auto",
//           }}
//         ></Image>
//       </View>
//       <View style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
//         <Text style={{ fontSize: 13, fontWeight: "bold" }}>
//           Dear {order.customer.name},
//         </Text>
//         {/* <Text style={{ marginLeft: "auto", fontWeight: "bold" }}>
//           {order.salesForceId}
//         </Text> */}
//       </View>
//       <View>
//         <Text style={{ marginBottom: 40 }}>
//           Here is the quotation for the workstations you had requested for.
//         </Text>
//       </View>
//       <View style={{ border: "2px solid #dddddd", borderRadius: 10 }}>
//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             padding: 20,
//             borderBottom: "2px solid #dddddd",
//           }}
//         >
//           <Text style={{ width: "10%" }}>Sl.No</Text>
//           <Text style={{ width: "35%", textAlign: "center" }}>Workstation</Text>
//           <Text style={{ width: "20%", textAlign: "center" }}>Thumbnail</Text>
//           <Text style={{ width: "20%", textAlign: "center" }}>Qty</Text>
//           <Text style={{ width: "15%", textAlign: "right" }}>Price</Text>
//         </View>
//         {order.cart_items.map((product, index) => {
//           const productPrice = product.cost! * product.quantity;
//           // const productPriceWithGst = Math.round(
//           //   productPrice + productPrice * gst
//           // );
//           return (
//             <View
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 padding: 20,
//                 alignItems: "center",

//                 borderBottom:
//                   order.cart_items.length - 1 === index
//                     ? ""
//                     : "2px solid #dddddd",
//               }}
//               key={index}
//             >
//               <Text style={{ width: "10%" }}>{index + 1}</Text>
//               <View
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",

//                   width: "35%",
//                   textAlign: "center",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}
//               >
//                 <Text>{product.product}</Text>
//               </View>
//               <View
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",

//                   width: "30%",
//                   textAlign: "center",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}
//               >
//                 {product.thumbnail && (
//                   <Image
//                     src={product.thumbnail}
//                     style={{ height: "50px", width: "auto" }}
//                   ></Image>
//                 )}
//               </View>
//               <Text style={{ width: "10%", textAlign: "right" }}>
//                 {product.quantity}
//               </Text>
//               <Text
//                 style={{
//                   width: "25%",
//                   textAlign: "right",
//                 }}
//               >
//                 {"INR " + productPrice}
//               </Text>
//             </View>
//           );
//         })}
//       </View>
//       <View>
//         <Text
//           style={{
//             textAlign: "right",
//             fontWeight: "bold",
//             marginTop: 10,
//           }}
//         >
//           Order Total :{" INR "}
//           {orderTotal}
//         </Text>
//         {selectedDiscount > 0 && (
//           <Text
//             style={{
//               textAlign: "right",
//               fontWeight: "bold",
//               marginTop: 10,
//             }}
//           >
//             Discount ({selectedDiscount}%):{" INR "}
//             {discountedPrice}
//           </Text>
//         )}
//         {selectedDamageAndFreight > 0 && (
//           <Text
//             style={{
//               textAlign: "right",
//               fontWeight: "bold",
//               marginTop: 10,
//             }}
//           >
//             Freight and Damage charges ({selectedDamageAndFreight}%):
//             {" INR "}
//             {freightAndDamage}
//           </Text>
//         )}
//         <Text
//           style={{
//             textAlign: "right",
//             fontWeight: "bold",
//             marginTop: 10,
//           }}
//         >
//           Total Amount payable (including 18% GST):
//           {" INR "}
//           {amountPayableWithGst}
//         </Text>
//       </View>
//       <View
//         style={{
//           marginTop: "auto",
//           paddingTop: 20,
//           borderTop: "2 solid #9a9a9a",
//         }}
//       >
//         <Text>
//           Your sales representative {user.first_name} {user.last_name},{" "}
//           {user.user_name}
//         </Text>
//       </View>
//     </Page>
//   );
// };

// const Page2 = ({
//   order,
//   selectedDiscount,
// }: {
//   order: Order;
//   selectedDiscount: number | undefined;
// }) => (
//   <Page
//     size="A4"
//     style={{
//       backgroundColor: "white",
//       padding: 30,
//       fontFamily: "Open Sans",
//       fontSize: 13,
//     }}
//   >
//     <View
//       style={{
//         color: "black",
//         display: "flex",
//         flexDirection: "row",
//         paddingBottom: 20,
//         borderBottom: "2 solid #9a9a9a",

//         alignItems: "center",
//       }}
//     >
//       <Text
//         style={{
//           fontWeight: "bold",
//           fontSize: 18,
//         }}
//       >
//         Bill of materials
//       </Text>
//     </View>
//     <View>
//       {order.cart_items.map((product, index) => (
//         <ComponentBOM
//           key={index}
//           product={product}
//           selectedDiscount={selectedDiscount}
//         />
//       ))}
//     </View>
//   </Page>
// );
// // Create Document Component
// const MyDocument = ({
//   order,
//   selectedDiscount,
//   selectedDamageAndFreight,
// }: {
//   order: Order;
//   selectedDiscount: number;
//   selectedDamageAndFreight: number;
// }) => (
//   <Document>
//     <Page1
//       order={order}
//       selectedDiscount={selectedDiscount}
//       selectedDamageAndFreight={selectedDamageAndFreight}
//     />
//     <Page2 order={order} selectedDiscount={selectedDiscount} />
//   </Document>
// );

// export const Report = ({ hideGoBack }: { hideGoBack?: boolean }) => {
//   const currentOrder: Order = useAppSelector(
//     (state) => state.orders.currentOrder
//   );
//   const [selectedDamageAndFreight, setSelectedDamageAndFreight] =
//     useState<number>(0);
//   const [selectedDiscount, setSelectedDiscount] = useState<number>(0);

//   const [showOptions, setShowOptions] = useState<boolean>(true);
//   const history = useHistory();

//   const handleChange = (type: string, value: number) => {
//     if (type === "discount") {
//       if (value < 0) {
//         setSelectedDiscount(0);
//       } else {
//         setSelectedDiscount(value);
//       }
//     } else if (type === "freightAndDamage") {
//       if (value < 0) {
//         setSelectedDamageAndFreight(0);
//       } else {
//         setSelectedDamageAndFreight(value);
//       }
//     }
//   };
//   return (
//     <div
//       className="px-16 py-4 pt-0 flex flex-col h-screen items-center"
//       id="report-container"
//     >
//       {!hideGoBack && (
//         <div
//           onClick={() => history.goBack()}
//           className="flex mr-auto mt-4 cursor-pointer hide-while-download"
//         >
//           <img src={rightArrowIcon} className="mr-2" alt="" />
//           <h1 className="font-bold">Back</h1>
//         </div>
//       )}
//       {showOptions ? (
//         <div className="px-5 py-5 flex flex-col justify-center items-center rounded-lg shadow-xl font-bold text-sm xl:text-xl">
//           <div className="flex justify-between mt-4 w-full">
//             <p>Discount percentage</p>
//             <input
//               type="number"
//               onChange={(e) =>
//                 handleChange("discount", parseInt(e.target.value))
//               }
//               value={selectedDiscount}
//               className="border ml-5 w-20 px-1"
//               placeholder="0"
//             />
//           </div>
//           <div className="flex justify-between  mt-4 w-full">
//             <p>Damage and Freight percentage</p>
//             <input
//               type="number"
//               onChange={(e) =>
//                 handleChange("freightAndDamage", parseInt(e.target.value))
//               }
//               value={selectedDamageAndFreight}
//               className="border ml-5 w-20 px-1"
//               placeholder="0"
//             />
//           </div>

//           <button
//             className="px-4 py-1 bg-black text-white rounded-md mt-5"
//             onClick={() => {
//               setShowOptions(false);
//             }}
//           >
//             Next
//           </button>
//         </div>
//       ) : (
//         <div style={{ width: "100%", height: "100%" }} className="mt-8">
//           <PDFViewer width="100%" height="100%">
//             <MyDocument
//               order={currentOrder}
//               selectedDiscount={selectedDiscount}
//               selectedDamageAndFreight={selectedDamageAndFreight}
//             />
//           </PDFViewer>
//         </div>
//       )}
//     </div>
//   );
// };

import React, { useState } from "react";
import { useAppSelector } from "../hooks/hooks";
import { Order, OrderItem } from "../types/types";
import rightArrowIcon from "../assets/icons/right-arrow.svg";
import { useHistory } from "react-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import logo from "../assets/logo.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import { getUserFromLocalStorage } from "../utils/localStorage";

const gst = 18 / 100;

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const ComponentBOM = ({
  product,
  selectedDiscount,
}: {
  product: OrderItem;
  selectedDiscount: number | undefined;
}) => {
  const productCost = product.bom.reduce(
    (acc: number, curr: any) => acc + (curr?.price * curr.quantity || 0),
    0
  );

  return (
    <View style={{ marginTop: 50 }}>
      <View style={{ fontWeight: "bold", marginBottom: 10, fontSize: 14 }}>
        <Text>{product.bom.name}</Text>
      </View>
      <View style={{ border: "2px solid #dddddd", borderRadius: 10 }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            borderBottom: "2px solid #dddddd",
          }}
        >
          <Text style={{ fontWeight: "bold", width: "50%", textAlign: "left" }}>
            Component
          </Text>
          <Text
            style={{ fontWeight: "bold", width: "25%", textAlign: "center" }}
          >
            Qty
          </Text>
          <Text
            style={{ fontWeight: "bold", width: "25%", textAlign: "right" }}
          >
            Price
          </Text>
        </View>
        {product.bom.map((component: any, index: number) => {
          const componentCost = component.price * component.quantity;
          return (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 20,
                borderBottom:
                  product.bom.length - 1 === index ? "" : "2px solid #dddddd",
              }}
              key={index}
            >
              <Text
                style={{
                  width: "50%",
                  textAlign: "left",
                }}
              >
                {component.name}
              </Text>
              <Text style={{ width: "25%", textAlign: "center" }}>
                {component.quantity}
              </Text>
              <Text
                style={{
                  width: "25%",
                  textAlign: "right",
                }}
              >
                {"INR " + componentCost}
              </Text>
            </View>
          );
        })}
      </View>
      <View>
        <Text
          style={{
            textAlign: "right",
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          Total Price:{" INR "}
          {productCost}
        </Text>
      </View>
    </View>
  );
};

const Page1 = ({
  order,
  selectedDiscount,
  selectedDamageAndFreight,
}: {
  order: Order;
  selectedDiscount: number;
  selectedDamageAndFreight: number;
}) => {
  const user = getUserFromLocalStorage();
  const orderTotal = order.cart_items.reduce(
    (acc, curr) => acc + (curr.cost! * curr.quantity || 0),
    0
  );

  const discountedPrice: number = Number(
    (orderTotal * (selectedDiscount / 100)).toFixed(2)
  );
  const freightAndDamage: number = Number(
    ((orderTotal - discountedPrice) * (selectedDamageAndFreight / 100)).toFixed(
      2
    )
  );
  const amountPayable: number = Number(
    orderTotal - discountedPrice + freightAndDamage
  );
  const amountPayableWithGst: number = Number(
    (amountPayable + amountPayable * gst).toFixed(2)
  );

  return (
    // <Page
    //   size="A4"
    //   style={{
    //     backgroundColor: "white",
    //     padding: 30,
    //     fontFamily: "Open Sans",
    //     fontSize: 13,
    //   }}
    // >
    //   <View
    //     style={{
    //       color: "black",
    //       display: "flex",
    //       flexDirection: "row",
    //       paddingBottom: 20,
    //       borderBottom: "2px solid #9a9a9a",
    //       marginBottom: 30,
    //       alignItems: "center",
    //     }}
    //   >
    //     <Text
    //       style={{
    //         fontWeight: "bold",
    //         fontSize: 18,
    //       }}
    //     >
    //       Quotation
    //     </Text>
    //     <Image
    //       src={logo}
    //       style={{
    //         height: "30px",
    //         objectFit: "contain",
    //         marginLeft: "auto",
    //       }}
    //     ></Image>
    //   </View>
    //   <View style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
    //     <Text style={{ fontSize: 13, fontWeight: "bold" }}>
    //       Dear {order.customer.name},
    //     </Text>
    //   </View>
    //   <View>
    //     <Text style={{ marginBottom: 40 }}>
    //       Here is the quotation for the workstations you had requested for.
    //     </Text>
    //   </View>
    //   <View style={{ border: "2px solid #dddddd", borderRadius: 10 }}>
    //     <View
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         padding: 20,
    //         borderBottom: "2px solid #dddddd",
    //       }}
    //     >
    //       <Text style={{ width: "10%" }}>Sl.No</Text>
    //       <Text style={{ width: "35%", textAlign: "center" }}>Workstation</Text>
    //       <Text style={{ width: "20%", textAlign: "center" }}>Thumbnail</Text>
    //       <Text style={{ width: "20%", textAlign: "center" }}>Qty</Text>
    //       <Text style={{ width: "15%", textAlign: "right" }}>Price</Text>
    //     </View>
    //     {order.cart_items.map((product, index) => {
    //       const productPrice = product.cost! * product.quantity;
    //       return (
    //         <View
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //             padding: 20,
    //             alignItems: "center",
    //             borderBottom:
    //               order.cart_items.length - 1 === index
    //                 ? ""
    //                 : "2px solid #dddddd",
    //           }}
    //           key={index}
    //         >
    //           <Text style={{ width: "10%" }}>{index + 1}</Text>
    //           <View
    //             style={{
    //               display: "flex",
    //               flexDirection: "column",
    //               width: "35%",
    //               textAlign: "center",
    //               justifyContent: "center",
    //               alignItems: "center",
    //             }}
    //           >
    //             {/* <Text>{product.product}</Text> */}
    //           </View>
    //           <View
    //             style={{
    //               display: "flex",
    //               flexDirection: "column",
    //               width: "30%",
    //               textAlign: "center",
    //               justifyContent: "center",
    //               alignItems: "center",
    //             }}
    //           >
    //             {product.thumbnail && (
    //               <Image
    //                 src={product.thumbnail}
    //                 style={{ height: "50px", width: "auto" }}
    //               ></Image>
    //             )}
    //           </View>
    //           <Text style={{ width: "10%", textAlign: "right" }}>
    //             {product.quantity}
    //           </Text>
    //           <Text
    //             style={{
    //               width: "25%",
    //               textAlign: "right",
    //             }}
    //           >
    //             {"INR " + productPrice}
    //           </Text>
    //         </View>
    //       );
    //     })}
    //   </View>
    //   <View>
    //     <Text
    //       style={{
    //         textAlign: "right",
    //         fontWeight: "bold",
    //         marginTop: 10,
    //       }}
    //     >
    //       Order Total :{" INR "}
    //       {orderTotal}
    //     </Text>
    //     {selectedDiscount > 0 && (
    //       <Text
    //         style={{
    //           textAlign: "right",
    //           fontWeight: "bold",
    //           marginTop: 10,
    //         }}
    //       >
    //         Discount ({selectedDiscount}%):{" INR "}
    //         {discountedPrice}
    //       </Text>
    //     )}
    //     {selectedDamageAndFreight > 0 && (
    //       <Text
    //         style={{
    //           textAlign: "right",
    //           fontWeight: "bold",
    //           marginTop: 10,
    //         }}
    //       >
    //         Freight and Damage charges ({selectedDamageAndFreight}%):
    //         {" INR "}
    //         {freightAndDamage}
    //       </Text>
    //     )}
    //     <Text
    //       style={{
    //         textAlign: "right",
    //         fontWeight: "bold",
    //         marginTop: 10,
    //       }}
    //     >
    //       Total Amount payable (including 18% GST):
    //       {" INR "}
    //       {amountPayableWithGst}
    //     </Text>
    //   </View>
    //   <View
    //     style={{
    //       marginTop: "auto",
    //       paddingTop: 20,
    //       borderTop: "2px solid #9a9a9a",
    //     }}
    //   >
    //     <Text>
    //       Your sales representative {user.first_name} {user.last_name},{" "}
    //       {user.user_name}
    //     </Text>
    //   </View>
    // </Page>
    <></>
  );
};

const Page2 = ({
  order,
  selectedDiscount,
}: {
  order: Order;
  selectedDiscount: number | undefined;
}) => (
  // <Page
  //   size="A4"
  //   style={{
  //     backgroundColor: "white",
  //     padding: 30,
  //     fontFamily: "Open Sans",
  //     fontSize: 13,
  //   }}
  // >
  //   <View
  //     style={{
  //       color: "black",
  //       display: "flex",
  //       flexDirection: "row",
  //       paddingBottom: 20,
  //       borderBottom: "2px solid #9a9a9a",
  //       alignItems: "center",
  //     }}
  //   >
  //     <Text
  //       style={{
  //         fontWeight: "bold",
  //         fontSize: 18,
  //       }}
  //     >
  //       Bill of materials
  //     </Text>
  //   </View>
  //   <View>
  //     {order.cart_items.map((product, index) => (
  //       <ComponentBOM
  //         key={index}
  //         product={product}
  //         selectedDiscount={selectedDiscount}
  //       />
  //     ))}
  //   </View>
  // </Page>
  <></>
);

const MyDocument = ({
  order,
  selectedDiscount,
  selectedDamageAndFreight,
}: {
  order: Order;
  selectedDiscount: number;
  selectedDamageAndFreight: number;
}) => (
  // <Document>
  //   <Page1
  //     order={order}
  //     selectedDiscount={selectedDiscount}
  //     selectedDamageAndFreight={selectedDamageAndFreight}
  //   />
  //   <Page2 order={order} selectedDiscount={selectedDiscount} />
  // </Document>
  <></>
);

export const Report = ({ hideGoBack }: { hideGoBack?: boolean }) => {
  const currentOrder: Order = useAppSelector(
    (state) => state.orders.currentOrder
  );
  const [selectedDamageAndFreight, setSelectedDamageAndFreight] =
    useState<number>(0);
  const [selectedDiscount, setSelectedDiscount] = useState<number>(0);

  const [showOptions, setShowOptions] = useState<boolean>(true);
  const history = useHistory();

  const handleChange = (type: string, value: number) => {
    if (type === "discount") {
      setSelectedDiscount(value < 0 ? 0 : value);
    } else if (type === "freightAndDamage") {
      setSelectedDamageAndFreight(value < 0 ? 0 : value);
    }
  };

  return (
    <div
      className="px-16 py-4 pt-0 flex flex-col h-screen items-center"
      id="report-container"
    >
      {!hideGoBack && (
        <div
          onClick={() => history.goBack()}
          className="flex mr-auto mt-4 cursor-pointer hide-while-download"
        >
          <img src={rightArrowIcon} className="mr-2" alt="" />
          <h1 className="font-bold">Back</h1>
        </div>
      )}
      {showOptions ? (
        <div className="px-5 py-5 flex flex-col justify-center items-center rounded-lg shadow-xl font-bold text-sm xl:text-xl">
          <div className="flex justify-between mt-4 w-full">
            <p>Discount percentage</p>
            <input
              type="number"
              onChange={(e) =>
                handleChange("discount", parseInt(e.target.value))
              }
              value={selectedDiscount}
              className="border ml-5 w-20 px-1"
              placeholder="0"
            />
          </div>
          <div className="flex justify-between mt-4 w-full">
            <p>Damage and Freight percentage</p>
            <input
              type="number"
              onChange={(e) =>
                handleChange("freightAndDamage", parseInt(e.target.value))
              }
              value={selectedDamageAndFreight}
              className="border ml-5 w-20 px-1"
              placeholder="0"
            />
          </div>
          <button
            className="px-4 py-1 bg-black text-white rounded-md mt-5"
            onClick={() => {
              setShowOptions(false);
            }}
          >
            Next
          </button>
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%" }} className="mt-8">
          <PDFViewer width="100%" height="100%">
            <MyDocument
              order={currentOrder}
              selectedDiscount={selectedDiscount}
              selectedDamageAndFreight={selectedDamageAndFreight}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};
