import { UserConfiguration } from "../types/types";

const getDescription = (userConfig: Partial<UserConfiguration>) => {
  const {
    type,
    Legs,
    Length,
    Depth,
    Dia,
    noOfSeats,
    Raceway,
    Pedestal,
    Laminate,
    Accessories,
  } = userConfig;
  const wireAccess = userConfig["Wire Access"];
  const wireEntry = userConfig["Wire Entry"];
  const mainScreen = userConfig["Main screen"];
  const returnScreen = userConfig["Return screen"];
  const sideModesty = userConfig["Side Modesty"];
  const mainModesty = userConfig["Main Modesty"];
  const powderCoat = userConfig["Powder Coat"];
  const glassColor = userConfig["Glass Color"];
  const fabricColor = userConfig["Fabric Color"];
  const colorsLegColor = userConfig["Color Options"];

  const sideTable = userConfig["Side Table"]
    ? userConfig["Side Table"]
    : undefined;
  const configArray = [
    type !== "SHARING" && type !== "NON SHARING" ? `Name : ${type}` : undefined,
    type === "SHARING" || type === "NON SHARING"
      ? `Name : ${noOfSeats} Seater - ${type}`
      : undefined,
    Length !== undefined ? `Length : ${Length}W` : undefined,
    Depth !== undefined ? `Depth : ${Depth}D` : undefined,
    Dia !== undefined ? `Diameter : ${Dia}mm` : undefined,
    Legs !== undefined ? `Legs : ${Legs}` : Legs,
    Legs === "Colors" && colorsLegColor
      ? `Leg Color : ${colorsLegColor}`
      : undefined,
    sideTable && `SideTable Dimensions : ${sideTable}`,
    Raceway ? `Raceway : ${Raceway}` : undefined,
    wireAccess ? `Wire Access : ${wireAccess}` : undefined,
    wireEntry ? `Wire Entry : ${wireEntry}` : undefined,

    mainScreen ? `Main Screen : ${mainScreen}` : undefined,
    returnScreen ? `Return Screen : ${returnScreen}` : undefined,
    mainModesty ? `Main Modesty : ${mainModesty}` : undefined,
    sideModesty ? `Side Modesty : ${sideModesty}` : undefined,
    Laminate ? `Laminate : ${Laminate}` : undefined,
    powderCoat ? `Powder Coat : ${powderCoat}` : undefined,
    glassColor ? `Glass Color : ${glassColor}` : undefined,
    fabricColor ? `Fabric Color : ${fabricColor}` : undefined,
    Pedestal ? `Pedestal : ${Pedestal}` : undefined,
    // Accessories && Accessories.length > 0
    //   ? `Accessories : ${Accessories.join(", ")}`
    //   : undefined,
  ];

  return configArray
    .filter((val) => {
      return val !== undefined;
    })
    .join(", ");
};

export default getDescription;
