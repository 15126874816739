import React, { useState } from "react";
import dropDownIcon from "../assets/icons/drop-down.svg";

export default function Card({
  name,
  onClick,
  selected,
  disabled,
  label,
}: {
  name: String;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
  label?: string;
}) {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <div
      className="select-none cursor-pointer text-center h-full"
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        onClick={onClick}
        style={{}}
        className={`${!disabled ? "" : "cursor-not-allowed"} ${
          !disabled && (selected || isHovering)
            ? " shadow-lg bg-white "
            : "bg-gray-50"
        } text-sm h-18 rounded-xl flex px-5 flex-col justify-between  items-center py-3 h-full`}
      >
        <h1 className="text-base 2xl:text-lg uppercase  font-bold items-center justify-center tracking-widest">
          {name}
        </h1>
        <div className="flex pb-1 items-center mt-1">
          <h2
            style={{ width: "3.75rem" }}
            className="text-left overflow-hidden h-9 w-16 text-xs 2xl:text-sm"
          >
            {label
              ? label?.length > 15
                ? label.substring(0, 15) + "..."
                : label
              : "none"}
          </h2>

          <img src={dropDownIcon} alt="" className="ml-3 h-2" />
        </div>
      </div>
    </div>
  );
}
