import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTable, useExpanded, usePagination, useSortBy } from "react-table";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { configDataConstants, ordersConstants } from "../store/actions/types";
import { Order } from "../types/types";
import ProductsTable from "./ProductsTable";
import dropDownIcon from "../assets/icons/drop-down.svg";
import toast from "react-hot-toast";
import { deleteOrder, patchOrder } from "../utils/ordersApi";
import confirmAction from "../utils/confirmAction";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import addIcon from "../assets/icons/add.svg";
import { formatDateTime } from "../utils/formatDateTime";
import ReactTooltip from "react-tooltip";
import { ConfData3 as meetingTables } from "../const/meetingTables";
import { ConfData3 as confTables } from "../const/conferenceTables";
import { ConfData3 as workStation } from "../const/const";
import { ConfData3 as discussion } from "../const/discussionTables";
import { ConfData3 as cabinTable } from "../const/cabinTablesNew";
import { ConfData3 as neoWorkstation } from "../const/neoWorkstations";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import { getIsSuperAdmin } from "../utils/getIsSuperAdmin";
function Table({ columns, data, editOrder, showGenerateQuotationForm }: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const totalOrders = useAppSelector((state) => state.orders.totalOrders);
  const currentPage = useAppSelector((state) => state.orders.currentPage);
  const currentPageSize = useAppSelector((state) => state.orders.pageSize);
  const user = useAppSelector((state) => state.user);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    page,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: currentPageSize,
        // sortBy: [{ id: "createdAt", desc: true }],
      },
      autoResetExpanded: false,
      autoResetPage: false,
    },

    useSortBy,

    useExpanded,
    usePagination
  );

  const addProduct = (order: Order) => {
    makeSessionRequest("clicked_on_add_product", { orderId: order._id });
    if (order.cart_items.length > 0) {
      let tableType = order.cart_items[0].configuration.type;
      tableType =
        tableType.includes("main partition") ||
        tableType.includes("main + return partition")
          ? "Neo WKS"
          : tableType;
      if (tableType === "Meeting Table") {
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: meetingTables,
        });
      } else if (tableType === "Conference Table") {
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: confTables,
        });
      } else if (tableType === "Discussion Table") {
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: discussion,
        });
      } else if (tableType === "Cabin Table") {
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: cabinTable,
        });
      } else if (tableType === "Neo WKS") {
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: neoWorkstation,
        });
      } else {
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: workStation,
        });
      }
    }
    dispatch({ type: ordersConstants.SET_CURRENT_ORDER, payload: order });
    history.push("/configurator");
  };

  const removeOrder = async (order: Order) => {
    try {
      order._id && (await deleteOrder(order._id));
      dispatch({ type: ordersConstants.DELETE_ORDER, payload: order });
      toast.success("Order deleted successfully");
      makeSessionRequest("order_deleted", { orderId: order._id });
    } catch (error) {
      toast.error("Error deleting order");
    }
  };

  const removeOrderConfirm = (order: Order) => {
    confirmAction("Confirm Delete", "", () => removeOrder(order));
  };

  const handleBomClick = async (order: Order) => {
    makeSessionRequest("clicked_quote", {
      orderId: order._id,
      customerName: order.customer.name,
    });
    showGenerateQuotationForm();
    dispatch({ type: ordersConstants.SET_CURRENT_ORDER, payload: order });
  };
  const lastPage: number =
    totalOrders % 10 > 0
      ? Math.floor(Number(totalOrders / currentPageSize)) + 1
      : Math.floor(Number(totalOrders / currentPageSize));

  const handleNextClick = () => {
    const nextPage = currentPage + 1;

    if (nextPage <= lastPage) {
      dispatch({
        type: ordersConstants.SET_CURRENT_PAGE,
        payload: nextPage,
      });
    } else {
      return;
    }
  };

  const handlePrevClick = () => {
    const prevPage = currentPage - 1;

    if (prevPage >= 1) {
      dispatch({
        type: ordersConstants.SET_CURRENT_PAGE,
        payload: prevPage,
      });
    } else {
      return;
    }
  };

  return (
    <div className=" shadow-xl">
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr
              className=" border-0 border-b-2 text-left xl:text-xl"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <th
                  className="p-4 md:pr-10 md:pl-0 px-2"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <React.Fragment key={rowProps.key}>
                <tr className=" border-0 border-t" {...rowProps}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td className="py-2 xl:text-xl" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {user.role !== 3 && (
                    <td>
                      <button
                        onClick={() => handleBomClick(row.original)}
                        className={`${
                          (row.original as Order).cart_items.length === 0
                            ? "opacity-30 pointer-events-none"
                            : ""
                        } underline font-bold ml-4 xl:text-lg`}
                      >
                        Quote
                      </button>
                    </td>
                  )}
                  {/* {getIsSuperAdmin() && (
                    <td>
                      <Link
                        to={{
                          pathname: `/shipping-doc?id=${row.original._id}`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${
                          (row.original as Order).cart_items.length === 0
                            ? "pointer-events-none"
                            : ""
                        }`}
                      >
                        <button
                          className={`${
                            (row.original as Order).cart_items.length === 0
                              ? "opacity-30 pointer-events-none"
                              : ""
                          } underline font-bold ml-4 xl:text-lg`}
                        >
                          Shipping Doc
                        </button>
                      </Link>
                    </td>
                  )} */}
                  <td>
                    <img
                      src={addIcon}
                      className="cursor-pointer mx-4"
                      alt=""
                      onClick={() => addProduct(row.original)}
                    />
                  </td>
                  <td>
                    <img
                      src={editIcon}
                      className="cursor-pointer mr-4"
                      alt=""
                      onClick={() => editOrder(row.original)}
                    />
                  </td>
                  <td>
                    <img
                      src={deleteIcon}
                      onClick={() => removeOrderConfirm(row.original)}
                      className="cursor-pointer mr-4"
                      alt=""
                    />
                  </td>
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      <div className="m-4 flex justify-center">
                        <ProductsTable order={row.original} />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
          <tr></tr>
        </tbody>
      </table>
      <div className="pagination mt-4 p-4">
        <button
          className="border px-2 rounded-lg"
          onClick={() =>
            dispatch({
              type: ordersConstants.SET_CURRENT_PAGE,
              payload: 1,
            })
          }
          // disabled={!canPreviousPage}
        >
          {"<<"}
        </button>{" "}
        <button
          className="border px-2 rounded-lg"
          onClick={() => handlePrevClick()}
          // disabled={!canPreviousPage}
        >
          {"<"}
        </button>{" "}
        <button
          className="border px-2 rounded-lg"
          onClick={() => handleNextClick()}
          // disabled={!canNextPage}
        >
          {">"}
        </button>{" "}
        <button
          className="border px-2 rounded-lg"
          onClick={() =>
            dispatch({
              type: ordersConstants.SET_CURRENT_PAGE,
              payload: lastPage,
            })
          }
          // disabled={!canNextPage}
        >
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {currentPage} of {lastPage}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            className="border p-1 px-2 focus:outline-none"
            type="number"
            min={1}
            max={lastPage}
            defaultValue={currentPage}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 1;
              dispatch({
                type: ordersConstants.SET_CURRENT_PAGE,
                payload: page,
              });
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          className="border p-1 focus:outline-none"
          value={currentPageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            dispatch({
              type: ordersConstants.SET_PAGE_SIZE,
              payload: Number(e.target.value),
            });
          }}
        >
          {[10, 20, 30, 40].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

function OrdersTable({
  editOrder,
  showGenerateQuotationForm,
}: {
  editOrder: (order: Order) => void;
  showGenerateQuotationForm: () => void;
}) {
  const orders = useAppSelector((state) => state.orders.orders);
  const searchedOrders = useAppSelector((state) => state.orders.searchedOrders);
  const dispatch = useAppDispatch();
  const changeStatus = useCallback(
    (order: Order, status: 0 | 1 | 2) => {
      const updatedOrder: Order = JSON.parse(JSON.stringify(order));
      updatedOrder.status.status = status;

      patchOrder(updatedOrder)
        .then((data) => {
          dispatch({
            type: ordersConstants.UPDATE_ORDER,
            payload: updatedOrder,
          });
          toast.success("Product updated successfully");
        })
        .catch((error) => {
          toast.error("Error updating product");
        });
    },
    [dispatch]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }: any) => (
          <span {...row.getToggleRowExpandedProps()}>
            <img
              className={`transform transition-transform ml-auto px-4 ${
                row.isExpanded ? "rotate-180" : ""
              } `}
              src={dropDownIcon}
              alt=""
            />
          </span>
        ),
      },
      {
        Header: () => <span className="mx-0">ID</span>,
        id: "index",
        Cell: ({ row }: { row: { original: Order } }) => (
          <span data-tip={row.original._id} className=" cursor-pointer">
            {row.original._id?.slice(
              row.original._id.length - 4,
              row.original._id.length
            )}
          </span>
        ),
      },
      {
        Header: "Company Name",
        // accessor: "customerName",
        Cell: ({ row }: { row: { original: Order } }) => (
          <span className="mx-4">
            <ReactTooltip />
            {row.original.custom_data?.company_name ||
              row.original.customer.name}
          </span>
        ),
      },
      {
        Header: "POC's Email",
        // accessor: "customerEmail",
        Cell: ({ row }: { row: { original: Order } }) => (
          <span className="mx-4">
            <ReactTooltip />
            {row.original.customer.email}
          </span>
        ),
      },
      {
        Header: "Location",
        // accessor: "customerEmail",
        Cell: ({ row }: { row: { original: Order } }) => (
          <span className="">
            <ReactTooltip />
            {row.original.customer.location
              ? row.original.customer.location
              : ""}
          </span>
        ),
      },
      {
        Header: "CRM ID",
        // accessor: "salesForceId",
        Cell: ({ row }: { row: { original: Order } }) => (
          <span className="mx-4">
            <ReactTooltip />
            {row.original.lead_id}
          </span>
        ),
      },
      {
        Header: "Created at",
        Cell: ({ row }: any) => formatDateTime(row.original.created_at),
        accessor: "createdAt",
      },
      {
        Header: () => <span className="mx-4">Status</span>,
        id: "expander1",
        Cell: ({ row }: { row: { original: Order } }) => (
          <span className="mx-4">
            <ReactTooltip />
            <select
              className=" cursor-pointer"
              data-tip={
                row.original.status.status === 2
                  ? row.original.status.description
                  : null
              }
              name="status"
              value={row.original.status.status}
              onChange={(e) =>
                changeStatus(
                  row.original,
                  parseInt(e.target.value) as 0 | 1 | 2
                )
              }
              id=""
            >
              <option value="0">New</option>
              <option value="1">Received</option>
              <option value="2">Lost</option>
            </select>
          </span>
        ),
      },
    ],
    [changeStatus]
  );
  if (searchedOrders.length > 0) {
    return (
      <Table
        columns={columns}
        data={searchedOrders}
        editOrder={editOrder}
        showGenerateQuotationForm={showGenerateQuotationForm}
      />
    );
  } else {
    return (
      <Table
        columns={columns}
        data={orders}
        editOrder={editOrder}
        showGenerateQuotationForm={showGenerateQuotationForm}
      />
    );
  }
}

export default OrdersTable;
