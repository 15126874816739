import React, { useState } from "react";

type Props = {
  item: { options: { name: string }[]; name: string; rules?: any[] };
  handleOptionSelection: (obj: any) => void;
  value: string;
};

export default function DropDown({
  item,
  handleOptionSelection,
  value,
}: Props) {
  const [openDropDown, setOpenDropDown] = useState(false);

  return (
    <div className="flex w-full border-0 border-b flex-col px-4 py-4">
      <div className="flex cursor-pointer flex-col items-center mb-1">
        <h1 className="text-xs font-bold mr-auto items-center justify-center">
          {item.name}
        </h1>
      </div>
      <div
        onClick={() => setOpenDropDown(!openDropDown)}
        className="group inline-block relative w-full "
      >
        <button className=" w-full font-semibold rounded inline-flex items-center">
          <span className="mr-auto">{value || "---"}</span>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
        <ul
          style={{ display: openDropDown ? "block" : "none" }}
          className=" hidden border bg-white pt-1 group-hover:block h-32 overflow-y-auto"
        >
          {item.options.map((option, index) => (
            <li key={option.name} className="">
              <p
                className={`bg-white hover:bg-gray-300 cursor-pointer py-2 px-4 block whitespace-no-wrap ${
                  option.name === value ? "bg-gray-400 hover:bg-gray-400" : ""
                }`}
                onClick={() =>
                  handleOptionSelection({
                    name: item.name,
                    option,
                    rules: item.rules,
                  })
                }
              >
                {option.name}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
