import { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/hooks";
import RenderOptions from "../Components/RenderOptions";
import { Feature, Option } from "../types/types";
import useUserConfiguration from "../utils/updateUserConfiguration";
import { makeSessionRequest } from "../utils/makeSessionRequest";

export default function SecondaryBar({
  secondaryBarHidden,
  highlightSecondaryBar,
  closeSecondaryBar,
}: {
  secondaryBarHidden: boolean;
  highlightSecondaryBar: boolean;
  closeSecondaryBar: () => void;
}) {
  const [data, setData2] = useState<Feature[]>();

  const { updateUserConfiguration, getSelectedOption: getSelectedOption2 } =
    useUserConfiguration();
  const primaryBarSelection: any = useAppSelector(
    (state) => state.configuration.primaryBarSelection
  );

  const disabledFeatures = useAppSelector(
    (state) => state.configuration.disabledFeatures
  );

  const configData = useAppSelector((state) => state.configData.current);

  useEffect(() => {
    try {
      if (primaryBarSelection) {
        const getSecondaryBar2Data = () => {
          return configData.filter((item: any) => {
            return item.name === primaryBarSelection.name;
          })[0].options;
        };

        setData2(getSecondaryBar2Data());
      }
    } catch (error) {
      console.log("error");
    }
  }, [configData, primaryBarSelection, primaryBarSelection?.name]);

  const getSelectedOption = (subFeatureName: string) => {
    return getSelectedOption2(
      primaryBarSelection?.name,
      subFeatureName
    )?.filter((x) => typeof x !== "undefined");
  };

  const handleOptionSelection2 = (obj: any) => {
    updateUserConfiguration(primaryBarSelection?.name, obj);
  };

  const hasSubFeatures = (features: Feature[]) => {
    return features[0].options ? true : false;
  };

  return (
    <div
      className="z-20 w-max overflow-hidden bg-white px-1 flex absolute shadow-xl rounded-xl flex-col transform transition-all"
      style={{
        right: "5%",
        maxHeight: "80%",
        minWidth: "220px",
        maxWidth: "320px",
        height: "max-content",
        transform: secondaryBarHidden ? "translateX(150%)" : "",
      }}
    >
      <div className="flex mt-4 cursor-pointer py-1 items-center">
        <h1 className="text-sm 2xl:text-xl pl-4 font-bold mr-auto items-center justify-center">
          {primaryBarSelection?.name}
        </h1>
      </div>
      <div style={{ height: "max-content" }} className=" overflow-y-auto mb-5">
        {data && hasSubFeatures(data) ? (
          data
            .filter((subFeature) => !disabledFeatures.includes(subFeature.name))
            .map((subFeature) => (
              <RenderOptions
                selectedValue={getSelectedOption(subFeature.name)
                  .map((option) => option.name)
                  .join(",")}
                item={subFeature}
                handleOptionSelection={handleOptionSelection2}
                getSelectedOption={getSelectedOption}
              />
            ))
        ) : (
          <RenderOptions
            selectedValue={getSelectedOption("")
              .map((option) => option.name)
              .join(",")}
            item={{
              ...primaryBarSelection,
              name: "",
              options: data as Option[],
            }}
            handleOptionSelection={handleOptionSelection2}
            getSelectedOption={getSelectedOption}
          />
        )}
      </div>
      <div className="w-full bg-white ">
        <button
          style={{
            backgroundColor: highlightSecondaryBar ? "#555" : "",
            width: "90%",
          }}
          className="tracking-widest w-full text-xs 2xl:text-sm my-4 py-3 mx-2 px-8 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors"
          onClick={() => {
            closeSecondaryBar();
            makeSessionRequest("closed_secondary_modal");
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}
