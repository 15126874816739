// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const hide = "hide";
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const show = "show";
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const replacePart = "replacePart";
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const applyMaterial = "applyMaterial";

// export const ConfData3 = Object.freeze([
//   {
//     name: "System",
//     multiSelect: false,
//     options: [
//       {
//         name: "Discussion Table",
//         required: false,
//         options: [
//           {
//             name: "750 Dia",
//             size: 750,
//             Dia: 700,
//             thumbnail: "/assets/system/discussion1200Dia.png",
//             productId: "664f06839cb5c9d4cb118d5d",
//             tableTop: ["table_top"],
//             leftLegs: ["Leg"],
//             rightLegs: ["Leg"],
//             disableOptions:["Grommet","Flip-Up",]

//           },
//           {
//             name: "900 Dia",
//             size: 900,
//             Dia: 900,
//             thumbnail: "/assets/system/discussion900Dia.png",
//             productId: "6650412b115de7b77e49c8d2",
//             tableTop: ["table_top"],
//             leftLegs: ["Leg"],
//             rightLegs: ["Leg"],
//             disableOptions:["Pole","Flip-Up"]

//           },
//           {
//             name: "1050 Dia",
//             size: 1050,
//             Dia: 1050,
//             thumbnail: "/assets/system/discussion1050Dia.png",
//             productId: "665041519e80450b27ca72eb",
//             tableTop: ["table_top"],
//             leftLegs: ["Leg"],
//             rightLegs: ["Leg"],
//             disableOptions:["Pole","Grommet"]
//           },
//           {
//             name: "1200 Dia",
//             size: 1200,
//             Dia: 1200,
//             thumbnail: "/assets/system/discussion1200Dia.png",
//             productId: "6650417eef20dc6396c27c14",
//             tableTop: ["table_top"],
//             leftLegs: ["Leg"],
//             rightLegs: ["Leg"],
//             disableOptions:["Pole","Grommet"]
//           },
//         ],
//       },
//     ],
//   },

//   {
//     "name": "Legs",
//     "multiSelect": false,
//     "options": [
//       {
//         "name": "Discussion Table",
//         "required": false,
//         "options": [
//           {
//             "name": "Legs",
//             "required": false,
//             "options": [
//               {
//                 "name": "Eco",
//                 "thumbnail": "/assets/components/Colors-D3.png",
//                 "rules": [
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "https://content.helloviewer.io/uploads/38a5ba59-d99a-44f1-895c-1c3f843c94f0/24_4_2024/1716531823976/eco-leg.glb"
//                   },
//                   {
//                     "type": "show",
//                     "node": "connecter"
//                   }
//                 ]
//               },
//               {
//                 "name": "@Work",
//                 "thumbnail": "/assets/components/Connect-D3.png",
//                 "rules": [
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "https://content.helloviewer.io/uploads/7072ba09-b897-4b4a-b9f8-641e6a773b2a/25_4_2024/1716635340792/-work-leg.glb"
//                   },
//                   {
//                     "type": "hide",
//                     "node": "connecter"
//                   }
//                 ]
//               },
//               {
//                 "name": "Scope",
//                 "thumbnail": "/assets/components/Collaborate-D3.png",
//                 "rules": [
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "https://content.helloviewer.io/uploads/04780bb4-36c0-4285-8c39-01189b175a92/25_4_2024/1716634690915/scope-leg.glb"
//                   },
//                   {
//                     "type": "hide",
//                     "node": "connecter"
//                   }
//                 ]
//               },
//               {
//                 "name": "Io",
//                 "thumbnail": "/assets/components/Synergy-D3.png",
//                 "rules": [
//                   {
//                     "type": "hide",
//                     "node": "connecter"
//                   },
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "https://content.helloviewer.io/uploads/6e6101be-b8d4-4886-8b0b-ac9e1f82928b/25_4_2024/1716634547843/IO.glb"
//                   }
//                 ]
//               },
//               {
//                 "name": "Pole",
//                 "thumbnail": "/assets/components/Perform-D3.png",
//                 "rules": [
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "https://content.helloviewer.io/uploads/86ffec40-07d5-427e-9ad3-8461640d90f4/24_4_2024/1716535639480/Pole-leg.glb"
//                   },
//                   {
//                     "type": "hide",
//                     "node": "connecter"
//                   }
//                 ],

//               },
//               {
//                 "name": "Styl",
//                 "thumbnail": "/assets/components/SwitchAngularSharing.png",
//                 "rules": [
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "63aa997444d4dfc09138e08f"
//                   },
//                   {
//                     "type": "hide",
//                     "node": "connecter"
//                   }
//                 ]
//               },
//               {
//                 "name": "Europa",
//                 "thumbnail": "/assets/components/SwitchAngularSharing.png",
//                 "rules": [
//                   {
//                     "type": "replacePart",
//                     "node": "leg",
//                     "pid": "63aa997444d4dfc09138e08f"
//                   },
//                   {
//                     "type": "hide",
//                     "node": "connecter"
//                   }
//                 ]
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },

//           // {
//           //   name: "Perform",
//           //   thumbnail: "/assets/components/Perform-D3.png",
//           //   rules: [
//           //     {
//           //       type: replacePart,
//           //       condition: [{ feature: "noOfSeats", values: [4] }],
//           //       node: "Leg",
//           //       pid: "628b44f69a6ff1e5b561f771",
//           //     },
//           //     {
//           //       type: replacePart,
//           //       condition: [{ feature: "noOfSeats", values: [6] }],
//           //       node: "Leg",
//           //       pid: "628c918f3b650396b703aa50",
//           //     },
//           //     {
//           //       type: replacePart,
//           //       condition: [{ feature: "noOfSeats", values: [8] }],
//           //       node: "Leg",
//           //       pid: "628c974d03c8f7c338050ee1",
//           //     },
//           //     {
//           //       type: show,
//           //       node: "beam",
//           //     },
//           //   ],
//           // },
//           // {
//           //   name: "Switch Straight",
//           //   thumbnail: "/assets/components/SwitchAngularSharing.png",
//           //   rules: [
//           //     {
//           //       type: replacePart,
//           //       condition: [{ feature: "noOfSeats", values: [4] }],
//           //       node: "Leg",
//           //       pid: "62833aabdb62c8c84837ee31",
//           //     },
//           //     {
//           //       type: replacePart,
//           //       condition: [{ feature: "noOfSeats", values: [6] }],
//           //       node: "Leg",
//           //       pid: "628c91ff2f032fac6cccc46b",
//           //     },
//           //     {
//           //       type: replacePart,
//           //       condition: [{ feature: "noOfSeats", values: [8] }],
//           //       node: "Leg",
//           //       pid: "628c978403c8f7c338050f02",
//           //     },
//           //     {
//           //       type: hide,
//           //       node: "beam",
//           //     },
//           //   ],
//           // },

//   {
//     name: "Wire Management",
//     multiSelect: true,
//     optional: false,
//     options: [
//       {
//         name: "Discussion Table",
//         required: false,
//         options: [
//           {
//             name: "Wire Management",
//             required: false,
//             options: [
//               {
//                 name: "None",
//                 thumbnail: "/assets/components/snake.png",
//                 rules: [
//                   {
//                     type: hide,
//                     node: ["gorment","FlipUp"],

//                   },
//                   {
//                     type: show,
//                     node: ["Table_top"],

//                   },
//                 ],
//               },
//               {
//                 name: "Grommet",
//                 thumbnail: "/assets/components/snake.png",
//                 rules: [
//                   {
//                     type: show,
//                     node: ["gorment"],

//                   },
//                 ],
//               },
//               {
//                 name: "Flip-Up",
//                 thumbnail: "/assets/components/snake.png",
//                 rules: [
//                   {
//                     type: show,
//                     node: ["FlipUp"],

//                   },
//                   {
//                     type: hide,
//                     node: ["gorment","Table_top"],

//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: "Laminate",
//     multiSelect: false,
//     optional: false,
//     options: [
//       {
//         name: "Discussion Table",
//         required: false,

//           options: [

//             {
//                 "name": "Mangfall_Beech",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg"
//                     }
//                 ]
//             },
//             {
//                 "name": "Acacia",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg"
//                     }
//                 ]
//             },
//             {
//                 "name": "Urban_Teak",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg"
//                     }
//                 ]
//             },
//             {
//                 "name": "SILVER_GREY_DIFFUSE",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png"
//                     }
//                 ]
//             },
//             {
//                 "name": "Off_White_diffuse",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png"
//                     }
//                 ]
//             },
//             {
//                 "name": "Graphite_Grey_diffuse",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png"
//                     }
//                 ]
//             },
//             {
//                 "name": "Cairo_Walnut_BaseColor",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png"
//                     }
//                 ]
//             },
//             {
//                 "name": "Maldau_Acacia_BaseColor",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png"
//                     }
//                 ]
//             },
//             {
//                 "name": "plain_DefaultMaterial_BaseColor",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png"
//                     }
//                 ]
//             },
//             {
//                 "name": "Thansau_Maple_BaseColor",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Wood",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png"
//                     }
//                 ]
//             }
//         ]
//       },
//     ]
//   },
//   {
//     name: "Powder Coat",
//     multiSelect: false,
//     optional: false,
//     options: [
//       {
//         name: "Discussion Table",
//         required: false,

//           options: [

//             {
//                 "name": "Graphite_grey",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Legs",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg"
//                     }
//                 ]
//             },
//             {
//                 "name": "Pure_white",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Legs",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg"
//                     }
//                 ]
//             },
//             {
//                 "name": "Nimbus_grey",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Legs",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg"
//                     }
//                 ]
//             },
//             {
//                 "name": "Super_matt_black",
//                 "thumbnail": "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
//                 "rules": [
//                     {
//                         "type": "applyMaterial",
//                         "node": "Legs",
//                         "textureUrl": "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg"
//                     }
//                 ]
//             }
//         ]
//       },
//     ]
//   }
// ]);

// export type ConfigData = typeof ConfData3;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

export const ConfData3 = Object.freeze([
  //basically for desking workstations
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "SHARING",

        required: false,
        options: [
          {
            name: "Two Seater Linear WKS",
            size: 2,
            thumbnail: "/assets/system/2 SS.png",
            productId: "664df007c2435f4579b6ef2b",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: " Four seater Linear WKS",
            size: 4,
            thumbnail: "/assets/system/4 SS.png",
            productId: "664deb3e4bb8588cd0932d08",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Six Seater Linear WKS",
            size: 6,
            thumbnail: "/assets/system/6 SS.png",
            productId: "664ddfac42fb3029f1ee5a6a",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Eight Seater  Linear WKS",
            size: 8,
            thumbnail: "/assets/system/8 SS.png",
            productId: "664dd72ebee1d1e7b4bf8ee0",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Ten Seater  Linear WKS",
            size: 10,
            thumbnail: "/assets/system/10 SS.png",
            productId: "664dc72fe24c826e131f3a58",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Twelve Seater  Linear WKS",
            size: 12,
            thumbnail: "/assets/system/12 SS.png",
            productId: "664dc481141d6073de406f7a",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Fourteen Seater Linear WKS",
            size: 14,
            thumbnail: "/assets/system/2 SS.png",
            productId: "664db57fbf76f40c4391542c",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Sixteen Seater Linear WKS",
            size: 16,
            thumbnail: "/assets/system/6 SS.png",
            productId: "664da855b9780401dc9a902f",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Eightteen Seater Linear WKS",
            size: 18,
            thumbnail: "/assets/system/8 SS.png",
            productId: "664d9b43c2c2fc27b3264928",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Twenty Seater Linear WKS",
            size: 20,
            thumbnail: "/assets/system/10 SS.png",
            productId: "664ca041902ee2673a726ede",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "TwentyTwo Seater  Linear WKS",
            size: 22,
            thumbnail: "/assets/system/12 SS.png",
            productId: "664d907646f058b142479e55",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Twenty Four Seater  Linear WKS",
            size: 24,
            thumbnail: "/assets/system/12 SS.png",
            productId: "664c3bbee6e3665074724121",
            disableOptions: [],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "One Seater",
            size: 1,
            thumbnail: "/assets/system/1NS.jpg",
            productId: "664ecbcc73e5f22dcc6ed2a7", //612fccfb5c51e05fa805f84e
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2 NS.jpg",
            productId: "664ee8e80b33fa67708c55fd", //612fcf025c51e05fa805f854
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Three Seater",
            size: 3,
            thumbnail: "/assets/system/3 NS.png",
            productId: "664f09e2e5556bb1236201a4",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Four Seater",
            size: 4,
            thumbnail: "/assets/system/4 NS.png",
            productId: "6650483e127637d9e30bbd8f", //612fd2be34bcffa99edb8e93
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Five Seater",
            size: 5,
            thumbnail: "/assets/system/5 NS.png",
            productId: "665046c1224e5de0089b65b2", //6650483e127637d9e30bbd8f
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Six Seater",
            size: 6,
            thumbnail: "/assets/system/6 NS.png",
            productId: "6650459b0aed6bc3aaacc870", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Seven Seater",
            size: 7,
            thumbnail: "/assets/system/6 NS.png",
            productId: "665043d311f482737cccaf78", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Eight Seater",
            size: 8,
            thumbnail: "/assets/system/6 NS.png",
            productId: "66503c8e70d7be8785578a42", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Nine Seater",
            size: 9,
            thumbnail: "/assets/system/6 NS.png",
            productId: "6650370f282083cfb17a7b8a", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Ten Seater",
            size: 10,
            thumbnail: "/assets/system/6 NS.png",
            productId: "665029fbdc55eb5c53a89bf7", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Eleven Seater",
            size: 11,
            thumbnail: "/assets/system/6 NS.png",
            productId: "665028bbdc6dce06882f34a1", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Twelve Seater",
            size: 12,
            thumbnail: "/assets/system/6 NS.png",
            productId: "6650275bfd1899798aaa9d1b", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "675 mm",
                value: 675,
              },
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "675 mm",
                value: 675,
              },
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Proceed Legs",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/11593e9d-89af-4623-a2bc-4c3850324e63/28_4_2024/1716879970969/left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/587698e7-d0d4-4372-9f81-74df3cef8cd8/28_4_2024/1716879924880/right-leg.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Legs",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7b88031a-7135-4df6-b34e-b0cc0b2675fa/28_4_2024/1716873065488/Right-Leg.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  // {
                  //   type: "hide",
                  //   node: ["right_leg", "left_leg"],
                  //   // pid: "6638e25215077125003fdfc9",// "https://content.helloviewer.io/uploads/cd71b040-9d2d-433d-bcad-b7c6b8fe7213/14_4_2024/1715692513793/left-leg.glb",//
                  // },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/8dc73338-14e4-4d8c-8bb7-2a9876bc312d/28_4_2024/1716873041800/Left-Leg.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Curvivo Legs",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/24aa7169-6d9f-453d-98bb-5000aad13c59/28_4_2024/1716908284413/curvivo-left.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/733684c8-3196-4c6a-9c69-0451efc284e4/28_4_2024/1716908263248/curvivo-right.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                ],
              },
              {
                name: "Tapper Leg",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/004952d2-6460-4c6b-a55f-1a3869d0882d/28_4_2024/1716880174050/left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/75aa6aa0-2c3c-4aa2-8bd2-0be06277612e/28_4_2024/1716880190643/right-leg.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                ],
              },
              // {
              //   name: "Scope",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["workconnect","metalmodestymid@work",'prelammid@work',"prelamleft",'prelamright','metalmodestyleft','metalmodestyright'],
              //     },

              //   ],
              // },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "GLASS_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "GLASS_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "GLASS_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Proceed Legs",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/cf129454-acd7-4e7b-a96b-9ef8f3c4150e/29_4_2024/1716977062166/left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/df2ff38b-15be-4281-9e08-d3b80e0cce24/29_4_2024/1716977023966/right-leg.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Legs",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a898cc6e-cca2-48c6-9485-82a127e4d394/29_4_2024/1716981742673/sleek-right.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  // {
                  //   type: "hide",
                  //   node: ["right_leg", "left_leg"],
                  //   // pid: "6638e25215077125003fdfc9",// "https://content.helloviewer.io/uploads/cd71b040-9d2d-433d-bcad-b7c6b8fe7213/14_4_2024/1715692513793/left-leg.glb",//
                  // },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/2fd63b06-6c06-404a-b89f-d140e220e41e/29_4_2024/1716981790943/sleek-left.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Curvivo Legs",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/5a4a280a-7f06-4a17-b1b2-a9ba302ee126/29_4_2024/1716978533863/curvivo-left.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/54cb0930-3a83-46a3-ac49-ca7cd0eae8b7/29_4_2024/1716978575522/curvivo-right.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                ],
              },
              {
                name: "Tapper Leg",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/8243f0a6-17aa-4982-9595-fa798b3889a3/29_4_2024/1716981335362/tapper-left.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/1f78ce70-bd39-4422-9b06-9eee661e5f79/29_4_2024/1716981313181/tapper-right.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                ],
              },
              // {
              //   name: "Color Options",
              //   required: false,
              //   options: [
              //     {
              //       name: "Red",
              //       thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Blue",
              //       thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Green",
              //       thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Yellow",
              //       thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Ivory Nandini",
              //       thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "White",
              //       thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Mustard Yellow",
              //       thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Orange",
              //       thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Dark Grey",
              //       thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Silver Grey",
              //       thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
              //         },
              //       ],
              //     },
              //     {
              //       name: "Black",
              //       thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
              //       rules: [
              //         {
              //           type: applyMaterial,
              //           node: "glass_colors",
              //           textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
              //         },
              //       ],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "show",
                    node: ["straight_edge"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/b24f742f-ba6a-4b21-a313-bc4960a438ca/27_4_2024/1716808338625/Left-Leg-0.glb",
                  },
                  // {
                  //   type: "replacePart",
                  //   node: "right_leg",
                  //   pid: "https://content.helloviewer.io/uploads/c6c0dc54-fe4d-4055-ac9c-2ffae195d499/27_4_2024/1716808402205/Right-Leg-0.glb",//"6638d0447f3062d784ce6ab6",
                  // },
                  // {
                  //   type: "show",
                  //   node: ["connecter"],
                  // },
                  {
                    type: "hide",
                    node: [
                      "chamfer_edge",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "postform_edge",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "show",
                    node: ["chamfer_edge"],
                    pid: "https://content.helloviewer.io/uploads/94e2a97b-f7f3-4508-a1cb-77e3e92706f2/27_4_2024/1716808657157/Right-Leg.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  // {
                  //   type: "hide",
                  //   node: ["right_leg", "left_leg"],
                  //   // pid: "6638e25215077125003fdfc9",// "https://content.helloviewer.io/uploads/cd71b040-9d2d-433d-bcad-b7c6b8fe7213/14_4_2024/1715692513793/left-leg.glb",//
                  // },
                  // {
                  //   type: "show",
                  //   node: ["connecter"],
                  // },
                  // {
                  //   type: "replacePart",
                  //   node: "left_leg",
                  //   pid:  "https://content.helloviewer.io/uploads/8ec6064a-6634-4067-92af-3093103c4ffd/27_4_2024/1716808689171/Left-Leg.glb",//"6638e25215077125003fdfc9",//
                  // },
                  {
                    type: "hide",
                    node: [
                      "straight_edge",
                      "Table_top",
                      "flipUp",
                      "postform_edge",
                      "postform_edge",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "@25 MM PLPB POST FORM BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "show",
                    node: "postform_edge",
                    pid: "663b574a7c7402d4fb5251e4",
                  },
                  {
                    type: "hide",
                    node: [
                      "chamfer_edge",
                      "straight_edge",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PRELAMINATED MDF BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b57ab7c7402d4fb5251f1",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b5783c90a726a227b7c03",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                ],
              },
              {
                name: "25 MM MDF POSTFORMED BAORD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PLY BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PLY POST FORMED BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM WHITE MARKER LAMINATE IN PARTICLE",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM MARINE PLY",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM BWP PLY",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PRELAMINATED E1 GRADE BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 THK COMM. PLY WITH 4 MM THK VENEER FINISH TOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "show",
                    node: ["straight_edge"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/b24f742f-ba6a-4b21-a313-bc4960a438ca/27_4_2024/1716808338625/Left-Leg-0.glb",
                  },
                  // {
                  //   type: "replacePart",
                  //   node: "right_leg",
                  //   pid: "https://content.helloviewer.io/uploads/c6c0dc54-fe4d-4055-ac9c-2ffae195d499/27_4_2024/1716808402205/Right-Leg-0.glb",//"6638d0447f3062d784ce6ab6",
                  // },
                  // {
                  //   type: "show",
                  //   node: ["connecter"],
                  // },
                  {
                    type: "hide",
                    node: [
                      "chamfer_edge",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "postform_edge",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "show",
                    node: ["chamfer_edge"],
                    pid: "https://content.helloviewer.io/uploads/94e2a97b-f7f3-4508-a1cb-77e3e92706f2/27_4_2024/1716808657157/Right-Leg.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  // {
                  //   type: "hide",
                  //   node: ["right_leg", "left_leg"],
                  //   // pid: "6638e25215077125003fdfc9",// "https://content.helloviewer.io/uploads/cd71b040-9d2d-433d-bcad-b7c6b8fe7213/14_4_2024/1715692513793/left-leg.glb",//
                  // },
                  // {
                  //   type: "show",
                  //   node: ["connecter"],
                  // },
                  // {
                  //   type: "replacePart",
                  //   node: "left_leg",
                  //   pid:  "https://content.helloviewer.io/uploads/8ec6064a-6634-4067-92af-3093103c4ffd/27_4_2024/1716808689171/Left-Leg.glb",//"6638e25215077125003fdfc9",//
                  // },
                  {
                    type: "hide",
                    node: [
                      "straight_edge",
                      "Table_top",
                      "flipUp",
                      "postform_edge",
                      "postform_edge",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "@25 MM PLPB POST FORM BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "show",
                    node: "postform_edge",
                    pid: "663b574a7c7402d4fb5251e4",
                  },
                  {
                    type: "hide",
                    node: [
                      "chamfer_edge",
                      "straight_edge",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PRELAMINATED MDF BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b57ab7c7402d4fb5251f1",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b5783c90a726a227b7c03",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                ],
              },
              {
                name: "25 MM MDF POSTFORMED BAORD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PLY BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PLY POST FORMED BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM WHITE MARKER LAMINATE IN PARTICLE",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM MARINE PLY",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM BWP PLY",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 MM PRELAMINATED E1 GRADE BOARD WTOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "25 THK COMM. PLY WITH 4 MM THK VENEER FINISH TOP",
                thumbnail: "/assets/components/ColorsSharing.png",
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "663b585c7fc0a1b8456448c6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "663b57db7c7402d4fb5251f9",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Raceway",
            required: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                    ],
                  },
                  {
                    type: show,
                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "/assets/components/racewaysmall.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: show,

                    node: ["flipup"],
                  },
                  {
                    type: hide,

                    node: [
                      "straight_edge",
                      "chamfer_edge",
                      "postform_edge",
                      "wipro_grommet",
                      "grommet",
                    ],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/components/racewaysmall.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: ["wipro_grommet"],
                  },
                  {
                    type: show,
                    node: ["grommet"],
                  },
                ],
              },
              {
                name: "Afc Grommet",
                thumbnail: "/assets/components/racewaysmall.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: ["grommet"],
                  },
                  {
                    type: show,
                    node: ["wipro_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "Raceway",
            required: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "Flip Up",
                      "Raceway",
                      "gorment2",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "/assets/components/racewaysmall.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: show,

                    node: ["flipUp"],
                  },
                  {
                    type: hide,

                    node: [
                      "straight_edge",
                      "chamfer_edge",
                      "postform_edge",
                      "wipro_grommet",
                      "grommet",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: ["wipro_grommet", "grommet"],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope", "IO", "@work"],
                      },
                    ],
                    node: ["mid_leg", "connecter"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/components/racewaysmall.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipUp",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    node: ["grommet", "straight_edge"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["grommet", "gorment2@work"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Afc Grommet",
                thumbnail: "/assets/components/racewaysmall.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: ["flipUp", "Raceway", "grommet"],
                  },
                  {
                    type: show,
                    node: ["wipro_grommet"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Options",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["wood"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "alumhalfandhalf",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "alumhalfandhalf",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "alumhalfandhalf",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "alumhalfandhalf",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "alumhalfandhalf",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "With Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["midmodesty"],
                  },
                ],
              },
              {
                name: "Without Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: "midmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "Leg Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "With Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["legmodesty"],
                  },
                ],
              },
              {
                name: "Without Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: ["legmodesty"],
                  },
                ],
              },
            ],
          },

          // {
          //   name: "Fabric Panel Accessories",
          //   nodeName: ["aluminium", "alumfabric"],
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       thumbnail: "/assets/components/none.jpg",
          //       pid: "",

          //       rules: [
          //         {
          //           type: "hide",
          //           node: ["hangingwhiteboard", "metal_nameplate","Alluminiumname_board","rail"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Accessories Rail",
          //       thumbnail: "/assets/components/metalmodesty.png",
          //       //pid: "6122f35afe408b1279847325",
          //       disableOptions: [""],
          //       // disableFeatures: ["Color Options Privacy"],
          //       // enableOptions: ['ColorOptionsPrivacy'],
          //       rules: [
          //         {
          //           type: "show",
          //           node: ["rail"],
          //         },

          //       ],
          //     },
          //     {
          //       name: "Metal Nameplate",
          //       thumbnail: "/assets/components/metalmodesty.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // disableFeatures: ["Color Options Privacy"],
          //       rules: [
          //         {
          //           type: "show",
          //           node: ["metal_nameplate"],
          //         },
          //         {
          //           type: "hide",
          //           node: ["Alluminiumname_board"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Alluminiumname_board",
          //       thumbnail: "/assets/components/metalmodesty.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // enableFeatures: ["Color Options Privacy"],
          //       rules: [
          //         {
          //           type: "show",
          //           node: ["Alluminiumname_board"],
          //         },
          //         {
          //           type: "hide",
          //           node: ["metal_nameplate"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Hanging whiteboard",
          //       thumbnail: "/assets/components/metalmodesty.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // enableFeatures: ["Color Options Privacy"],

          //         rules: [
          //           {
          //             type: "show",
          //             node: ["hangingwhiteboard"],
          //           },

          //       ],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "alumhalfandhalf",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "alumhalfandhalf",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "alumhalfandhalf",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "alumhalfandhalf",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "alumhalfandhalf",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "With Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["midmodesty"],
                  },
                ],
              },
              {
                name: "Without Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: "midmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "Leg Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "With Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["modesty"],
                  },
                ],
              },
              {
                name: "Without Modesty",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: "modesty",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Color Options Privacy",

    options: [
      {
        name: "SHARING",
        required: false,
        nodeName: ["fabric"],

        options: [
          {
            name: "Apple Green",
            thumbnail: "/assets/textures/Glass/Apple Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Glass/Apple Green.jpg",
              },
            ],
          },
          {
            name: "Aqua Maring",
            thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
              },
            ],
          },
          {
            name: "Azure Blue",
            thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
              },
            ],
          },
          {
            name: "Beige",
            thumbnail: "/assets/textures/Glass/Beige.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Beige.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "/assets/textures/Glass/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Black.jpg",
              },
            ],
          },
          {
            name: "Canary Yellow",
            thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
              },
            ],
          },
          {
            name: "Casis Purple",
            thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
              },
            ],
          },
          {
            name: "Classic Ivory",
            thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
              },
            ],
          },
          {
            name: "Crystal White",
            thumbnail: "/assets/textures/Glass/Crystal White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Crystal White.jpg",
              },
            ],
          },
          {
            name: "Dark Blue",
            thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
              },
            ],
          },
          {
            name: "Dark Bronze",
            thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
              },
            ],
          },
          {
            name: "Dark Lavender",
            thumbnail: "/assets/textures/Glass/dark lavender.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/dark lavender.jpg",
              },
            ],
          },
          {
            name: "Dark Red",
            thumbnail: "/assets/textures/Glass/Dark Red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Dark Red.jpg",
              },
            ],
          },
          {
            name: "Ergo Grey",
            thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze Frosted",
            thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze",
            thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
              },
            ],
          },
          {
            name: "Euro Grey",
            thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
              },
            ],
          },
          {
            name: "Flame Red",
            thumbnail: "/assets/textures/Glass/Flame-red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Flame-red.jpg",
              },
            ],
          },
          {
            name: "Frosted White",
            thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
              },
            ],
          },
          {
            name: "Green Lime",
            thumbnail: "/assets/textures/Glass/Green Lime.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Green Lime.jpg",
              },
            ],
          },
          {
            name: "Mediterranean Blue",
            thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
              },
            ],
          },
          {
            name: "Ocean Green",
            thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
              },
            ],
          },
          {
            name: "Olive Green",
            thumbnail: "/assets/textures/Glass/Olive Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Olive Green.jpg",
              },
            ],
          },
          {
            name: "Orange Mist",
            thumbnail: "/assets/textures/Glass/orange mist.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/orange mist.jpg",
              },
            ],
          },
          {
            name: "Sky Blue",
            thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
              },
            ],
          },
          {
            name: "Solar Yellow",
            thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
              },
            ],
          },
          {
            name: "Steel Grey",
            thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
              },
            ],
          },
          {
            name: "Sunny Orange",
            thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
              },
            ],
          },
          {
            name: "Super White",
            thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
              },
            ],
          },
          {
            name: "Tangerine Orange",
            thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        nodeName: ["Alum_panelscreen"],

        options: [
          {
            name: "Apple Green",
            thumbnail: "/assets/textures/Glass/Apple Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Apple Green.jpg",
              },
            ],
          },
          {
            name: "Aqua Maring",
            thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
              },
            ],
          },
          {
            name: "Azure Blue",
            thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
              },
            ],
          },
          {
            name: "Beige",
            thumbnail: "/assets/textures/Glass/Beige.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Beige.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "/assets/textures/Glass/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Black.jpg",
              },
            ],
          },
          {
            name: "Canary Yellow",
            thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
              },
            ],
          },
          {
            name: "Casis Purple",
            thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
              },
            ],
          },
          {
            name: "Classic Ivory",
            thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
              },
            ],
          },
          {
            name: "Crystal White",
            thumbnail: "/assets/textures/Glass/Crystal White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Crystal White.jpg",
              },
            ],
          },
          {
            name: "Dark Blue",
            thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
              },
            ],
          },
          {
            name: "Dark Bronze",
            thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
              },
            ],
          },
          {
            name: "Dark Lavender",
            thumbnail: "/assets/textures/Glass/dark lavender.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/dark lavender.jpg",
              },
            ],
          },
          {
            name: "Dark Red",
            thumbnail: "/assets/textures/Glass/Dark Red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Dark Red.jpg",
              },
            ],
          },
          {
            name: "Ergo Grey",
            thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze Frosted",
            thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze",
            thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
              },
            ],
          },
          {
            name: "Euro Grey",
            thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
              },
            ],
          },
          {
            name: "Flame Red",
            thumbnail: "/assets/textures/Glass/Flame-red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Flame-red.jpg",
              },
            ],
          },
          {
            name: "Frosted White",
            thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
              },
            ],
          },
          {
            name: "Green Lime",
            thumbnail: "/assets/textures/Glass/Green Lime.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Green Lime.jpg",
              },
            ],
          },
          {
            name: "Mediterranean Blue",
            thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
              },
            ],
          },
          {
            name: "Ocean Green",
            thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
              },
            ],
          },
          {
            name: "Olive Green",
            thumbnail: "/assets/textures/Glass/Olive Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Olive Green.jpg",
              },
            ],
          },
          {
            name: "Orange Mist",
            thumbnail: "/assets/textures/Glass/orange mist.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/orange mist.jpg",
              },
            ],
          },
          {
            name: "Sky Blue",
            thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
              },
            ],
          },
          {
            name: "Solar Yellow",
            thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
              },
            ],
          },
          {
            name: "Steel Grey",
            thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
              },
            ],
          },
          {
            name: "Sunny Orange",
            thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
              },
            ],
          },
          {
            name: "Super White",
            thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
              },
            ],
          },
          {
            name: "Tangerine Orange",
            thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
            rules: [
              {
                type: applyMaterial,
                node: "Alum_panelscreen",
                textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Glass Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Apple Green",
            thumbnail: "/assets/textures/Glass/Apple Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Apple Green.jpg",
              },
            ],
          },
          {
            name: "Aqua Maring",
            thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
              },
            ],
          },
          {
            name: "Azure Blue",
            thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
              },
            ],
          },
          {
            name: "Beige",
            thumbnail: "/assets/textures/Glass/Beige.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Beige.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "/assets/textures/Glass/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Black.jpg",
              },
            ],
          },
          {
            name: "Canary Yellow",
            thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
              },
            ],
          },
          {
            name: "Casis Purple",
            thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
              },
            ],
          },
          {
            name: "Classic Ivory",
            thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
              },
            ],
          },
          {
            name: "Crystal White",
            thumbnail: "/assets/textures/Glass/Crystal White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Crystal White.jpg",
              },
            ],
          },
          {
            name: "Dark Blue",
            thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
              },
            ],
          },
          {
            name: "Dark Bronze",
            thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
              },
            ],
          },
          {
            name: "Dark Lavender",
            thumbnail: "/assets/textures/Glass/dark lavender.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/dark lavender.jpg",
              },
            ],
          },
          {
            name: "Dark Red",
            thumbnail: "/assets/textures/Glass/Dark Red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Red.jpg",
              },
            ],
          },
          {
            name: "Ergo Grey",
            thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze Frosted",
            thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze",
            thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
              },
            ],
          },
          {
            name: "Euro Grey",
            thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
              },
            ],
          },
          {
            name: "Flame Red",
            thumbnail: "/assets/textures/Glass/Flame-red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Flame-red.jpg",
              },
            ],
          },
          {
            name: "Frosted White",
            thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
              },
            ],
          },
          {
            name: "Green Lime",
            thumbnail: "/assets/textures/Glass/Green Lime.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Green Lime.jpg",
              },
            ],
          },
          {
            name: "Mediterranean Blue",
            thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
              },
            ],
          },
          {
            name: "Ocean Green",
            thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
              },
            ],
          },
          {
            name: "Olive Green",
            thumbnail: "/assets/textures/Glass/Olive Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Olive Green.jpg",
              },
            ],
          },
          {
            name: "Orange Mist",
            thumbnail: "/assets/textures/Glass/orange mist.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/orange mist.jpg",
              },
            ],
          },
          {
            name: "Sky Blue",
            thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
              },
            ],
          },
          {
            name: "Solar Yellow",
            thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
              },
            ],
          },
          {
            name: "Steel Grey",
            thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
              },
            ],
          },
          {
            name: "Sunny Orange",
            thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
              },
            ],
          },
          {
            name: "Super White",
            thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
              },
            ],
          },
          {
            name: "Tangerine Orange",
            thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Apple Green",
            thumbnail: "/assets/textures/Glass/Apple Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Apple Green.jpg",
              },
            ],
          },
          {
            name: "Aqua Maring",
            thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
              },
            ],
          },
          {
            name: "Azure Blue",
            thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
              },
            ],
          },
          {
            name: "Beige",
            thumbnail: "/assets/textures/Glass/Beige.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Beige.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "/assets/textures/Glass/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Black.jpg",
              },
            ],
          },
          {
            name: "Canary Yellow",
            thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
              },
            ],
          },
          {
            name: "Casis Purple",
            thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
              },
            ],
          },
          {
            name: "Classic Ivory",
            thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
              },
            ],
          },
          {
            name: "Crystal White",
            thumbnail: "/assets/textures/Glass/Crystal White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Crystal White.jpg",
              },
            ],
          },
          {
            name: "Dark Blue",
            thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
              },
            ],
          },
          {
            name: "Dark Bronze",
            thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
              },
            ],
          },
          {
            name: "Dark Lavender",
            thumbnail: "/assets/textures/Glass/dark lavender.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/dark lavender.jpg",
              },
            ],
          },
          {
            name: "Dark Red",
            thumbnail: "/assets/textures/Glass/Dark Red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Dark Red.jpg",
              },
            ],
          },
          {
            name: "Ergo Grey",
            thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze Frosted",
            thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze",
            thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
              },
            ],
          },
          {
            name: "Euro Grey",
            thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
              },
            ],
          },
          {
            name: "Flame Red",
            thumbnail: "/assets/textures/Glass/Flame-red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Flame-red.jpg",
              },
            ],
          },
          {
            name: "Frosted White",
            thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
              },
            ],
          },
          {
            name: "Green Lime",
            thumbnail: "/assets/textures/Glass/Green Lime.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Green Lime.jpg",
              },
            ],
          },
          {
            name: "Mediterranean Blue",
            thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
              },
            ],
          },
          {
            name: "Ocean Green",
            thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
              },
            ],
          },
          {
            name: "Olive Green",
            thumbnail: "/assets/textures/Glass/Olive Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Olive Green.jpg",
              },
            ],
          },
          {
            name: "Orange Mist",
            thumbnail: "/assets/textures/Glass/orange mist.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/orange mist.jpg",
              },
            ],
          },
          {
            name: "Sky Blue",
            thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
              },
            ],
          },
          {
            name: "Solar Yellow",
            thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
              },
            ],
          },
          {
            name: "Steel Grey",
            thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
              },
            ],
          },
          {
            name: "Sunny Orange",
            thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
              },
            ],
          },
          {
            name: "Super White",
            thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
              },
            ],
          },
          {
            name: "Tangerine Orange",
            thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
            rules: [
              {
                type: applyMaterial,
                node: "GLASS",
                textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "FABRIC",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: show,
                node: ["Fabric_Right", "Fabric_Left"],
              },
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                ],
              },
            ],
          },
          {
            name: "CPU Stand",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
                // pid: "663884d5ef16e940c9752698", //611a127008dc8e016c4e8f22
              },

              // {
              //   type: "hide",
              //   node: [
              //     "metal_nameplate",
              //     "CPU_stand",
              //     "hangingcpustand",
              //     "Key&mousetray",
              //     "Key_board_tray",
              //     "hangingwhiteboard"
              //   ],
              // },
              //
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER 300WX300H",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER 450WX300H",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_1",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "hangingmetalpapertray",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_2"],
              },
            ],
          },
          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "hangingmetalpapertray",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },
          {
            name: "HANGING METAL PAPER TRAY 222L",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "hangingmetalmarker_2",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalpapertray"],
              },
            ],
          },
          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_1",
                  "hangingmetalpapertray",
                  "metaldividerstand",
                  "hangingmetalmarker_2",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },
          {
            name: "METAL SINGLE PAPER HOLDER",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_1",
                  "hangingmetalpapertray",
                  "metaldividerstand",
                  "hangingmetalmarker_2",
                  "panelmountednameplate",
                  "haningmetalpenholder",
                ],
              },
              {
                type: "show",
                node: ["metalsinglepaperholder"],
              },
            ],
          },
          {
            name: "METAL LAPTOP STAND",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["metallaptopstand"],
              },
            ],
          },
          {
            name: "METAL MOBILE STAND",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["metalmobilestand"],
              },
            ],
          },
          {
            name: "METAL DIVIDER STAND",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "show",
                node: ["metaldividerstand"],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "Alluminiumname_board",
                  "metal_nameplate",
                  "rail",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingwhiteboard",
                ],
              },
            ],
          },
          {
            name: "CPU Stand",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
                // pid: "663884d5ef16e940c9752698", //611a127008dc8e016c4e8f22
              },

              // {
              //   type: "hide",
              //   node: [
              //     "metal_nameplate",
              //     "CPU_stand",
              //     "hangingcpustand",
              //     "Key&mousetray",
              //     "Key_board_tray",
              //     "hangingwhiteboard"
              //   ],
              // },
              //
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
