import { features } from "process";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Prelam Storages",
        required: false,
        options: [
          {
            name: "LOW HEIGHT AND MID HEIGHT STORAGE",
            disableFeatures: ["Width (High)", "Depth (High)", "Height (High)"],
            enableFeatures: ["Width", "Depth", "Height"],
            size: 1,
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/storage/lh.png",
            productId: "666067d7a37efe5006ff936e",
          },

          {
            name: "FULL HEIGHT STORAGE",
            size: 2,
            Length: 1800,
            Depth: 900,
            disableFeatures: ["Width", "Depth", "Height"],
            enableFeatures: ["Width (High)", "Depth (High)", "Height (High)"],
            thumbnail: "/assets/afc/thumnail/storage/fh.png",
            productId: "665ff3f9935569dc3b41e317 ",
          },

          {
            name: "COMBO STORAGE WITH OPENABLE SHUTTERS",
            disableFeatures: ["Width (High)", "Depth (High)", "Height (High)"],
            enableFeatures: ["Width", "Depth", "Height"],
            size: 4,
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/storage/cs.png",
            disableOptions: ["Groove Handle"],
            rules: [
              {
                type: hide,
                node: [
                  "lock",
                  "numberlock",
                  "ihandle",
                  "dhandle",
                  "straighthandle",
                ],
              },
            ],
            productId: "6666e781e74cae028227591e",
          },
          {
            name: "STORAGE WITH SLIDING SHUTTERS",
            disableFeatures: ["Width (High)", "Depth (High)", "Height (High)"],
            enableFeatures: ["Width", "Depth", "Height"],
            size: 5,
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/storage/sss.png",
            productId: "665ff213935569dc3b41e28e",
          },
        ],
      },
      {
        name: "Metal Storages",
        required: false,
        options: [
          {
            name: "LOW HEIGHT AND MID HEIGHT STORAGE",
            disableFeatures: ["Width (High)", "Depth (High)", "Height (High)"],
            enableFeatures: ["Width", "Depth", "Height"],
            size: 1,
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/storage/lh.png",
            productId: "66e29afd314d16a0df2e0aa1",
          },
          // {
          //   name: "MID HEIGHT STORAGE WITH OPENABLE SHUTTERS",
          //   size: 1.1,
          //   Length: 1800,
          //   Depth: 900,
          //   thumbnail: "/assets/afc/thumnail/storage/metalmh.png",
          //   productId: "665963d018985f8904c7234e",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: "WOOD",
          //       textureUrl: "/assets/textures/Wood/2k/Black.jpg",
          //     },
          //     {
          //       type: applyMaterial,
          //       node: "carcass",
          //       textureUrl: "/assets/textures/Wood/2k/Black.jpg",
          //     },
          //   ],
          // },
          {
            name: "FULL HEIGHT STORAGE",
            size: 2,
            Length: 1800,
            Depth: 900,
            disableFeatures: ["Width", "Depth", "Height"],
            enableFeatures: ["Width (High)", "Depth (High)", "Height (High)"],
            thumbnail: "/assets/afc/thumnail/storage/fh.png",
            productId: "66e2d9f98e606695c77089d2 ",
          },
        ],
      },
    ],
  },

  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Prelam Storages",
        multiselect: false,

        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,

            options: [
              {
                name: "450 mm",
                value: 450,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/ed698339-a99c-4e38-b2a8-f3040df074f5/11_8_2024/1726037535097/lowheight-1shutter.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/dd9f589f-5f23-4cb7-98c9-4ab4463a3886/11_8_2024/1726038568704/MidHeight-1Shutter.glb",
                  },

                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "600 mm",
                value: 600,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0115a857-f06e-4a05-899a-9d3894110f97/11_8_2024/1726061888745/LowHeight-2doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/a8840e99-3dac-424f-972a-185d1a49c612/11_8_2024/1726062195206/MidHeight-2doors.glb",
                  },

                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "750 mm",
                value: 750,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0115a857-f06e-4a05-899a-9d3894110f97/11_8_2024/1726061888745/LowHeight-2doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/a8840e99-3dac-424f-972a-185d1a49c612/11_8_2024/1726062195206/MidHeight-2doors.glb",
                  },

                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0115a857-f06e-4a05-899a-9d3894110f97/11_8_2024/1726061888745/LowHeight-2doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/a8840e99-3dac-424f-972a-185d1a49c612/11_8_2024/1726062195206/MidHeight-2doors.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1050 mm",
                value: 1050,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/ceb2909f-459f-426e-b2d3-99e7353d2855/11_8_2024/1726061968189/LowHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0f03da6f-cd4e-4daf-9238-26581beb2a23/11_8_2024/1726062368861/MidHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/1fd462cf-94b9-43df-95e3-1d2e757f3c7c/12_5_2024/1718190125559/largeheight-4doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1200 mm",
                value: 1200,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/ceb2909f-459f-426e-b2d3-99e7353d2855/11_8_2024/1726061968189/LowHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0f03da6f-cd4e-4daf-9238-26581beb2a23/11_8_2024/1726062368861/MidHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/e0cb2629-7fe3-4077-8551-b8e587f860e7/11_8_2024/1726062765468/largeheight-8doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1350 mm",
                value: 1350,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/ceb2909f-459f-426e-b2d3-99e7353d2855/11_8_2024/1726061968189/LowHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0f03da6f-cd4e-4daf-9238-26581beb2a23/11_8_2024/1726062368861/MidHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/3940d03d-3d73-4496-8375-6f27998febf1/12_5_2024/1718192482055/largeheight-6doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1500 mm",
                value: 1500,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/59108a91-ef48-44d1-83ee-510e3705083a/11_8_2024/1726038279719/lowHeight-4doors.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Prelam Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0d685ba2-e7c6-46c3-a042-6e6a51c3fdc4/11_8_2024/1726039522236/MidHeight-4doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/3940d03d-3d73-4496-8375-6f27998febf1/12_5_2024/1718192482055/largeheight-6doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width (High)",
            required: false,
            dropDown: true,

            options: [
              {
                name: "450 mm",
                value: 450,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/5e2385a0-d2d1-4638-b5bd-d611524b4247/11_8_2024/1726062569892/largeheight-4doors.glb",
                  },
                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "600 mm",
                value: 600,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/5e2385a0-d2d1-4638-b5bd-d611524b4247/11_8_2024/1726062569892/largeheight-4doors.glb",
                  },
                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/5e2385a0-d2d1-4638-b5bd-d611524b4247/11_8_2024/1726062569892/largeheight-4doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "1200 mm",
                value: 1200,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Prelam Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/e0cb2629-7fe3-4077-8551-b8e587f860e7/11_8_2024/1726062765468/largeheight-8doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Depth (High)",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          {
            name: "Height (High)",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1800 mm",
                value: 1800,
              },
              {
                name: "1950 mm",
                value: 1950,
              },
              {
                name: "2100 mm",
                value: 2100,
              },
              {
                name: "2250 mm",
                value: 2250,
              },
              {
                name: "2400 mm",
                value: 2400,
              },
            ],
          },
        ],
      },
      {
        name: "Metal Storages",
        multiselect: false,

        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,

            options: [
              {
                name: "450 mm",
                value: 450,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/f106e44a-494f-43e9-91ca-58e33bace310/12_8_2024/1726127403537/1-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/ad91848c-38da-4bd4-bfdd-5489177d5944/12_8_2024/1726130379895/midheight-1doors.glb",
                  },

                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "600 mm",
                value: 600,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/b873e457-3634-452e-82ed-5b8e215b4f20/12_8_2024/1726127839330/2-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/d82ea58a-548b-40c1-8c7d-302b8b9907e5/12_8_2024/1726132073023/midheight-2doors.glb",
                  },

                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "750 mm",
                value: 750,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/b873e457-3634-452e-82ed-5b8e215b4f20/12_8_2024/1726127839330/2-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/d82ea58a-548b-40c1-8c7d-302b8b9907e5/12_8_2024/1726132073023/midheight-2doors.glb",
                  },

                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/b873e457-3634-452e-82ed-5b8e215b4f20/12_8_2024/1726127839330/2-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/d82ea58a-548b-40c1-8c7d-302b8b9907e5/12_8_2024/1726132073023/midheight-2doors.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1050 mm",
                value: 1050,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/7517ec17-599a-484d-b182-a15186d8c15f/12_8_2024/1726128446391/3-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0f03da6f-cd4e-4daf-9238-26581beb2a23/11_8_2024/1726062368861/MidHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/1fd462cf-94b9-43df-95e3-1d2e757f3c7c/12_5_2024/1718190125559/largeheight-4doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1200 mm",
                value: 1200,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/7517ec17-599a-484d-b182-a15186d8c15f/12_8_2024/1726128446391/3-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0f03da6f-cd4e-4daf-9238-26581beb2a23/11_8_2024/1726062368861/MidHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/e0cb2629-7fe3-4077-8551-b8e587f860e7/11_8_2024/1726062765468/largeheight-8doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1350 mm",
                value: 1350,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/7517ec17-599a-484d-b182-a15186d8c15f/12_8_2024/1726128446391/3-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/0f03da6f-cd4e-4daf-9238-26581beb2a23/11_8_2024/1726062368861/MidHeight-3doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/3940d03d-3d73-4496-8375-6f27998febf1/12_5_2024/1718192482055/largeheight-6doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "1500 mm",
                value: 1500,
                rules: [
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [750, 900, 1050],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/be070123-c988-46c5-ba35-c2c8a247e1b0/12_8_2024/1726129487613/4-shutter-lowhieght.glb",
                  },
                  {
                    type: replacePart,
                    multipleConditions: [
                      {
                        feature: "Metal Storages",
                        values: ["LOW HEIGHT AND MID HEIGHT STORAGE"],
                      },
                      { operator: "AND" },
                      {
                        feature: "Height",
                        values: [1200, 1350, 1500],
                      },
                    ],

                    node: "1_shutters_lowhieght",
                    pid: "https://content.helloviewer.io/uploads/c0ab652a-18b7-45da-892c-96b2af294895/12_8_2024/1726136029897/midheight-4doors.glb",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/3940d03d-3d73-4496-8375-6f27998febf1/12_5_2024/1718192482055/largeheight-6doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width (High)",
            required: false,
            dropDown: true,

            options: [
              {
                name: "450 mm",
                value: 450,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/22a566a3-e467-42ef-8fb9-c76e59e9e3c1/12_8_2024/1726143042346/Largeheight-4doors.glb",
                  },
                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "600 mm",
                value: 600,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/22a566a3-e467-42ef-8fb9-c76e59e9e3c1/12_8_2024/1726143042346/Largeheight-4doors.glb ",
                  },
                  {
                    type: hide,

                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/22a566a3-e467-42ef-8fb9-c76e59e9e3c1/12_8_2024/1726143042346/Largeheight-4doors.glb ",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },

              {
                name: "1200 mm",
                value: 1200,
                rules: [
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Metal Storages",
                        values: ["FULL HEIGHT STORAGE"],
                      },
                    ],
                    node: "largeheight",
                    pid: "https://content.helloviewer.io/uploads/be3f63ae-e21b-4acf-ad69-5ca689c8dfac/12_8_2024/1726143756583/Largeheight-8doors.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "lock",
                      "numberlock",
                      "ihandle",
                      "dhandle",
                      "straighthandle",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Depth (High)",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          {
            name: "Height (High)",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1800 mm",
                value: 1800,
              },
              {
                name: "1950 mm",
                value: 1950,
              },
              {
                name: "2100 mm",
                value: 2100,
              },
              {
                name: "2250 mm",
                value: 2250,
              },
              {
                name: "2400 mm",
                value: 2400,
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   name: "Size",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Prelam Storages",
  //       required: false,
  //       options: [
  //         {
  //           name: "Legs",
  //           required: false,
  //           options: [
  //             {
  //               name: "Very Small",
  //               required: false,
  //               rule: [
  //                 {
  //                   type: replacePart,
  //                   node: "1_shutter_lowhieght",
  //                   pid: "https://viewer.shape.helloar.io/?id=666067d7a37efe5006ff936e ",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Width",
  //           required: false,
  //           options: [
  //             {
  //               name: "450",
  //             },
  //             {
  //               name: "600-900",
  //             },
  //             {
  //               name: "1050-1350",
  //             },
  //             {
  //               name: "1350-1800",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Metal Storages",
  //       required: false,
  //       options: [
  //         {
  //           name: "Very small",
  //           required: false,
  //         },
  //         {
  //           name: "Small",
  //           required: false,
  //         },
  //         {
  //           name: "Medium",
  //           required: false,
  //         },
  //         {
  //           name: "Large",
  //           required: false,
  //         },
  //         {
  //           name: " Very Large",
  //           required: false,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Shutter",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Storages",
        required: false,
        options: [
          {
            name: "With Shutter",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            enableOptions: [
              "I Handle",
              "Groove Handle",
              "Key Lock",
              "2 Individual KeyLocks",
              "Numeric Lock",
              "2 Individual Numeric Locks",
            ],
            rules: [
              {
                type: show,
                node: ["groovehandle"],
              },
            ],
          },
          {
            name: "Without Shutter",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            disableOptions: [
              "I Handle",
              "Groove Handle",
              "Key Lock",
              "D Handle",
              "2 Individual KeyLocks",
              "Numeric Lock",
              "2 Individual Numeric Locks",
            ],
            rules: [
              {
                type: hide,
                node: ["groovehandle"],
              },
            ],
          },
        ],
      },
      {
        name: "Metal Storages",
        required: false,
        options: [
          {
            name: "With Shutter",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            enableOptions: [
              "I Handle",
              "Groove Handle",
              "Key Lock",
              "2 Individual KeyLocks",
              "Numeric Lock",
              "2 Individual Numeric Locks",
            ],
            rules: [
              {
                type: show,
                node: ["groovehandle"],
              },
            ],
          },
          {
            name: "Without Shutter",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            disableOptions: [
              "I Handle",
              "Groove Handle",
              "Key Lock",
              "2 Individual KeyLocks",
              "Numeric Lock",
              "2 Individual Numeric Locks",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "dhandle",
                  "groovehandle",
                  "groovehandle",
                  "ihandle",
                  "lock",
                  "numberlock",
                  "bottom",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  //   {
  //     name: "Handle",
  //     multiSelect: false,
  //     optional: false,
  //     options: [
  //       {
  //         name: "Prelam Storages",
  //         required: false,
  //         options: [
  //           {
  //             name: "I Handle",
  //             thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             rules: [
  //               {
  //                 type: show,
  //                 node: ["ihandle", "front_1", "front_2"],
  //               },
  //               {
  //                 type: hide,
  //                 condition: [
  //                   { feature: "Shutter", values: ["Without Shutter"] },
  //                 ],

  //                 node: [
  //                   "lock",
  //                   "numberlock",
  //                   "ihandle",
  //                   "dhandle",
  //                   "groovehandle",
  //                   "straighthandle",
  //                 ],
  //               },
  //               {
  //                 type: hide,
  //                 node: ["groovehandle", "dhandle"],
  //               },
  //             ],
  //           },
  //           {
  //             name: "Groove Handle",
  //             thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             rules: [
  //               {
  //                 type: show,
  //                 node: ["groovehandle"],
  //               },
  //               {
  //                 type: hide,
  //                 node: ["dhandle", "ihandle"],
  //               },
  //               {
  //                 type: hide,
  //                 condition: [
  //                   { feature: "Shutter", values: ["Without Shutter"] },
  //                 ],

  //                 node: [
  //                   "lock",
  //                   "numberlock",
  //                   "ihandle",
  //                   "dhandle",
  //                   "groovehandle",
  //                   "straighthandle",
  //                 ],
  //               },
  //             ],
  //           },
  //           {
  //             name: "D Handle",
  //             thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             rules: [
  //               {
  //                 type: show,
  //                 node: ["dhandle", "front_1", "front_2"],
  //               },
  //               {
  //                 type: hide,
  //                 node: ["groovehandle", "ihandle"],
  //               },
  //               {
  //                 type: hide,
  //                 condition: [
  //                   { feature: "Shutter", values: ["Without Shutter"] },
  //                 ],

  //                 node: [
  //                   "lock",
  //                   "numberlock",
  //                   "ihandle",
  //                   "dhandle",
  //                   "groovehandle",
  //                   "straighthandle",
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         name: "Metal Storages",
  //         required: false,
  //         options: [
  //           {
  //             name: "I Handle",
  //             thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             rules: [
  //               {
  //                 type: show,
  //                 node: ["ihandle", "front_1", "front_2"],
  //               },
  //               {
  //                 type: hide,
  //                 node: ["groovehandle"],
  //               },
  //               {
  //                 type: hide,
  //                 condition: [
  //                   { feature: "Shutter", values: ["Without Shutter"] },
  //                 ],

  //                 node: [
  //                   "lock",
  //                   "numberlock",
  //                   "ihandle",
  //                   "dhandle",
  //                   "groovehandle",
  //                 ],
  //               },
  //             ],
  //           },
  //           {
  //             name: "Groove Handle",
  //             thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             rules: [
  //               {
  //                 type: show,
  //                 node: ["groovehandle"],
  //               },
  //               {
  //                 type: hide,
  //                 node: ["front_1", "front_2", "ihandle"],
  //               },
  //               {
  //                 type: hide,
  //                 condition: [
  //                   { feature: "Shutter", values: ["Without Shutter"] },
  //                 ],

  //                 node: [
  //                   "lock",
  //                   "numberlock",
  //                   "ihandle",
  //                   "dhandle",
  //                   "groovehandle",
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  {
    name: "Lock",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Storages",
        required: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: hide,
                node: ["lock", "numberlock", "numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "Key Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["lock"],
              },
              {
                type: hide,
                node: ["numberlock"],
              },
              {
                type: hide,
                condition: [
                  { feature: "Shutter", values: ["Without Shutter"] },
                ],

                node: [
                  "lock",
                  "numberlock",
                  "ihandle",
                  "dhandle",
                  "groovehandle",
                  "straighthandle",
                ],
              },
            ],
          },
          // {
          //   name: "2 Individual KeyLocks",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: show,
          //       node: ["lock_1", "lock_2"],
          //     },
          //     {
          //       type: hide,
          //       node: ["numberlock_1", "numberlock_2"],
          //     },
          //   ],
          // },
          {
            name: "Numeric Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["numberlock"],
              },
              {
                type: hide,
                node: ["lock"],
              },
              {
                type: hide,
                condition: [
                  { feature: "Shutter", values: ["Without Shutter"] },
                ],

                node: [
                  "lock",
                  "numberlock",
                  "ihandle",
                  "dhandle",
                  "groovehandle",
                  "straighthandle",
                ],
              },
            ],
          },
          // {
          //   name: "2 Individual Numeric Locks",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: show,
          //       node: ["numberlock_1", "numberlock_2"],
          //     },
          //     {
          //       type: hide,
          //       node: ["lock_1", "lock_2"],
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "Metal Storages",
        required: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: hide,
                node: ["lock", "numberlock", "numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "Key Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["lock"],
              },

              {
                type: hide,
                node: ["numberlock"],
              },
              {
                type: hide,
                condition: [
                  { feature: "Shutter", values: ["Without Shutter"] },
                ],

                node: [
                  "lock",
                  "numberlock",
                  "ihandle",
                  "dhandle",
                  "groovehandle",
                ],
              },
            ],
          },
          // {
          //   name: "2 Individual KeyLocks",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: show,
          //       node: ["lock_1", "lock_2"],
          //     },
          //     {
          //       type: hide,
          //       node: ["numberlock_1", "numberlock_2"],
          //     },
          //   ],
          // },
          {
            name: "Numeric Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["numberlock"],
              },
              {
                type: hide,
                node: ["lock"],
              },
              {
                type: hide,
                condition: [
                  { feature: "Shutter", values: ["Without Shutter"] },
                ],

                node: [
                  "lock",
                  "numberlock",
                  "ihandle",
                  "dhandle",
                  "groovehandle",
                ],
              },
            ],
          },
          // {
          //   name: "2 Individual Numeric Locks",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: show,
          //       node: ["numberlock_1", "numberlock_2"],
          //     },
          //     {
          //       type: hide,
          //       node: ["lock_1", "lock_2"],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  // {
  //   name: "Cushion",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "None",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: hide,
  //               node: ["Cushion"],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Cushion",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: show,
  //               node: ["Cushion"],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Laminate Shades",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Storages",
        required: false,

        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Frosty White",
            thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/white.jpg",
              },
            ],
          },
          {
            name: "Slate Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/highland.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Light",
            thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Dark",
            thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
              },
            ],
          },
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
              },
            ],
          },
          {
            name: "Flowery Wenge",
            thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
              },
            ],
          },
          {
            name: "Persian Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/persian.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/urban.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "METAL STORAGES",
        required: false,
        options: [
          {
            name: "None",

            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coating Shades",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Metal Storages",
        required: false,

        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/black.jpeg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/white.jpeg",
              },
            ],
          },
          {
            name: "AFC Munshell Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Graphite Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
              },
            ],
          },
          {
            name: "AFC DA Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/dagrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Sky Blue",
            thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/blue.jpeg",
              },
            ],
          },
          {
            name: "AFC Sea Green",
            thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/green.jpeg",
              },
            ],
          },
          {
            name: "AFC Light Orange",
            thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/orange.jpeg",
              },
            ],
          },
          {
            name: "AFC Misty Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Nut Brown",
            thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "METAL",
                textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
              },
            ],
          },
        ],
      },
      // {
      //   name: "Prelam Storages",
      //   required: false,
      //   options: [
      //     {
      //       name: "None",

      //       required: false,
      //       options: [
      //         {
      //           name: "Black",
      //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "",
      //               textureUrl: "/assets/textures/Wood/2k/Black.jpg",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },

  //   {
  //     name: "Material",
  //     optional: false,
  //     options: [
  //       {
  //         name: "Prelam Storages",
  //         required: false,
  //         options: [
  //           {
  //             name: "PRELAM PARTICAL BOARD",
  //             rules: [
  //               {
  //                 type: "",
  //                 node: "",
  //               },
  //             ],
  //           },
  //           {
  //             name: "PRELAMINATED MDF BOARD",
  //             rules: [
  //               {
  //                 type: "",
  //                 node: "",
  //               },
  //             ],
  //           },
  //           {
  //             name: "PLY BOARD WTOP",
  //             rules: [
  //               {
  //                 type: "",
  //                 node: "",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },

  // {
  //   name: "Fabric",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "Caramel",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Clouds",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dago Green",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dago Orange",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dolphin",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Flame",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Granada",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Greysto",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Lemon",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Med Blue",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Mink",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Olive",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "P Green",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Peppermint",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Pink",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Planet",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sangria",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Stone",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Tangerine",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Waterfall",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "CUSHION_FABRIC",
  //               textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  // ],
  // },
]);

export type ConfigData = typeof ConfData3;
