import React, { useCallback, useEffect, useRef, useState } from "react";
import useUserConfiguration from "../utils/updateUserConfiguration";
import { pedestalConstants } from "../const/pedestalConst";
import logo from "../assets/logo.svg";
import { Viewer3D } from "hello-3d-sdk/packages/sdk";
import { fetchProduct } from "../utils/apiManager";
import { useAppDispatch } from "../hooks/hooks";
import "./Preview.css";
import { productsReducer } from "../store/reducers/product";

type Rule = {
  type: string;
  node: string[];
  pid: string;
};

const getReplacePart = (
  partId: string,
  nodes: string[],
  isScale: boolean,
  scale: any,
  zoom: number
) => {
  if (scale == null) scale = 0;
  if (isScale !== true) {
    return {
      parameters: {
        part: partId,
        nodes: nodes,
        position: {
          position: {
            x: 0,
            y: 0,
            z: 0,
          },
          scale: {
            x: "1", //was initially 100x for all x, y, z
            y: "1",
            z: "1",
          },
          rotation: {
            x: 0, //was -90 for featherlite
            y: 0,
            z: 0,
          },
        },
      },
      type: "ReplacePartGlb",
      id: "6cc506e6-0ce8-41dd-8990-c0430fd1f140",
    };
  } else {
    return {
      parameters: {
        part: partId,
        nodes: nodes,
        position: {
          position: {
            x: 0,
            y: 0,
            z: 0,
          },
          scale: {
            x: zoom, //was initially 100x for all x, y, z
            y: zoom,
            z: zoom,
          },
          rotation: {
            x: "0", //was -90 for featherlite
            y: scale,
            z: 0,
          },
        },
      },
      type: "ReplacePartGlb",
      id: "6cc506e6-0ce8-41dd-8990-c0430fd1f140",
    };
  }
};

const getUnbindPart = (instance: string) => {
  return {
    parameters: {
      instance: instance,
    },
    type: "Unbind",
    id: "ca7d3ec2-486f-4c51-9a9e-1b27d238343e",
  };
};

const getBindPart = (
  partId: string,
  position?: any,
  rotation?: any,
  instance?: string
) => {
  return {
    parameters: {
      part: partId,
      // nodes: nodes,
      position: {
        position: position || {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: "1",
          y: "1",
          z: "1",
        },
        rotation: rotation || {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      instance: instance || "6b49a29e-64f8-4f20-a14e-0a5b4414602c",
    },
    type: "BindPart",
    id: "e7b661df-ca20-4ff9-ab9e-4cee7a8ae88b",
  };
};

const createMaterialRuleJSON = (textureUrl: string, nodeName: string) => {
  return {
    id: "5f979442-e8a8-492b-8b02-75e74c82cbac",
    type: "Manual",
    actions: [
      {
        parameters: {
          material: {
            name: nodeName,
            texture: textureUrl,
            center: {
              x: 0.5,
              y: 0.5,
            },
          },
        },
        type: "ChangeMaterial",
      },
    ],
  };
};

export default function Preview({
  productViewerRef,
}: {
  productViewerRef: React.MutableRefObject<any>;
}) {
  const [productViewer, setProductViewer] = useState<any>();
  const [prevProductId, setPrevProductId] = useState<string>();
  const [product, setProduct] = useState<any>();
  const [isPostRenderingDone, setIsPostRenderingDone] = useState(true);
  const [is3dSceneLoading, setIs3dSceneLoading] = useState(false);
  const [sceneLoadingProgress, setSceneLoadingProgress] = useState(0);
  const [readyForNextRule, setReadyForNextRule] = useState(true);

  const dispatch = useAppDispatch();
  const prevRules = useRef<{
    [key: string]: Rule[];
  }>({});

  const { getSelectedOption, userConfiguration2, getRules } =
    useUserConfiguration();
  const option = getSelectedOption("System", userConfiguration2.type);
  console.log("agya", option);
  const productId = option[0].productId;

  const bindPart = useCallback(
    (pid: string, position?: any, rotation?: any, instance?: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getBindPart(pid, position, rotation, instance)];

        let ruleJSON = {
          id: "989f39f2-4f73-4259-a670-1f59f6a1821d",
          iconText: "BindPart",
          type: "Manual",
          actions,
        };

        if (productViewerRef) {
          productViewerRef.current.runCustomRule(
            { rule: ruleJSON, node: [] },
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewerRef]
  );

  const unbindPart = useCallback(
    (instance: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getUnbindPart(instance)];

        let ruleJSON = {
          id: "75fd16c9-70d1-469c-9be9-a94341bd7c8e",
          iconText: "UnbindPart",
          type: "Manual",
          actions,
        };

        if (productViewerRef) {
          productViewerRef.current.runCustomRule(
            { rule: ruleJSON, node: [] },
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewerRef]
  );

  const hideNode = useCallback(
    (nodeName: string, completionHandler: any) => {
      setReadyForNextRule(false);
      console.log(nodeName);
      const ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName, //add correct node here
            },
            type: "HideNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      // console.log( productViewerRef.current.getCore3D().getModel(), "MODEL")

      if (productViewerRef) {
        productViewerRef.current.runCustomRule({
          rule: ruleJSON,
          node: nodeName,
          completionHandler: () => {
            setReadyForNextRule(true);
            completionHandler();
          },
        });
      }
    },
    [productViewerRef]
  );

  const showNode = useCallback(
    (nodeName: string, completionHandler: any) => {
      setReadyForNextRule(false);
      let ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName, //add correct node here
            },
            type: "ShowNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      if (productViewerRef) {
        productViewerRef.current.runCustomRule(
          {
            rule: ruleJSON,
            node: nodeName,
            completionHandler: () => {
              setReadyForNextRule(true);
              completionHandler();
            },
          },
          () => {}
        );
      }
    },
    [productViewerRef]
  );

  const replacePart =
    // useCallback(
    async (
      nodes: string[],
      pid: string,
      completionHandlerok: any,
      scale: any,
      isScale: boolean,
      zoom: number
    ) => {
      // return new Promise((resolve, reject) => {
      const actions = [getReplacePart(pid, nodes, scale, isScale, zoom)];

      setReadyForNextRule(false);
      let ruleJSON = {
        id: "21cbc580-8270-4d23-bc61-f0d27a711af3", //4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        iconText: "init",
        type: "Manual",
        actions,
      };

      if (productViewerRef) {
        await productViewerRef.current.runCustomRule({
          rule: ruleJSON,
          node: null,
          completionHandler: () => {
            setReadyForNextRule(true);
            completionHandlerok();
          },
        });
      }
      // });
    };
  // ,
  // [productViewerRef]
  // );

  const applyMaterial = useCallback(
    (nodeName: string, textureUrl: string, completionHandler: any) => {
      setReadyForNextRule(false);
      const ruleJSON = createMaterialRuleJSON(textureUrl, nodeName);

      if (productViewerRef) {
        productViewerRef.current.runCustomRule({
          rule: ruleJSON,
          node: nodeName,
          completionHandler: () => {
            setReadyForNextRule(true);
            completionHandler();
          },
        });
      }
    },
    [productViewerRef]
  );

  // const runRules = useCallback(
  //   async (rules: any[]) => {
  //     for (let index = 0; index < rules.length; index++) {
  //       const rule = rules[index];
  //       const nodes: string[] = rule.node
  //         ? typeof rule.node === "string"
  //           ? [rule.node]
  //           : rule.node
  //         : rule.parameters.node
  //         ? typeof rule.parameters.node === "string"
  //           ? [rule.parameters.node]
  //           : rule.parameters.node
  //         : [];

  //       if (rule.type === "replacePart") {
  //         try {
  //           await replacePart(nodes, rule.pid);
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }

  //       if (rule.type === "bindPart") {
  //         const instance = "6b49a29e-64f8-4f20-a14e-0a5b4414602c";
  //         if (userConfiguration2.type === "SHARING") {
  //           const seats = userConfiguration2.noOfSeats;
  //           const frontStartingPos =
  //             pedestalConstants.sharedWorkstations.front.pos;
  //           const backStartingPos =
  //             pedestalConstants.sharedWorkstations.back.pos;
  //           let frontPositions = [frontStartingPos];
  //           let backPositions = [backStartingPos];
  //           // front pedestal positions
  //           for (let i = 0; i < seats / 2; i++) {
  //             const x =
  //               Number(frontStartingPos.x) +
  //               i * pedestalConstants.sharedWorkstations.front.increment;

  //             if (i >= 1) {
  //               frontPositions.push({
  //                 ...frontStartingPos,
  //                 x,
  //               });
  //             }
  //           }
  //           // back pedestal positions
  //           for (let i = 0; i < seats / 2; i++) {
  //             const x =
  //               Number(backStartingPos.x) +
  //               i * pedestalConstants.sharedWorkstations.back.increment;

  //             if (i >= 1) {
  //               backPositions.push({
  //                 ...backStartingPos,
  //                 x,
  //               });
  //             }
  //           }
  //           frontPositions.forEach(async (pos, index) => {
  //             const instanceId = `${instance}-front-${index}`;
  //             // console.log(instanceId, "front");
  //             try {
  //               await bindPart(
  //                 rule.pid,
  //                 pos,
  //                 pedestalConstants.sharedWorkstations.front.rotation,
  //                 instanceId
  //               );
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           });
  //           backPositions.forEach(async (pos, index) => {
  //             const instanceId = `${instance}-back-${index}`;
  //             // console.log(instanceId, "front");
  //             try {
  //               await bindPart(
  //                 rule.pid,
  //                 pos,
  //                 pedestalConstants.sharedWorkstations.back.rotation,
  //                 instanceId
  //               );
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           });
  //         } else if (userConfiguration2.type === "NON SHARING") {
  //           const seats = userConfiguration2.noOfSeats;
  //           const startingPos =
  //             pedestalConstants.nonSharedWorkstations.front.pos;
  //           let allPositions = [startingPos];
  //           // pedestal positions
  //           for (let i = 0; i < seats; i++) {
  //             const x =
  //               Number(startingPos.x) +
  //               i * pedestalConstants.nonSharedWorkstations.front.increment;
  //             if (i >= 1) {
  //               allPositions.push({
  //                 ...startingPos,
  //                 x,
  //               });
  //             }
  //           }
  //           allPositions.forEach(async (pos, index) => {
  //             const instanceId = `${instance}-front-${index}`;

  //             try {
  //               await bindPart(
  //                 rule.pid,
  //                 pos,
  //                 pedestalConstants.nonSharedWorkstations.front.rotation,
  //                 instanceId
  //               );
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           });
  //         }
  //       }

  //       if (rule.type === "unbind") {
  //         const instance = "6b49a29e-64f8-4f20-a14e-0a5b4414602c";
  //         const seats = userConfiguration2.noOfSeats;
  //         if (userConfiguration2.type === "SHARING") {
  //           for (let i = 0; i < seats / 2; i++) {
  //             const instanceId = `${instance}-front-${i}`;
  //             try {
  //               await unbindPart(instanceId);
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           }
  //           for (let i = 0; i < seats / 2; i++) {
  //             const instanceId = `${instance}-back-${i}`;
  //             try {
  //               await unbindPart(instanceId);
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           }
  //         } else if (userConfiguration2.type === "NON SHARING") {
  //           for (let i = 0; i < seats; i++) {
  //             const instanceId = `${instance}-front-${i}`;
  //             try {
  //               await unbindPart(instanceId);
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           }
  //         }
  //       }

  //       for (const node of nodes) {
  //         console.log(nodes, node, "NODES OF LEGS RULE");
  //         switch (rule.type) {
  //           case "hide":

  //             hideNode(node);
  //             break;
  //           case "show":
  //             showNode(node);
  //             break;

  //           case "applyMaterial":
  //             applyMaterial(
  //               node,
  //               rule.textureUrl
  //               // rule.metalnessUrl ?? "",
  //               // rule.normalUrl ?? "",
  //               // rule.roughnessUrl?? "",
  //               // rule.alphaUrl ?? ""
  //             );
  //             break;
  //           default:
  //             break;
  //         }
  //       }
  //     }
  //   },
  //   [
  //     applyMaterial,
  //     bindPart,
  //     hideNode,
  //     replacePart,
  //     showNode,
  //     unbindPart,
  //     userConfiguration2.noOfSeats,
  //     userConfiguration2.type,
  //   ]
  // );

  const runRules = useCallback(
    async (rules: any[]) => {
      const executeRule = async (index: number) => {
        if (index >= rules.length) {
          return; // All rules executed
        }

        const rule = rules[index];
        const nodes: string[] = rule.node
          ? typeof rule.node === "string"
            ? [rule.node]
            : rule.node
          : rule.parameters.node
          ? typeof rule.parameters.node === "string"
            ? [rule.parameters.node]
            : rule.parameters.node
          : [];

        const completionHandler = () => {
          console.log(`Rule ${index} completed`);
          setReadyForNextRule(true);
          executeRule(index + 1); // Execute the next rule
        };

        setReadyForNextRule(false);

        switch (rule.type) {
          case "replacePart":
            try {
              await replacePart(
                nodes,
                rule.pid,
                completionHandler,
                rule.isScale,
                rule.scale,
                rule.zoom
              );
            } catch (error) {
              console.log(error);
              completionHandler();
            }
            break;

          // case "bindPart":
          //   try {
          //     await handleBindPartRule(rule, nodes, completionHandler);
          //   } catch (error) {
          //     console.log(error);
          //     completionHandler();
          //   }
          //   break;

          // case "unbind":
          //   try {
          //     await handleUnbindRule(rule, nodes, completionHandler);
          //   } catch (error) {
          //     console.log(error);
          //     completionHandler();
          //   }
          //   break;

          default:
            nodes.forEach((node) => {
              switch (rule.type) {
                case "hide":
                  console.log(rule, "hello");
                  hideNode(node, completionHandler);
                  break;
                case "show":
                  showNode(node, completionHandler);
                  break;
                case "applyMaterial":
                  applyMaterial(node, rule.textureUrl, completionHandler);
                  break;
                default:
                  completionHandler();
                  break;
              }
            });
            break;
        }
      };

      // Start executing from the first rule
      await executeRule(0);
    },
    [
      applyMaterial,
      bindPart,
      hideNode,
      replacePart,
      showNode,
      unbindPart,
      userConfiguration2.noOfSeats,
      userConfiguration2.type,
    ]
  );

  // const handleBindPartRule = async (rule, nodes, completionHandler) => {
  //   const instance = "6b49a29e-64f8-4f20-a14e-0a5b4414602c";
  //   const seats = userConfiguration2.noOfSeats;

  //   if (userConfiguration2.type === "SHARING") {
  //     const positions = getSharingPositions(seats);
  //     for (const [index, pos] of positions.entries()) {
  //       const instanceId = `${instance}-pos-${index}`;
  //       try {
  //         await bindPart(rule.pid, pos, getRotation(pos), instanceId);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   } else if (userConfiguration2.type === "NON SHARING") {
  //     const positions = getNonSharingPositions(seats);
  //     for (const [index, pos] of positions.entries()) {
  //       const instanceId = `${instance}-pos-${index}`;
  //       try {
  //         await bindPart(rule.pid, pos, getRotation(pos), instanceId);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   }
  //   completionHandler();
  // };

  // const handleUnbindRule = async (rule, nodes, completionHandler) => {
  //   const instance = "6b49a29e-64f8-4f20-a14e-0a5b4414602c";
  //   const seats = userConfiguration2.noOfSeats;

  //   if (userConfiguration2.type === "SHARING") {
  //     for (let i = 0; i < seats / 2; i++) {
  //       const instanceId = `${instance}-front-${i}`;
  //       try {
  //         await unbindPart(instanceId);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //     for (let i = 0; i < seats / 2; i++) {
  //       const instanceId = `${instance}-back-${i}`;
  //       try {
  //         await unbindPart(instanceId);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   } else if (userConfiguration2.type === "NON SHARING") {
  //     for (let i = 0; i < seats; i++) {
  //       const instanceId = `${instance}-front-${i}`;
  //       try {
  //         await unbindPart(instanceId);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   }
  //   completionHandler();
  // };

  const postRenderRules = async (rules: any[]) => {
    for (const key in rules) {
      if (productViewerRef) {
        productViewerRef.current.runCustomRule(
          { rule: rules[key], node: [] },
          () => {}
        );
      }
    }
    setSceneLoadingProgress(100);
    setIsPostRenderingDone(true);
  };
  useEffect(() => {
    //initialize product viewer
    // let productViewer: any = null;
    // const dynamicImport = async () => {
    //   // console.log("444444444", "dynamic import");

    //   const viewerEle = document.getElementById("3d-viewer");
    //   if (!viewerEle) return;
    //   const { HelloViewer, HelloARManager } = await import(
    //     "helloar/build/helloar.min.js"
    //   );
    //   if (process.env.REACT_APP_HELLOAR_STAGING) {
    //     HelloARManager.getInstance().useDevServer();
    //   } else {
    //     HelloARManager.getInstance().useProdServer();
    //   }

    //   if (productViewer) {
    //     productViewer.remove();
    //   }
    //   productViewer = new HelloViewer("3d-viewer", null, false, true);
    //   setProductViewer(productViewer);
    //   productViewerRef.current = productViewer;

    //   productViewer.init(
    //     productId,
    //     () => {
    //       setPrevProductId(productId);
    //     },
    //     (error: any) => {
    //       alert("error:" + JSON.stringify(error));
    //     }
    //   );
    // };
    // if (productId) {
    //   try {
    //     dynamicImport();
    //   } catch (error) {}
    // }
    // return () => {
    //   if (productViewer) {
    //     productViewer.remove();
    //     const x = document.getElementById("3d-viewer");
    //     if (x) {
    //       x.innerHTML = "";
    //     }
    //   }
    // };
    setPrevProductId(productId);
    dispatch({
      type: "SET_PRODUCT_ID",
      payload: {
        value: productId,
      },
    });
  }, [productId, productViewerRef]);

  const hasObjectValueChanged = useCallback((value: any, prevValue: any) => {
    if (JSON.stringify(value) === JSON.stringify(prevValue)) {
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    (async () => {
      const rules = getRules();
      Object.keys(rules).forEach(async (key) => {
        if (hasObjectValueChanged(rules[key], prevRules.current[key])) {
          if (rules[key].length > 0) {
            try {
              await runRules(rules[key]);
            } catch (error) {
              console.log(error);
            }
          }
        }
      });
      prevRules.current = rules;
    })();
    //run when userConfiguration2 changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfiguration2]);

  useEffect(() => {
    (async () => {
      const rules = getRules();
      for (const key in rules) {
        if (rules[key].length > 0) {
          try {
            await runRules(rules[key]);
          } catch (error) {
            console.log(error);
          }
        }
      }

      prevRules.current = rules;
    })();
    //run when prevProductId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProductId]);

  const concatRules = async () => {
    const rules = getRules();
    for (const key in rules) {
      if (rules[key].length > 0) {
        try {
          // console.log("RUUUULES run rules", key + ":", rules[key]);
          await runRules(rules[key]);
        } catch (error) {
          console.log(error);
        }
      }
    }
    // return concatenatedRules
  };

  useEffect(() => {
    fetchProduct(productId)
      .then((res): any => {
        setProduct(res);
        console.log(res, "RES");
        dispatch({
          type: "SET_PRODUCT",
          payload: {
            value: res,
          },
        });
        if (productViewerRef?.current) {
          productViewerRef?.current?.getViewer().destroy();
          setSceneLoadingProgress(0);
          setIsPostRenderingDone(false);
          setIs3dSceneLoading(true);
          productViewerRef?.current
            ?.getViewer()
            .reinitialiseWeb3d(document.getElementById("3d-viewer"));
          productViewerRef?.current?.getViewer().init({
            modelDetails: {
              glb: res.three_dee_model.glb,
            },
            rules: [], //[...(JSON.parse(res?.rules.json).rules ?? [])],
            settings: {
              lights: res?.rendering_rules?.lightsV2 ?? [],
              envMapMeta:
                res?.rendering_rules?.envMapV2 ??
                res?.rendering_rules?.envMapMeta,
              shadow: {
                enabled: true,
                intensity: res?.rendering_rules?.shadowIntensity,
              },
            },
            disableModelEntryAnimation: false,
            onProgress: (progress: any) => {
              setSceneLoadingProgress(progress);
              // onProgress && onProgress(progress);
            },
            onModelRendered: () => {
              //  console.log("rendered", JSON.parse(res?.rules.json).rules);
              setIs3dSceneLoading(false);
              try {
                const parsedRules = JSON.parse(res?.rules.json).rules;
                const PostRenderRules = parsedRules.filter(
                  (rule: any) => rule.type === "PostRender"
                );
                postRenderRules(PostRenderRules);
              } catch (error) {}
              concatRules();
            },
          });
          // concatRules()
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [productId]);

  return (
    <>
      <div
        style={{ width: "100%", height: "100%" }}
        className="flex-grow items-center w-full p-4 mx-auto h-full relative flex align-center justify-center"
      >
        {is3dSceneLoading && !isPostRenderingDone && (
          <div className="loader-container">
            <div className="loader-containing-wrapper">
              <div className="loader-wrapper">
                <div className="loader-outline">
                  <div
                    className="loader-progress"
                    style={{ width: `${sceneLoadingProgress}%` }}
                  />
                  <span className="loader-text">{sceneLoadingProgress}%</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          id="3d-viewer"
          // ref={productViewerRef}
          className=" h-full w-full rounded text-gray-600 flex items-center justify-center"
          // key={productId} // Update key prop to force remount
        >
          {productId && (
            <Viewer3D
              data={product}
              ref={productViewerRef}
              onSceneLoaded={() => {
                console.log("loaded");
                console.log(option[0].rules);
                runRules(option[0].rules);
              }}
              server={process.env.NODE_ENV === "production" ? "PROD" : "DEV"}
              disableSpin={true}
              disableSpec={true}
            />
          )}
        </div>

        {/* <img
          src={logo}
          alt="logo"
          className="mr-autoh-auto w-40 ml-4 absolute bottom-40 right-4"
        /> */}
      </div>
    </>
  );
}
