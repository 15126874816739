// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Metal Pedestals",
        required: false,
        options: [
          {
            name: "2 EQUAL DRAWER",
            size: 1,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Charcoal",
              "Classic Planked Walnut",
              "Everest White",
              "Highland Pine",
              "Lyonus Walnut",
              "Black",
              "Urban Teak",
              "Thansau Maple",
              "Moldau Acacia",
              "Sonama Oak",
              "Gothic Grey",
              "Warm Grey",
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
            ],
            enableOptions: [
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/metpedestal/2equal.png",
            productId: "665ef27fe798119fccc21b07",
            rules: [
              {
                type: applyMaterial,
                node: "WOOD",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "carcass",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "1 DRAWER + 1 FILLING",
            size: 2,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Charcoal",
              "Classic Planked Walnut",
              "Everest White",
              "Highland Pine",
              "Lyonus Walnut",
              "Black",
              "Urban Teak",
              "Thansau Maple",
              "Moldau Acacia",
              "Sonama Oak",
              "Gothic Grey",
              "Warm Grey",
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/metpedestal/2nonequal.png",
            productId: "665ef4c10d1eb2776183ccf1",
          },
          // {
          //   name: "METAL PEDESTAL (1D+1F) DRAWER WITH CUSHION",
          //   size: 4,
          //   Length: 1800,
          //   Depth: 900,
          //   enableOptions: [
          //     "Charcoal",
          //     "Classic Planked Walnut",
          //     "Everest White",
          //     "Highland Pine",
          //     "Lyonus Walnut",
          //     "Black",
          //     "Urban Teak",
          //     "Thansau Maple",
          //     "Moldau Acacia",
          //     "Sonama Oak",
          //     "Gothic Grey",
          //     "Warm Grey",
          //     "Caramel",
          //     "Clouds",
          //     "Dago Green",
          //     "Dago Orange",
          //     "Dolphin",
          //     "Flame",
          //     "Granada",
          //     "Greysto",
          //     "Lemon",
          //     "Med Blue",
          //     "Mink",
          //     "Olive",
          //     "P Green",
          //     "Peppermint",
          //     "Pink",
          //     "Planet",
          //     "Sangria",
          //     "Stone",
          //     "Tangerine",
          //     "Waterfall",
          //     "2 Individual KeyLocks",
          //     "2 Individual Numeric Locks",
          //   ],
          //   thumbnail: "/assets/afc/thumnail/metpedestal/1d1fcushion.png",
          //   productId: "665ef609d78cb6bd5eb1eca3",
          // },
          {
            name: "2 DRAWERS + 1 FILLING",
            size: 5,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Charcoal",
              "Classic Planked Walnut",
              "Everest White",
              "Highland Pine",
              "Lyonus Walnut",
              "Black",
              "Urban Teak",
              "Thansau Maple",
              "Moldau Acacia",
              "Sonama Oak",
              "Gothic Grey",
              "Warm Grey",
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/metpedestal/2d1f.png",
            productId: "665ef774533639c5c9d73d0e",
          },
          {
            name: "4 EQUAL DRAWERS",
            size: 6,
            Length: 1800,
            Depth: 900,
            disableOptions: [
              "Charcoal",
              "Classic Planked Walnut",
              "Everest White",
              "Highland Pine",
              "Lyonus Walnut",
              "Black",
              "Urban Teak",
              "Thansau Maple",
              "Moldau Acacia",
              "Sonama Oak",
              "Gothic Grey",
              "Warm Grey",
              "Caramel",
              "Clouds",
              "Dago Green",
              "Dago Orange",
              "Dolphin",
              "Flame",
              "Granada",
              "Greysto",
              "Lemon",
              "Med Blue",
              "Mink",
              "Olive",
              "P Green",
              "Peppermint",
              "Pink",
              "Planet",
              "Sangria",
              "Stone",
              "Tangerine",
              "Waterfall",
              "2 Individual KeyLocks",
              "2 Individual Numeric Locks",
            ],
            thumbnail: "/assets/afc/thumnail/metpedestal/4Lequal.png",
            productId: "665ef8a4533639c5c9d73d49",
          },
          // {
          //   name: "4 EQUAL DRAWER WITH LEVELLER 4 LOCKS",
          //   size: 7,
          //   Length: 1800,
          //   Depth: 900,
          //   disableOptions: [
          //     "Charcoal",
          //     "Classic Planked Walnut",
          //     "Everest White",
          //     "Highland Pine",
          //     "Lyonus Walnut",
          //     "Black",
          //     "Urban Teak",
          //     "Thansau Maple",
          //     "Moldau Acacia",
          //     "Sonama Oak",
          //     "Gothic Grey",
          //     "Warm Grey",
          //     "Caramel",
          //     "Clouds",
          //     "Dago Green",
          //     "Dago Orange",
          //     "Dolphin",
          //     "Flame",
          //     "Granada",
          //     "Greysto",
          //     "Lemon",
          //     "Med Blue",
          //     "Mink",
          //     "Olive",
          //     "P Green",
          //     "Peppermint",
          //     "Pink",
          //     "Planet",
          //     "Sangria",
          //     "Stone",
          //     "Tangerine",
          //     "Waterfall",
          //     "2 Individual KeyLocks",
          //     "2 Individual Numeric Locks",
          //   ],

          //   thumbnail: "/assets/afc/thumnail/metpedestal/4Lequal4key.png",
          //   productId: "665efb25844a8e4982c0ace2",
          // },
        ],
      },
    ],
  },
  {
    name: "Leg",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Prelam Pedestals",
        required: false,
        options: [
          // {
          //   name: "None",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: hide,
          //       node: ["castor", "leveller"],
          //     },
          //   ],
          // },
          {
            name: "Castor",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["castor", "sidewood"],
              },
              {
                type: hide,
                node: ["leveller", "sidewood_1"],
              },
            ],
          },
          {
            name: "Leveller",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["leveller", "sidewood_1"],
              },
              {
                type: hide,
                node: ["castor", "sidewood"],
              },
            ],
          },
        ],
      },
      {
        name: "Metal Pedestals",
        required: false,
        options: [
          // {
          //   name: "None",
          //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
          //   rules: [
          //     {
          //       type: hide,
          //       node: ["castor", "sidemetal_1"],
          //     },
          //   ],
          // },
          {
            name: "Castor",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["castor", "sidemetal"],
              },
              {
                type: hide,
                node: ["sidemetal_1"],
              },
            ],
          },
          {
            name: "Leveller",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["sidemetal_1"],
              },
              {
                type: hide,
                node: ["castor", "sidemetal"],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Handle",
    multiSelect: false,
    optional: false,
    options: [
      // {
      //   name: "Prelam Pedestals",
      //   required: false,
      //   options: [
      //     {
      //       name: "None",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["front_1"],
      //         },
      //         {
      //           type: hide,
      //           node: ["Ihandle", "groovehandle"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "I Handle",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["Ihandle", "front_1"],
      //         },
      //         {
      //           type: hide,
      //           node: ["groovehandle", "flushhandle", "dhandle"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Groove Handle",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["groovehandle"],
      //         },
      //         {
      //           type: hide,
      //           node: ["front_1", "Ihandle", "dhandle", "flushhandle"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Flush Handle",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["flushhandle"],
      //         },
      //         {
      //           type: hide,
      //           node: ["groovehandle", "front_1", "Ihandle", "dhandle"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "D Handle",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["dhandle", "front_1"],
      //         },
      //         {
      //           type: hide,
      //           node: ["groovehandle", "Ihandle", "flushhandle"],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "Metal Pedestals",
        required: false,
        options: [
          {
            name: "Flush Handle",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["flushhandle"],
              },
              {
                type: hide,
                node: ["groovehandle", "horizontalgroove"],
              },
            ],
          },
          {
            name: "Front Finger Groove",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["groovehandle"],
              },
              {
                type: hide,
                node: ["flushhandle", "horizontalgroove"],
              },
            ],
          },
          {
            name: "Side Finger Groove",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["horizontalgroove"],
              },
              {
                type: hide,
                node: ["flushhandle", "groovehandle"],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Lock",
    multiSelect: false,
    optional: false,
    options: [
      // {
      //   name: "Prelam Pedestals",
      //   required: false,
      //   options: [
      //     {
      //       name: "None",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: hide,
      //           node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Key Lock",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["lock_1"],
      //         },
      //         {
      //           type: hide,
      //           node: ["lock_2", "numberlock_1", "numberlock_2"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "2 Individual KeyLocks",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["lock_1", "lock_2"],
      //         },
      //         {
      //           type: hide,
      //           node: ["numberlock_1", "numberlock_2"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Numeric Lock",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["numberlock_1"],
      //         },
      //         {
      //           type: hide,
      //           node: ["lock_1", "lock_2", "numberlock_2"],
      //         },
      //       ],
      //     },
      //     {
      //       name: "2 Individual Numeric Locks",
      //       thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
      //       rules: [
      //         {
      //           type: show,
      //           node: ["numberlock_1", "numberlock_2"],
      //         },
      //         {
      //           type: hide,
      //           node: ["lock_1", "lock_2"],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "Metal Pedestals",
        required: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: hide,
                node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "Key Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["lock_1"],
              },
              {
                type: hide,
                node: ["lock_2", "numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "2 Individual KeyLocks",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["lock_1", "lock_2"],
              },
              {
                type: hide,
                node: ["numberlock_1", "numberlock_2"],
              },
            ],
          },
          {
            name: "Numeric Lock",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["numberlock_1"],
              },
              {
                type: hide,
                node: ["lock_1", "lock_2", "numberlock_2"],
              },
            ],
          },
          {
            name: "2 Individual Numeric Locks",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: show,
                node: ["numberlock_1", "numberlock_2"],
              },
              {
                type: hide,
                node: ["lock_1", "lock_2"],
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   name: "Cushion",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "None",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: hide,
  //               node: ["Cushion"],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Cushion",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: show,
  //               node: ["Cushion"],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    name: "Powder Coat Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Metal Pedestals",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/black.jpeg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/white.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl:
                  "/assets/textures/Metal/white.jpeg",
              },
            ],
          },
          {
            name: "AFC Munshell Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Graphite Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
              },
            ],
          },
          {
            name: "AFC DA Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/dagrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Sky Blue",
            thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/blue.jpeg",
              },
            ],
          },
          {
            name: "AFC Sea Green",
            thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/green.jpeg",
              },
            ],
          },
          {
            name: "AFC Light Orange",
            thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/orange.jpeg",
              },
            ],
          },
          {
            name: "AFC Misty Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Nut Brown",
            thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["sidemetal" , "frontmetal"],
                textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   name: "Facia Powder Coat",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Metal Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "None",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: hide,
  //               node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Black",
  //           thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl: "/assets/textures/Metal/Black.jpg",
  //             },
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl: "/assets/textures/Metal/Black.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "White",
  //           thumbnail:
  //             "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl:
  //                 "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
  //             },
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl:
  //                 "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Ivory",
  //           thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl: "/assets/textures/Metal/Ivory.jpg",
  //             },
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl: "/assets/textures/Metal/Ivory.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Metallic Dark Grey",
  //           thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
  //             },
  //             {
  //               type: applyMaterial,
  //               node: "frontmetal",
  //               textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "Fabric",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Metal Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "Caramel",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Clouds",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dago Green",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dago Orange",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dolphin",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Flame",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Granada",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Greysto",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Lemon",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Med Blue",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Mink",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Olive",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "P Green",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Peppermint",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Pink",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Planet",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sangria",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Stone",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Tangerine",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Waterfall",
  //           thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "fabric",
  //               textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]);

export type ConfigData = typeof ConfData3;
