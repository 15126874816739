import { productConstants } from "../actions/types";

const initialState: {
  productid: undefined | string;
  product:any;
} = {
    productid: undefined,
    product :undefined
};

export const productsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case productConstants.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case productConstants.SET_PRODUCT_ID:
      return {
        ...state,
        productid: action.payload,
      };

    default:
      return state;
  }
};
