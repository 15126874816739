import { features } from "process";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";
const sideTableLegRules = [{}];
export const ConfData3 = Object.freeze([
  //basically for desking workstations

  {
    name: "System",
    multiSelect: false,

    options: [
      {
        name: "Fixed Training Table",
        required: false,

        options: [
          {
            name: "2 SEATER",
            size: 2,
            thumbnail: "/assets/afc/thumnail/fix/2seater.png",
            productId: "669a448e0365f5218aa25e66",
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
            ],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
        ],
      },
      {
        name: "Folding Training Tables",
        required: false,
        options: [
          {
            name: "1 SEATER",
            size: 1,
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
            ],
            thumbnail: "/assets/afc/thumnail/fix/fold.png",
            productId: "669a59ae9a113f9101e042ca", //612fccfb5c51e05fa805f84e
            tableTop: ["table_top_nonshared"],

            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Fixed Training Table",
        multiselect: false,
        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },

              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },

              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Folding Training Tables",
        multiselect: false,
        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },

              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    required: false,
    multiSelect: true,
    options: [
      {
        name: "Fixed Training Table",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
              {
                name: "@25 MM PLPB POST FORM BOARD WTOP",
                enableFeatures: ["PostForm Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",
                  "Chamfer Raceway Options",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "PostForm Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Folding Training Tables",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                  "Prelam Raceway Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["straight_edge"],
                  },
                  {
                    type: "hide",
                    node: ["postform_edge", "chamfer_edge"],
                  },
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["chamfer_edge"],
                  },
                  {
                    type: "hide",
                    node: ["postform_edge", "straight_edge"],
                  },
                ],
              },
              {
                name: "@25 MM PLPB POST FORM BOARD WTOP",
                enableFeatures: ["PostForm Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",
                  "Chamfer Raceway Options",

                  "Waterfall Raceway Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["postform_edge"],
                  },
                  {
                    type: "hide",
                    node: ["chamfer_edge", "straight_edge"],
                  },
                ],
                thumbnail: "/assets/afc/pf.png",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Fixed Training Table",
        required: false,
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "40*40 Loop Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",

                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Strip Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Livo Highlighter's Color"],
                enableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_strip"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_loop",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "PROOCED",
                thumbnail: "/assets/afc/proceed.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_proceed"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO (LOOP)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "LIVO",
                thumbnail: "/assets/afc/livo.png",
                enableFeatures: ["Livo Highlighter's Color"],
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_Livo"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_curvivo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "CURVIVO",
                thumbnail: "/assets/afc/curvivo.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_curvivo"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "TRIANGULAR LEG (STRAIGHT)",
                thumbnail: "/assets/afc/triangular-leg-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (SLANT)",
                thumbnail: "/assets/afc/triangular-leg-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (LOOP)",
                thumbnail: "/assets/afc/triangular-leg-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },

          ///////
          {
            name: "Strip PowderCoat Color",
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Laminate color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Fixed Training Table",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "Folding Training Tables",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Fixed Training Table",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Folding Training Tables",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Modesty Options",

    options: [
      {
        name: "Fixed Training Table",
        required: false,

        options: [
          {
            name: "MID Modesty",
            nodeName: ["metalmodesty", "woodmodesty"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "/assets/afc/wmm.png",

                rules: [
                  {
                    type: "show",
                    node: ["metalmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["woodmodesty"],
                  },
                ],
              },
              {
                name: "Mid Wood",
                thumbnail: "/assets/afc/wwm.png",

                rules: [
                  {
                    type: "show",
                    node: ["woodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodesty"],
                  },
                ],
              },
            ],
          },
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
