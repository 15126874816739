import axios from "axios";
import { getUserFromLocalStorage } from "./localStorage";

export const getUserProfileDetails = async () => {
  const { account_id, access_token } = getUserFromLocalStorage();
  const url = `${process.env.REACT_APP_HELLOAR_API}accounts/${account_id}`;
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Token ${access_token}` },
    });

    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching profile details");
  }
};

interface PatchDataType {
  first_name: string;
  last_name: string;
  custom_data: { designation: string };
}

export const patchUserProfileDetails = async (patchData: PatchDataType) => {
  const { account_id, access_token } = getUserFromLocalStorage();
  const url = `${process.env.REACT_APP_HELLOAR_API}accounts/${account_id}`;
  try {
    await axios.patch(url, patchData, {
      headers: { Authorization: `Token ${access_token}` },
    });
  } catch (error) {
    console.log(error);
    throw new Error("Failed to save profile details");
  }
};
