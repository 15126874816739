import axios from "axios";

const viewerId = process.env.REACT_APP_VIEWER_ID;
const baseUrl = process.env.REACT_APP_HELLOAR_API;
const url = `${baseUrl}viewers/${viewerId}`;

export interface PatchDataType {
  bank_details: {
    beneficiary_name: string;
    bank_name: string;
    branch: string;
    account_type: string;
    account_number: string;
    ifsc: string;
    swift_code: string;
    micr: string;
  };
  address: string;
  gst_number: string;
}

export const getDetails = async () => {
  const tags = localStorage.getItem("userTags");


  const tagsValue = tags && tags !== "undefined" ? JSON.parse(tags) : undefined;
  const requiredTag = tagsValue
    ? tagsValue.filter((tag: string) => tag.includes("group"))
    : undefined;
  const userLocationTag = requiredTag ?  `${requiredTag[0]?.replace(":", "_")}_data` : null;

  const res = await axios.get(url);
  const details = res.data.configurations.filter(
    (item: any) => item.key === userLocationTag
  )[0]?.value ?? {
    address : "", 
    bank_details:"",
    gst_number : ""
  }
  return details;
};

export const getAllBankDetails = async () => {
  const res = await axios.get(url);
  const bankDetailsArray = res.data.configurations.filter((item: any) =>
    item.key?.includes("group_")
  );
  return bankDetailsArray;
};

export const patchDetails = async (data: PatchDataType, tag?: string) => {
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : undefined;
  let changedConfigData: any;
  let unchangedConfigData: any;
  const res = await axios.get(url);
  if (!tag) {
    const tags = localStorage.getItem("userTags");
    const tagsValue = tags ? JSON.parse(tags) : undefined;
    const requiredTag = tagsValue
      ? tagsValue.filter((tag: string) => tag.includes("group"))
      : undefined;
    const userLocationTag = `${requiredTag[0].replace(":", "_")}_data`;
    unchangedConfigData = res.data.configurations.filter(
      (item: { key: any; value: any }) => item.key !== userLocationTag
    );
    changedConfigData = [{ key: userLocationTag, value: data }];
  } else {
    unchangedConfigData = res.data.configurations.filter(
      (item: { key: any; value: any }) => item.key !== tag
    );
    changedConfigData = [{ key: tag, value: data }];
  }
  const patchData = {
    configurations: [...unchangedConfigData, ...changedConfigData],
  };
  console.log(patchData);
  await axios.patch(url, patchData, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Token ${user.access_token}`,
    },
  });
};
