import { useEffect } from "react";
import { Feature, Option } from "../types/types";
import DropDown from "./DropDown";
import RenderThumbnails from "./RenderThumbnails";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import { tableInfoConstants } from "../store/actions/types";

const RenderOptions = ({
  item,
  handleOptionSelection,

  selectedValue,
  getSelectedOption,
}: {
  item: Feature;
  handleOptionSelection: (obj: any) => void;

  selectedValue: string | undefined;
  getSelectedOption: (name: string) => Option[];
}) => {
  const primaryBarSelection: any = useAppSelector(
    (state) => state.configuration.primaryBarSelection
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      primaryBarSelection &&
      primaryBarSelection.name === "System" &&
      selectedValue
    ) {
      console.log(selectedValue, "selected");
      dispatch({
        type: tableInfoConstants.SET_TABLE_INFO,
        payload: selectedValue,
      });
    }
  }, [dispatch, primaryBarSelection, selectedValue]);
  if (item.dropDown) {
    return (
      <DropDown
        value={selectedValue || ""}
        item={item}
        handleOptionSelection={handleOptionSelection}
      />
    );
  }

  return (
    <RenderThumbnails
      item={item}
      handleOptionSelection={handleOptionSelection}
      getSelectedOption={getSelectedOption}
    />
  );
};

export default RenderOptions;
