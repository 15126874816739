// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

export const ConfData3 = Object.freeze([
  //basically for desking workstations
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Meeting Table",

        required: false,
        options: [
          {
            name: "RECTANGULAR MEETING TABLE",
            size: 2,
            thumbnail: "/assets/afc/thumnail/meet/rec.png",
            productId: "669a13b86daff3c238c385f9",
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
              "Strip PowderCoat Color",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_30*60_strip",
                  "legs_xbench",
                  "legs_proceed",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_sleek",
                  "legs_triangular_slant",
                  "legs_triangular_loop",
                  "legs_triangular_straight",
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "grommet",
                  "legs_chrm",
                  "legs_POWDER_COATED",
                  "connectors",
                ],
              },
            ],
            disableOptions: ["POWDER COATED POLE BASE", "CHROME POLE BASE"],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "SQUARE MEETING TABLE",
            size: 3,
            thumbnail: "/assets/afc/thumnail/meet/sqr.png",
            productId: "669a68f995d87394fbc86eb9",
            enableOptions: ["POWDER COATED POLE BASE", "CHROME POLE BASE"],
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
              "Strip PowderCoat Color",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_30*60_strip",
                  "legs_xbench",
                  "legs_proceed",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_sleek",
                  "legs_triangular_slant",
                  "legs_triangular_loop",
                  "legs_triangular_straight",
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "grommet",
                  "legs_chrm",
                  "legs_POWDER_COATED",
                  "connectors",
                ],
              },
            ],

            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "ROUND MEETING TABLE",
            size: 4,
            thumbnail: "/assets/afc/thumnail/meet/rnd.png",
            productId: "669a72d19f68f4ad802fa679",
            enableOptions: ["POWDER COATED POLE BASE", "CHROME POLE BASE"],
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
              "Strip PowderCoat Color",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_30*60_strip",
                  "legs_xbench",
                  "legs_proceed",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_sleek",
                  "legs_triangular_slant",
                  "legs_triangular_loop",
                  "legs_triangular_straight",
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "grommet",
                  "legs_chrm",
                  "legs_POWDER_COATED",
                  "connectors",
                ],
              },
            ],

            tableTop: [],
            intermediateLegs: [],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Meeting Table",
        multiselect: false,
        options: [
          {
            name: "Width 1",
            required: false,
            dropDown: true,
            options: [
              { name: "1500 mm", value: 1500 },
              { name: "1650 mm", value: 1650 },
              { name: "1800 mm", value: 1800 },
              { name: "1950 mm", value: 1950 },
              { name: "2100 mm", value: 2100 },
              { name: "2250 mm", value: 2250 },
              { name: "2400 mm", value: 2400 },
              { name: "2550 mm", value: 2550 },
              { name: "2700 mm", value: 2700 },
              { name: "2850 mm", value: 2850 },
              { name: "3000 mm", value: 3000 },
              { name: "3150 mm", value: 3150 },
              { name: "3300 mm", value: 3300 },
              { name: "3450 mm", value: 3450 },
              { name: "3600 mm", value: 3600 },
              { name: "3750 mm", value: 3750 },
              { name: "3900 mm", value: 3900 },
              { name: "4050 mm", value: 4050 },
              { name: "4200 mm", value: 4200 },
              { name: "4350 mm", value: 4350 },
              { name: "4500 mm", value: 4500 },
              { name: "4650 mm", value: 4650 },
              { name: "4800 mm", value: 4800 },
              { name: "4950 mm", value: 4950 },
              { name: "5100 mm", value: 5100 },
              { name: "5250 mm", value: 5250 },
              { name: "5400 mm", value: 5400 },
              { name: "5550 mm", value: 5550 },
              { name: "5700 mm", value: 5700 },
              { name: "5850 mm", value: 5850 },
              { name: "6000 mm", value: 6000 },
              { name: "6150 mm", value: 6150 },
              { name: "6300 mm", value: 6300 },
              { name: "6450 mm", value: 6450 },
              { name: "6600 mm", value: 6600 },
              { name: "6750 mm", value: 6750 },
              { name: "6900 mm", value: 6900 },
              { name: "7050 mm", value: 7050 },
              { name: "7200 mm", value: 7200 },
            ],
          },
          {
            name: "Width 2",
            required: false,
            dropDown: true,
            options: [
              { name: "900 mm", value: 900 },
              { name: "1050 mm", value: 1050 },
              { name: "1200 mm", value: 1200 },
              { name: "1350 mm", value: 1350 },
              { name: "1500 mm", value: 1500 },
              { name: "1800 mm", value: 1800 },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Width 1",
            required: false,
            dropDown: true,
            options: [
              { name: "1500 mm", value: 1500 },
              { name: "1650 mm", value: 1650 },
              { name: "1800 mm", value: 1800 },
              { name: "1950 mm", value: 1950 },
              { name: "2100 mm", value: 2100 },
              { name: "2250 mm", value: 2250 },
              { name: "2400 mm", value: 2400 },
              { name: "2550 mm", value: 2550 },
              { name: "2700 mm", value: 2700 },
              { name: "2850 mm", value: 2850 },
              { name: "3000 mm", value: 3000 },
              { name: "3150 mm", value: 3150 },
              { name: "3300 mm", value: 3300 },
              { name: "3450 mm", value: 3450 },
              { name: "3600 mm", value: 3600 },
              { name: "3750 mm", value: 3750 },
              { name: "3900 mm", value: 3900 },
              { name: "4050 mm", value: 4050 },
              { name: "4200 mm", value: 4200 },
              { name: "4350 mm", value: 4350 },
              { name: "4500 mm", value: 4500 },
              { name: "4650 mm", value: 4650 },
              { name: "4800 mm", value: 4800 },
              { name: "4950 mm", value: 4950 },
              { name: "5100 mm", value: 5100 },
              { name: "5250 mm", value: 5250 },
              { name: "5400 mm", value: 5400 },
              { name: "5550 mm", value: 5550 },
              { name: "5700 mm", value: 5700 },
              { name: "5850 mm", value: 5850 },
              { name: "6000 mm", value: 6000 },
              { name: "6150 mm", value: 6150 },
              { name: "6300 mm", value: 6300 },
              { name: "6450 mm", value: 6450 },
              { name: "6600 mm", value: 6600 },
              { name: "6750 mm", value: 6750 },
              { name: "6900 mm", value: 6900 },
              { name: "7050 mm", value: 7050 },
              { name: "7200 mm", value: 7200 },
            ],
          },
          {
            name: "Width 2",
            required: false,
            dropDown: true,
            options: [
              { name: "900 mm", value: 900 },
              { name: "1050 mm", value: 1050 },
              { name: "1200 mm", value: 1200 },
              { name: "1350 mm", value: 1350 },
              { name: "1500 mm", value: 1500 },
              { name: "1800 mm", value: 1800 },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    required: false,
    multiSelect: true,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "PRELAM GABLE END",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                      "connectors",
                    ],
                  },
                  // {
                  //   type: hide,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE", "RECTANGULAR MEETING TABLE", "ROUND MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                  {
                    type: show,
                    node: ["legs_gable_end"],
                  },
                ],
              },
              {
                name: "40*40 Loop Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                      "connectors",
                      
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_40*40_loop", "connectors"],
                  },
                  {
                    // type: show,
                    // condition: [
                    //   {
                    //     feature: "Meeting Table",
                    //     values: ["SQUARE MEETING TABLE"],
                    //   },
                    // ],
                    // node: ["connectors"],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_40*40_slanted", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "30*60 Strip Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                enableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_30*60_strip", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_xbench", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "PROOCED",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_proceed", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_deskpro_straight", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_deskpro_slant", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "DESKPRO (LOOP)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_deskpro_loop", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_sleek", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "TRIANGULAR LEG (STRAIGHT)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_triangular_straight", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "TRIANGULAR LEG (SLANT)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_triangular_slant", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "TRIANGULAR LEG (LOOP)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_triangular_loop", "connectors"],
                  },
                  // {
                  //   type: show,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "POWDER COATED POLE BASE",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_chrm"],
                  },
                  // {
                  //   type: hide,
                  //   condition: [
                  //     {
                  //       feature: "Meeting Table",
                  //       values: ["SQUARE MEETING TABLE"],
                  //     },
                  //   ],
                  //   node: ["connectors"],
                  // },
                ],
              },
              {
                name: "CHROME POLE BASE",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_POWDER_COATED"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Meeting Table",
                        values: ["SQUARE MEETING TABLE"],
                      },
                    ],
                    node: ["connectors"],
                  },
                ],
              },
            ],
          },
          {
            name: "Strip PowderCoat Color",
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "PRELAM GABLE END",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_gable_end"],
                  },
                ],
              },
              {
                name: "40*40 Loop Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_40*40_loop"],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_40*40_slanted"],
                  },
                ],
              },
              {
                name: "30*60 Strip Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                enableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_30*60_strip"],
                  },
                ],
              },
              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_xbench"],
                  },
                ],
              },
              {
                name: "PROOCED",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_proceed"],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_deskpro_straight"],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_deskpro_slant"],
                  },
                ],
              },
              {
                name: "DESKPRO (LOOP)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_deskpro_loop"],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_sleek"],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (STRAIGHT)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_triangular_straight"],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (SLANT)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_triangular_slant"],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (LOOP)",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_triangular_loop"],
                  },
                ],
              },
              {
                name: "POWDER COATED POLE BASE",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_chrm"],
                  },
                ],
              },
              {
                name: "CHROME POLE BASE",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "legs_gable_end",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_30*60_strip",
                      "legs_xbench",
                      "legs_proceed",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_sleek",
                      "legs_triangular_slant",
                      "legs_triangular_loop",
                      "legs_triangular_straight",

                      "legs_chrm",
                      "legs_POWDER_COATED",
                    ],
                  },
                  {
                    type: show,
                    node: ["legs_POWDER_COATED"],
                  },
                ],
              },
            ],
          },
          {
            name: "Strip PowderCoat Color",
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: false,

        options: [
          {
            name: "Laminate",

            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "Laminate",

            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "AFC Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "AFC Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "AFC Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "AFC Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "AFC Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "AFC Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "AFC Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "AFC Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  // {
  //   name: "Modesty Options",

  //   options: [
  //     {
  //       name: "Meeting Table",
  //       required: false,

  //       options: [
  //         {
  //           ////
  //           name: "Side Modesty",
  //           nodeName: ["metalmodesty_side", "woodmodesty_side"],
  //           required: false,
  //           options: [
  //             {
  //               name: "none",
  //               thumbnail: "/assets/components/none.jpg",
  //               pid: "",
  //               rules: [
  //                 {
  //                   type: "hide",
  //                   node: [
  //                     "metalmodesty_side",
  //                     "woodmodesty_side",
  //                     "metalmodesty_sleek",
  //                     "woodmodesty_sleek",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Side Metal",
  //               thumbnail: "/assets/afc/wsm.png",

  //               rules: [
  //                 {
  //                   type: "hide",

  //                   node: [
  //                     "woodmodesty_sleek",
  //                     "metalmodesty_sleek",
  //                     "metalmodesty_side",
  //                     "woodmodesty_side",
  //                     "metalmodesty_slant",
  //                     "woodmodesty_slant",
  //                     "metalmodesty_sleek_livo",
  //                     "woodmodesty_proceed",
  //                     "woodmodesty_sleek_livo",
  //                     "metalmodesty_proceed",
  //                   ],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: [
  //                         "30*60 Loop Leg",
  //                         "30*60 Straight Leg",

  //                         "40*40 Loop Leg",
  //                         "40*40 Straight Leg",
  //                         "X-BENCH",
  //                         "DESKPRO STRAIGHT",
  //                         "DESKPRO (LOOP)",
  //                         "TRIANGULAR LEG (STRAIGHT)",
  //                         "TRIANGULAR LEG (LOOP)",
  //                       ],
  //                     },
  //                   ],
  //                   node: ["metalmodesty_side"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
  //                     },
  //                   ],
  //                   node: ["metalmodesty_sleek"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: [
  //                         "30*60 Strip Leg",
  //                         "DESKPRO SLANT",
  //                         "TRIANGULAR LEG (SLANT)",
  //                         "30*60 Slant Leg",
  //                         "40*40 Slanted Leg",
  //                       ],
  //                     },
  //                   ],
  //                   node: ["metalmodesty_slant"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: ["PROOCED"],
  //                     },
  //                   ],
  //                   node: ["metalmodesty_proceed"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: ["LIVO"],
  //                     },
  //                   ],
  //                   node: ["metalmodesty_sleek_livo"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Side Wood",
  //               thumbnail: "/assets/afc/wsw.png",

  //               rules: [
  //                 {
  //                   type: "hide",

  //                   node: [
  //                     "woodmodesty_sleek",
  //                     "metalmodesty_sleek",
  //                     "metalmodesty_side",
  //                     "woodmodesty_side",
  //                     "metalmodesty_slant",
  //                     "woodmodesty_slant",
  //                     "metalmodesty_sleek_livo",
  //                     "woodmodesty_proceed",
  //                     "woodmodesty_sleek_livo",
  //                     "metalmodesty_proceed",
  //                   ],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: [
  //                         "30*60 Loop Leg",
  //                         "30*60 Straight Leg",

  //                         "40*40 Loop Leg",
  //                         "40*40 Straight Leg",
  //                         "X-BENCH",
  //                         "DESKPRO STRAIGHT",
  //                         "DESKPRO (LOOP)",
  //                         "TRIANGULAR LEG (STRAIGHT)",
  //                         "TRIANGULAR LEG (LOOP)",
  //                       ],
  //                     },
  //                   ],
  //                   node: ["woodmodesty_side"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
  //                     },
  //                   ],
  //                   node: ["woodmodesty_sleek"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: [
  //                         "30*60 Strip Leg",
  //                         "DESKPRO SLANT",
  //                         "TRIANGULAR LEG (SLANT)",
  //                         "30*60 Slant Leg",
  //                         "40*40 Slanted Leg",
  //                       ],
  //                     },
  //                   ],
  //                   node: ["woodmodesty_slant"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: ["PROOCED"],
  //                     },
  //                   ],
  //                   node: ["woodmodesty_proceed"],
  //                 },
  //                 {
  //                   type: "show",
  //                   condition: [
  //                     {
  //                       feature: "Legs",
  //                       values: ["LIVO"],
  //                     },
  //                   ],
  //                   node: ["woodmodesty_sleek_livo"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "PowderCoat  Options for Modesty",
  //           multiSelect: false,
  //           options: [
  //             {
  //               name: "AFC Black",
  //               thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "metalmodesty",
  //                   textureUrl: "/assets/textures/Metal/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AFC White",
  //               thumbnail:
  //                 "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "metalmodesty",
  //                   textureUrl:
  //                     "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AFC Ivory",
  //               thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "metalmodesty",
  //                   textureUrl: "/assets/textures/Metal/Ivory.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AFC Metallic Dark Grey",
  //               thumbnail:
  //                 "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "metalmodesty",
  //                   textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AFC Moonlight Grey",
  //               thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "metalmodesty",
  //                   textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AFC Silver Grey",
  //               thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "metalmodesty",
  //                   textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Wood Color Options for Modesty",
  //           multiSelect: false,
  //           options: [
  //             {
  //               // name: "Options",
  //               // required: false,
  //               // options: [
  //               //   {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["woodmodesty"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "wood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //             // ],
  //             // },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]);

export type ConfigData = typeof ConfData3;