import { getUserFromLocalStorage } from "./localStorage";

export const getIsSuperAdmin = () => {
  const { role, viewer } = getUserFromLocalStorage();

  if (role === 2 && !viewer) {
    return true;
  }
  return false;
};
