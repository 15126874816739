// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "4 SEATER",
            size: 1,

            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/panel/linear/sharing/4seater.png",
            productId: "667919bacf68378a06b1de55",
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "2 SEATER",
            size: 1,

            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/panel/linear/nonsharing/2seater.png",
            productId: "66791aca20a5600af26d4369",
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "4 SEATER",
            size: 2,
            enableOptions: ["Leveller"],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/panel/l_shape/sharing/4seater.png",
            productId: "667583f6ab912673bf608ab2",
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "2 SEATER",
            size: 2,
            enableOptions: ["Leveller"],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/panel/l_shape/nonsharing/2seater.png",
            productId: "66a32278de827745ee2f975b",
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "3 SEATER",
            size: 4,
            disableOptions: ["Leveller"],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/panel/120/sharing/3seater.png",
            productId: "667935aa441b5e59aad481c9",
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "1 SEATER",
            size: 4,
            disableOptions: ["Leveller"],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/panel/120/nonsharing/1seater.png",
            productId: "6679198e15b51d167f85e502",
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Linear Workstation Sharing",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    required: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "60mmpanel",
                thumbnail: "/assets/system/60mmpanel.jpeg",
                disableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_40*40_loop",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "alumfullfabric_end",
                      "alumfullfabricwithborder__end",
                      "fullfabricrounded_end",
                      "fullpanels_end",
                      "roundedglass_end",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                    ],
                  },
                  {
                    type: "show",
                    node: ["60mmpanel"],
                  },
                ],
              },

              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Straight Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_40*40_slanted_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH Full",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT FULL",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT FULL",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "DESKPRO (LOOP FULL)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG FULL",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Loop",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_slanted_full",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "60mmpanel",
                thumbnail: "/assets/system/60mmpanel.jpeg",
                disableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_40*40_loop",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "alumfullfabric_end",
                      "alumfullfabricwithborder__end",
                      "fullfabricrounded_end",
                      "fullpanels_end",
                      "roundedglass_end",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                    ],
                  },
                  {
                    type: "show",
                    node: ["60mmpanel"],
                  },
                ],
              },

              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Straight Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_40*40_slanted_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH Full",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT FULL",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT FULL",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "DESKPRO (LOOP FULL)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG FULL",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Loop",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_slanted_full",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "60mmpanel",
                thumbnail: "/assets/system/60mmpanel.jpeg",
                disableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_40*40_loop",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "alumfullfabric_end",
                      "alumfullfabricwithborder__end",
                      "fullfabricrounded_end",
                      "fullpanels_end",
                      "roundedglass_end",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                    ],
                  },
                  {
                    type: "show",
                    node: ["60mmpanel"],
                  },
                ],
              },

              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Straight Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_40*40_slanted_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH Full",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT FULL",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT FULL",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "DESKPRO (LOOP FULL)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG FULL",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Loop",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_slanted_full",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "60mmpanel",
                thumbnail: "/assets/system/60mmpanel.jpeg",
                disableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_40*40_loop",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "alumfullfabric_end",
                      "alumfullfabricwithborder__end",
                      "fullfabricrounded_end",
                      "fullpanels_end",
                      "roundedglass_end",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                    ],
                  },
                  {
                    type: "show",
                    node: ["60mmpanel"],
                  },
                ],
              },

              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Straight Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_40*40_slanted_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH Full",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT FULL",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT FULL",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "DESKPRO (LOOP FULL)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG FULL",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Loop",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_slanted_full",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "60mmpanel",
                thumbnail: "/assets/system/60mmpanel.jpeg",
                disableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_40*40_loop",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "alumfullfabric_end",
                      "alumfullfabricwithborder__end",
                      "fullfabricrounded_end",
                      "fullpanels_end",
                      "roundedglass_end",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                    ],
                  },
                  {
                    type: "show",
                    node: ["60mmpanel"],
                  },
                ],
              },

              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Straight Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_40*40_slanted_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH Full",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT FULL",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT FULL",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "DESKPRO (LOOP FULL)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG FULL",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Loop",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_slanted_full",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        thumbnail: "/assets/system/60mmpanel.jpeg",
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "60mmpanel",
                disableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_sleek",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_triangular_straight",
                      "legs_xbench",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_40*40_loop",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "alumfullfabric_end",
                      "alumfullfabricwithborder__end",
                      "fullfabricrounded_end",
                      "fullpanels_end",
                      "roundedglass_end",
                      "metalmodesty_side",
                      "metalmodesty_slant",
                      "metalmodesty_sleek",
                      "woodmodesty_side",
                      "woodmodesty_slant",
                      "woodmodesty_sleek",
                    ],
                  },
                  {
                    type: "show",
                    node: ["60mmpanel"],
                  },
                ],
              },

              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Straight Leg Full",
                thumbnail: "/assets/afc/3slantleg.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_straight",
                      "legs_curvivo",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_slanted",
                      "legs_40*40_loop_full",
                      "legs_40*40_slanted_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH Full",
                thumbnail: "/assets/afc/x-bench.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT FULL",
                thumbnail: "/assets/afc/deskpro-straight.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT FULL",
                thumbnail: "/assets/afc/deskpro-slant.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },

              {
                name: "DESKPRO (LOOP FULL)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek_full",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "SLEEK LEG FULL",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Straight Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_straight_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted_full",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Slant Full",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_slanted_full"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Loop",
                thumbnail: "/assets/afc/sleek-legs.png",
                enableFeatures: [
                  "Glass End",
                  "Sleek Screen Nylon Beading End",
                  "Sleek Screen Aluminum Beading End",
                  "Frame Screen End",
                  "Side Modesty",
                  "PowderCoat  Options for Modesty",
                  "Wood Color Options for Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_slanted_full",
                      "legs_sleek_full",
                      "legs_30*60_straight_full",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_gable_end",
                      "legs_xbench_full",
                      "legs_triangular_straight_full",
                      "legs_triangular_slant_full",
                      "legs_sleek",
                      "legs_deskpro_straight_full",
                      "legs_deskpro_slant_full",
                      "legs_deskpro_loop_full",
                      "legs_40*40_straight_full",
                      "legs_40*40_slanted_full",
                      "legs_40*40_loop_full",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "60mmpanel",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   name: "Pedestal",
  //   rules: [
  //     {
  //       type: hide,
  //       node: ["LOCK", "NUMERIC_LOCK", "NUMERIC_LOCK2", "LOCK2"],
  //     },
  //   ],
  //   options: [
  //     {
  //       name: "Linear Workstation Sharing",
  //       required: false,
  //       multiSelect: false,
  //       rules: [],
  //       options: [
  //         {
  //           name: "Pedestal",
  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "assets/system/placeholder.svg",
  //               pid: "",
  //               disableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped1"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/36b86cee-2d9c-4d6e-a5ab-747890c5d2c9/18_5_2024/1718718919702/prelam-1.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Non Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/fa85cd5c-f9eb-4138-a8cf-2f88ae04258e/18_5_2024/1718719214237/prelam-2.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/d7252c80-0d50-4070-bfe2-920009018467/18_5_2024/1718719456705/prelam-3.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/355f33ac-d591-43b7-ade4-2948e2f9d61e/18_5_2024/1718719595747/prelam-4.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "4L EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/da99344a-6602-409a-9156-55d8c34f9cab/18_5_2024/1718719806434/prelam-5.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/ab94aa05-58db-4531-a17e-baf749bffab3/18_5_2024/1718720035058/prelam-6.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/6742516d-9fa4-445d-9066-7ce1bcea45cc/19_5_2024/1718769969876/Prelam-1.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 NON-EQUAL DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/c95971fd-c612-4239-aa22-0b81ba69144a/19_5_2024/1718770077386/Prelam-2.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/68605489-47eb-433f-8657-6eb2b3d3ec04/19_5_2024/1718770126399/Prelam-3.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (2D+1F) DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/938fabd1-f05a-4913-805a-ec4d958de834/19_5_2024/1718770172204/Prelam-4.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/82b3c53e-8ab3-45a5-935b-23c25832e988/19_5_2024/1718770218230/Prelam-5.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/665eda56-ec68-414b-8e65-0ef1d9c59ef7/19_5_2024/1718770250301/Prelam-6.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Linear Workstation Non Sharing",
  //       required: false,
  //       multiSelect: false,
  //       rules: [],
  //       options: [
  //         {
  //           name: "Pedestal",
  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "assets/system/placeholder.svg",
  //               pid: "",
  //               disableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped1"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/36b86cee-2d9c-4d6e-a5ab-747890c5d2c9/18_5_2024/1718718919702/prelam-1.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Non Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/fa85cd5c-f9eb-4138-a8cf-2f88ae04258e/18_5_2024/1718719214237/prelam-2.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/d7252c80-0d50-4070-bfe2-920009018467/18_5_2024/1718719456705/prelam-3.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/355f33ac-d591-43b7-ade4-2948e2f9d61e/18_5_2024/1718719595747/prelam-4.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "4L EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/da99344a-6602-409a-9156-55d8c34f9cab/18_5_2024/1718719806434/prelam-5.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/ab94aa05-58db-4531-a17e-baf749bffab3/18_5_2024/1718720035058/prelam-6.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/6742516d-9fa4-445d-9066-7ce1bcea45cc/19_5_2024/1718769969876/Prelam-1.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 NON-EQUAL DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/c95971fd-c612-4239-aa22-0b81ba69144a/19_5_2024/1718770077386/Prelam-2.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/68605489-47eb-433f-8657-6eb2b3d3ec04/19_5_2024/1718770126399/Prelam-3.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (2D+1F) DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/938fabd1-f05a-4913-805a-ec4d958de834/19_5_2024/1718770172204/Prelam-4.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/82b3c53e-8ab3-45a5-935b-23c25832e988/19_5_2024/1718770218230/Prelam-5.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: [
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                   pid: "https://content.helloviewer.io/uploads/665eda56-ec68-414b-8e65-0ef1d9c59ef7/19_5_2024/1718770250301/Prelam-6.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Curvlinear / L-shape Workstation Sharing",
  //       options: [
  //         {
  //           name: "Pedestal",
  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "assets/system/placeholder.svg",
  //               pid: "",
  //               disableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,

  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,

  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,

  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Non Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "4L EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 NON-EQUAL DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (2D+1F) DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Curvlinear / L-shape Workstation Non Sharing",
  //       options: [
  //         {
  //           name: "Pedestal",
  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "assets/system/placeholder.svg",
  //               pid: "",
  //               disableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,

  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,

  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,

  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Non Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "4L EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 NON-EQUAL DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (2D+1F) DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ped"],
  //                 },

  //                 {
  //                   type: replacePart,
  //                   node: ["ped1", "ped5", "ped9", "ped13"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2", "ped6", "ped10", "ped14"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3", "ped7", "ped11", "ped15"],
  //                   isScale: true,
  //                   scale: 90,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped4", "ped8", "ped12", "ped16"],
  //                   isScale: true,
  //                   scale: 270,
  //                   zoom: 0.45,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidemetal_1",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "120 Deg Workstation Sharing",
  //       required: false,
  //       multiSelect: false,
  //       rules: [],
  //       options: [
  //         {
  //           name: "Pedestal",
  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "assets/system/placeholder.svg",
  //               pid: "",
  //               disableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Non Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "castor",
  //                     "sidewood",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "4L EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 NON-EQUAL DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (2D+1F) DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 150,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped2"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },
  //                 {
  //                   type: replacePart,
  //                   node: ["ped3"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 270,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "120 Deg Workstation Non Sharing",
  //       required: false,
  //       multiSelect: false,
  //       rules: [],
  //       options: [
  //         {
  //           name: "Pedestal",
  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "assets/system/placeholder.svg",
  //               pid: "",
  //               disableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                     "ped18",
  //                     "ped20",
  //                     "ped22",
  //                     "ped24",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Non Equal drawer pedestal",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "4L EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: ["I Handle", "D Handle"],
  //               disableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
  //               thumbnail: "assets/system/placeholder.svg",
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 2 NON-EQUAL DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: ["I Handle", "D Handle"],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "Fabrics",
  //                 "Carcass Woods",
  //               ],
  //               enableOptions: [
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "METAL PEDESTAL (2D+1F) DRAWER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
  //               thumbnail: "assets/system/placeholder.svg",
  //               disableOptions: [
  //                 "I Handle",
  //                 "D Handle",
  //                 "2 Individual KeyLocks",
  //                 "2 Individual Numeric Locks",
  //               ],
  //               enableFeatures: [
  //                 "Handles",
  //                 "Locks",
  //                 "Facia Woods",
  //                 "LeGs",
  //                 "Carcass Woods",
  //               ],
  //               rules: [
  //                 {
  //                   type: replacePart,
  //                   node: ["ped1"],
  //                   isScale: true,
  //                   zoom: 0.45,
  //                   scale: 30,
  //                   pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
  //                 },

  //                 {
  //                   type: hide,
  //                   node: [
  //                     "ihandle",
  //                     "front_1",
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "lock_1",
  //                     "lock_2",
  //                     "numberlock_1",
  //                     "numberlock_2",
  //                     "sidewood_1",
  //                     "sidemetal_1",
  //                   ],
  //                 },
  //                 {
  //                   type: show,
  //                   node: [
  //                     "ped1",
  //                     "ped3",
  //                     "ped5",
  //                     "castor",
  //                     "sidewood",
  //                     "sidemetal",
  //                     "ped7",
  //                     "ped9",
  //                     "ped11",
  //                     "ped13",
  //                     "ped15",
  //                     "ped17",
  //                     "ped19",
  //                     "ped21",
  //                     "ped23",
  //                     "ped2",
  //                     "ped4",
  //                     "ped6",
  //                     "ped8",
  //                     "ped10",
  //                     "ped12",
  //                     "ped14",
  //                     "ped16",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "Pedestal Features",
  //   optional: false,
  //   options: [
  //     {
  //       name: "Linear Workstation Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Handles",

  //           options: [
  //             {
  //               name: "I Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ihandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Groove Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["groovehandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Horizontal Groove",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["horizontalgroove"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "groovehandle",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flush Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["flushhandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "D Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["dhandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "ihandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Locks",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Key Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual KeyLocks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Numeric Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual Numeric Locks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "LeGs",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             // {
  //             //   name: "None",
  //             //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             //   rules: [
  //             //     {
  //             //       type: hide,
  //             //       node: ["castor", "leveller"],
  //             //     },
  //             //   ],
  //             // },
  //             {
  //               name: "Castor",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Leveller",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: show,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Linear Workstation Non Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Handles",

  //           options: [
  //             {
  //               name: "I Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ihandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Groove Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["groovehandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Horizontal Groove",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["horizontalgroove"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "groovehandle",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flush Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["flushhandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "D Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["dhandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "ihandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Locks",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Key Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual KeyLocks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Numeric Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual Numeric Locks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "LeGs",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             // {
  //             //   name: "None",
  //             //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             //   rules: [
  //             //     {
  //             //       type: hide,
  //             //       node: ["castor", "leveller"],
  //             //     },
  //             //   ],
  //             // },
  //             {
  //               name: "Castor",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Leveller",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: show,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Curvlinear / L-shape Workstation Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Handles",

  //           options: [
  //             {
  //               name: "I Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ihandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Groove Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["groovehandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Horizontal Groove",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["horizontalgroove"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "groovehandle",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flush Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["flushhandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "D Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["dhandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "ihandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Locks",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Key Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual KeyLocks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Numeric Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual Numeric Locks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "LeGs",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             // {
  //             //   name: "None",
  //             //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             //   rules: [
  //             //     {
  //             //       type: hide,
  //             //       node: ["castor", "leveller"],
  //             //     },
  //             //   ],
  //             // },
  //             {
  //               name: "Castor",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Leveller",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: show,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Curvlinear / L-shape Workstation Non Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Handles",

  //           options: [
  //             {
  //               name: "I Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ihandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Groove Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["groovehandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Horizontal Groove",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["horizontalgroove"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "groovehandle",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flush Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["flushhandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "D Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["dhandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "ihandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Locks",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Key Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual KeyLocks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Numeric Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual Numeric Locks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "LeGs",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             // {
  //             //   name: "None",
  //             //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             //   rules: [
  //             //     {
  //             //       type: hide,
  //             //       node: ["castor", "leveller"],
  //             //     },
  //             //   ],
  //             // },
  //             {
  //               name: "Castor",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Leveller",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: show,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "120 Deg Workstation Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Handles",

  //           options: [
  //             {
  //               name: "I Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ihandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Groove Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["groovehandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Horizontal Groove",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["horizontalgroove"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "groovehandle",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flush Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["flushhandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "D Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["dhandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "ihandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Locks",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Key Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual KeyLocks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Numeric Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual Numeric Locks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "LeGs",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             // {
  //             //   name: "None",
  //             //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             //   rules: [
  //             //     {
  //             //       type: hide,
  //             //       node: ["castor", "leveller"],
  //             //     },
  //             //   ],
  //             // },
  //             {
  //               name: "Castor",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Leveller",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: show,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "120 Deg Workstation Non Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Handles",

  //           options: [
  //             {
  //               name: "I Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["ihandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "flushhandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Groove Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["groovehandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Horizontal Groove",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["horizontalgroove"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "flushhandle",
  //                     "groovehandle",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flush Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["flushhandle"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "front_1",
  //                     "ihandle",
  //                     "dhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "D Handle",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["dhandle", "front_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: [
  //                     "groovehandle",
  //                     "ihandle",
  //                     "flushhandle",
  //                     "horizontalgroove",
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Locks",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             {
  //               name: "None",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Key Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_2", "numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual KeyLocks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Numeric Lock",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2", "numberlock_2"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "2 Individual Numeric Locks",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["numberlock_1", "numberlock_2"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["lock_1", "lock_2"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "LeGs",
  //           multiSelect: false,
  //           optional: false,

  //           options: [
  //             // {
  //             //   name: "None",
  //             //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //             //   rules: [
  //             //     {
  //             //       type: hide,
  //             //       node: ["castor", "leveller"],
  //             //     },
  //             //   ],
  //             // },
  //             {
  //               name: "Castor",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: show,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Leveller",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: ["castor", "sidewood"],
  //                 },
  //                 {
  //                   type: show,
  //                   node: ["sidewood_1"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "Prelam Pedestal Color Options",
  //   options: [
  //     {
  //       name: "Linear Workstation Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Facia Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Carcass Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Linear Workstation Non Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Facia Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Carcass Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Curvlinear / L-shape Workstation Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Facia Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Carcass Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Curvlinear / L-shape Workstation Non Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Facia Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Carcass Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "120 Deg Workstation Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Facia Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Carcass Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "120 Deg Workstation Non Sharing",
  //       required: false,
  //       options: [
  //         {
  //           name: "Facia Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "frontwood",
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Carcass Woods",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Black",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Black.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Charcoal",
  //               thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Classic Planked Walnut",
  //               thumbnail:
  //                 "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl:
  //                     "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Everest White",
  //               thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Highland Pine",
  //               thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lyonus Walnut",
  //               thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Moldau Acacia",
  //               thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sonama Oak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Gothic Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Warm Grey",
  //               thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple",
  //               thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: ["sidewood", "topwood"],
  //                   textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fabrics",
  //           multiSelect: false,
  //           optional: false,
  //           options: [
  //             {
  //               name: "Caramel",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Caramel.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Clouds",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Clouds.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dago Orange",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Flame",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Flame.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Granada",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Granada.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Greysto",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Greysto.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Lemon",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Lemon.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Mink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Mink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Olive",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Olive.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "P Green",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/P_Green.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Peppermint",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Pink",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Pink.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Planet",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Planet.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Sangria.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Stone",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Stone.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Tangerine",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Waterfall",
  //               thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
  //               rules: [
  //                 {
  //                   type: applyMaterial,
  //                   node: "fabric.002",
  //                   textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Table Top",
    required: false,
    multiSelect: true,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
              {
                name: "@25 MM PLPB POST FORM BOARD WTOP",
                enableFeatures: ["PostForm Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",
                  "Chamfer Raceway Options",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "PostForm Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      ,
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",

                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
              {
                name: "@25 MM PLPB POST FORM BOARD WTOP",
                enableFeatures: ["PostForm Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",
                  "Chamfer Raceway Options",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "PostForm Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      ,
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",

                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PRELAM PARTICAL BOARD WTOP",
                enableFeatures: ["Prelam Raceway Options"],
                disableFeatures: [
                  "Chamfer Raceway Options",
                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Chamfer Raceway Options"],
                disableFeatures: [
                  "Prelam Raceway Options",

                  "PostForm Raceway Options",
                  "Waterfall Raceway Options",
                ],
              },
            ],
          },
          {
            name: "Prelam Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletry_flipup_RHS",
                      "cabletry_flipup_LHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Chamfer Raceway Options",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Mid With CableTray",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Left With CableTray",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",

                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup_LHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup_RHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Flip Up - Right With CableTray",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup_RHS"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "cabletray_flipup_LHS",
                      "cabletray_flipup",
                      "cabletray_flipup_RHS",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "RACEWAY",

    options: [
      {
        name: "Linear Workstation Sharing",
        options: [
          {
            name: "SKIRTING + BELOW  TOP",
            thumbnail: "/assets/system/belowtop.jpeg",
            rules: [
              {
                type: hide,
                node: ["aboveraceway"],
              },
              {
                type: show,
                node: ["belowraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + ABOVE  TOP",
            thumbnail: "/assets/system/abovetop.jpeg",
            rules: [
              {
                type: hide,
                node: ["belowraceway"],
              },
              {
                type: show,
                node: ["aboveraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + BELOW  TOP + ABOVE  TOP",
            thumbnail: "/assets/system/belowtop+abovetop.jpeg",
            rules: [
              {
                type: show,
                node: ["aboveraceway", "belowraceway"],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        options: [
          {
            name: "SKIRTING + BELOW  TOP",
            thumbnail: "/assets/system/belowtop.jpeg",
            rules: [
              {
                type: hide,
                node: ["aboveraceway"],
              },
              {
                type: show,
                node: ["belowraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + ABOVE  TOP",
            thumbnail: "/assets/system/abovetop.jpeg",
            rules: [
              {
                type: hide,
                node: ["belowraceway"],
              },
              {
                type: show,
                node: ["aboveraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + BELOW  TOP + ABOVE  TOP",
            thumbnail: "/assets/system/belowtop+abovetop.jpeg",
            rules: [
              {
                type: show,
                node: ["aboveraceway", "belowraceway"],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        options: [
          {
            name: "SKIRTING + BELOW  TOP",
            thumbnail: "/assets/system/belowtop.jpeg",
            rules: [
              {
                type: hide,
                node: ["aboveraceway"],
              },
              {
                type: show,
                node: ["belowraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + ABOVE  TOP",
            thumbnail: "/assets/system/abovetop.jpeg",
            rules: [
              {
                type: hide,
                node: ["belowraceway"],
              },
              {
                type: show,
                node: ["aboveraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + BELOW  TOP + ABOVE  TOP",
            thumbnail: "/assets/system/belowtop+abovetop.jpeg",
            rules: [
              {
                type: show,
                node: ["aboveraceway", "belowraceway"],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        options: [
          {
            name: "SKIRTING + BELOW  TOP",
            thumbnail: "/assets/system/belowtop.jpeg",
            rules: [
              {
                type: hide,
                node: ["aboveraceway"],
              },
              {
                type: show,
                node: ["belowraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + ABOVE  TOP",
            thumbnail: "/assets/system/abovetop.jpeg",
            rules: [
              {
                type: hide,
                node: ["belowraceway"],
              },
              {
                type: show,
                node: ["aboveraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + BELOW  TOP + ABOVE  TOP",
            thumbnail: "/assets/system/belowtop+abovetop.jpeg",
            rules: [
              {
                type: show,
                node: ["aboveraceway", "belowraceway"],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        options: [
          {
            name: "SKIRTING + BELOW  TOP",
            thumbnail: "/assets/system/belowtop.jpeg",
            rules: [
              {
                type: hide,
                node: ["aboveraceway"],
              },
              {
                type: show,
                node: ["belowraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + ABOVE  TOP",
            thumbnail: "/assets/system/abovetop.jpeg",
            rules: [
              {
                type: hide,
                node: ["belowraceway"],
              },
              {
                type: show,
                node: ["aboveraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + BELOW  TOP + ABOVE  TOP",
            thumbnail: "/assets/system/belowtop+abovetop.jpeg",
            rules: [
              {
                type: show,
                node: ["aboveraceway", "belowraceway"],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        options: [
          {
            name: "SKIRTING + BELOW  TOP",
            thumbnail: "/assets/system/belowtop.jpeg",
            rules: [
              {
                type: hide,
                node: ["aboveraceway"],
              },
              {
                type: show,
                node: ["belowraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + ABOVE  TOP",
            thumbnail: "/assets/system/abovetop.jpeg",
            rules: [
              {
                type: hide,
                node: ["belowraceway"],
              },
              {
                type: show,
                node: ["aboveraceway"],
              },
            ],
          },
          {
            name: "SKIRTING + BELOW  TOP + ABOVE  TOP",
            thumbnail: "/assets/system/belowtop+abovetop.jpeg",
            rules: [
              {
                type: show,
                node: ["aboveraceway", "belowraceway"],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Tile Color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Laminate",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Fabric's Color",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Powder Coating",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Laminate",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Fabric's Color",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Powder Coating",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Laminate",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Fabric's Color",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Powder Coating",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Laminate",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Fabric's Color",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Powder Coating",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Laminate",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Fabric's Color",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Powder Coating",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Laminate",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Fabric's Color",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "tile",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Powder Coating",
            required: false,
            options: [
              {
                name: "Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                  {
                    type: applyMaterial,
                    node: "tile",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Main Privacy Screen",
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Main screen",
            options: [
              {
                name: "Half fabric and half laminate",
                thumbnail: "/assets/system/halfandhalf.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["rectangularfabric", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["halffabricandhalflaminate"],
                  },
                ],
              },
              {
                name: "Full Fabirc",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["rectangularfabric"],
                  },
                ],
              },
              {
                name: "Full Laminate",
                thumbnail: "/assets/system/laminate.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "rectangularfabric"],
                  },
                  {
                    type: show,
                    node: ["fulllaminate"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Main screen",
            options: [
              {
                name: "Half fabric and half laminate",
                thumbnail: "/assets/system/halfandhalf.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["rectangularfabric", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["halffabricandhalflaminate"],
                  },
                ],
              },
              {
                name: "Full Fabirc",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["rectangularfabric"],
                  },
                ],
              },
              {
                name: "Full Laminate",
                thumbnail: "/assets/system/laminate.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "rectangularfabric"],
                  },
                  {
                    type: show,
                    node: ["fulllaminate"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Main screen",
            options: [
              {
                name: "Half fabric and half laminate",
                thumbnail: "/assets/system/halfandhalf.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["rectangularfabric", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["halffabricandhalflaminate"],
                  },
                ],
              },
              {
                name: "Full Fabirc",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["rectangularfabric"],
                  },
                ],
              },
              {
                name: "Full Laminate",
                thumbnail: "/assets/system/laminate.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "rectangularfabric"],
                  },
                  {
                    type: show,
                    node: ["fulllaminate"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Main screen",
            options: [
              {
                name: "Half fabric and half laminate",
                thumbnail: "/assets/system/halfandhalf.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["rectangularfabric", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["halffabricandhalflaminate"],
                  },
                ],
              },
              {
                name: "Full Fabirc",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["rectangularfabric"],
                  },
                ],
              },
              {
                name: "Full Laminate",
                thumbnail: "/assets/system/laminate.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "rectangularfabric"],
                  },
                  {
                    type: show,
                    node: ["fulllaminate"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Main screen",
            options: [
              {
                name: "Half fabric and half laminate",
                thumbnail: "/assets/system/halfandhalf.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["rectangularfabric", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["halffabricandhalflaminate"],
                  },
                ],
              },
              {
                name: "Full Fabirc",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["rectangularfabric"],
                  },
                ],
              },
              {
                name: "Full Laminate",
                thumbnail: "/assets/system/laminate.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "rectangularfabric"],
                  },
                  {
                    type: show,
                    node: ["fulllaminate"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Main screen",
            options: [
              {
                name: "Half fabric and half laminate",
                thumbnail: "/assets/system/halfandhalf.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["rectangularfabric", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["halffabricandhalflaminate"],
                  },
                ],
              },
              {
                name: "Full Fabirc",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "fulllaminate"],
                  },
                  {
                    type: show,
                    node: ["rectangularfabric"],
                  },
                ],
              },
              {
                name: "Full Laminate",
                thumbnail: "/assets/system/laminate.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: ["halffabricandhalflaminate", "rectangularfabric"],
                  },
                  {
                    type: show,
                    node: ["fulllaminate"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Return Privacy Screen",
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Return screen",
            options: [
              {
                name: "None",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Fullside Panel",
                thumbnail: "/assets/system/fullsidepanel.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullpanels_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Framed Screen",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Screen with Aluminium Beading",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Screen with Nylon Beading",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Glass Screen",
                thumbnail: "/assets/system/glass screen_return.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["roundedglass_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Return screen",
            options: [
              {
                name: "None",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Fullside Panel",
                thumbnail: "/assets/system/fullsidepanel.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullpanels_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Framed Screen",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Screen with Aluminium Beading",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Screen with Nylon Beading",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Glass Screen",
                thumbnail: "/assets/system/glass screen_return.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["roundedglass_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Return screen",
            options: [
              {
                name: "None",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Fullside Panel",
                thumbnail: "/assets/system/fullsidepanel.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullpanels_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Framed Screen",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Screen with Aluminium Beading",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Screen with Nylon Beading",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Glass Screen",
                thumbnail: "/assets/system/glass screen_return.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["roundedglass_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Return screen",
            options: [
              {
                name: "None",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Fullside Panel",
                thumbnail: "/assets/system/fullsidepanel.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullpanels_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Framed Screen",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Screen with Aluminium Beading",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Screen with Nylon Beading",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Glass Screen",
                thumbnail: "/assets/system/glass screen_return.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["roundedglass_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Return screen",
            options: [
              {
                name: "None",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Fullside Panel",
                thumbnail: "/assets/system/fullsidepanel.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullpanels_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Framed Screen",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Screen with Aluminium Beading",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Screen with Nylon Beading",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Glass Screen",
                thumbnail: "/assets/system/glass screen_return.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["roundedglass_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Return screen",
            options: [
              {
                name: "None",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Fullside Panel",
                thumbnail: "/assets/system/fullsidepanel.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullpanels_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Framed Screen",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Sleek Screen with Aluminium Beading",
                thumbnail: "/assets/system/screenwithalumbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Screen with Nylon Beading",
                thumbnail: "/assets/system/screenwithnylonbeading.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Glass Screen",
                thumbnail: "/assets/system/glass screen_return.jpeg",
                disableFeatures: ["Livo Highlighter's Color"],
                rules: [
                  {
                    type: show,
                    node: ["roundedglass_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "fullpanels_1",
                      "alumfullfabric_1",
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                      "livoscreen_1",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "End Privacy Screen",
    options: [
      {
        name: "Linear Workstation Sharing",
        options: [
          {
            name: "Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich magnetic glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        options: [
          {
            name: "Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich magnetic glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        options: [
          {
            name: "Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich magnetic glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        options: [
          {
            name: "Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich magnetic glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        options: [
          {
            name: "Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich magnetic glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        options: [
          {
            name: "Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
              {
                name: "Magnetic Fabric",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich magnetic glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy Main Color Option",
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Fabric's COlor",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Fabric's COlor",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Fabric's COlor",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Fabric's COlor",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Fabric's COlor",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Fabric's COlor",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy Return Color Option",
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            name: "Fabrics",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Fabrics",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            name: "Fabrics",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Fabrics",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            name: "Fabrics",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            name: "Fabrics",
            options: [
              {
                name: "Tangerine",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
                  },
                ],
              },
              {
                name: "Acid",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
                  },
                ],
              },
              {
                name: "Acorn",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
                  },
                ],
              },
              {
                name: "Amethyst",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
                  },
                ],
              },
              {
                name: "Apollo",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
                  },
                ],
              },
              {
                name: "Azure",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
                  },
                ],
              },

              {
                name: "Blush",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
                  },
                ],
              },
              {
                name: "Brown",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
                  },
                ],
              },
              {
                name: "Burnt Orange",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
                  },
                ],
              },
              {
                name: "Corn Flower",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
                  },
                ],
              },
              {
                name: "Magenta",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
                  },
                ],
              },

              {
                name: "Pink",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
                  },
                ],
              },
              {
                name: "Purple",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
                  },
                ],
              },
              {
                name: "Raspberry",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
                  },
                ],
              },
              {
                name: "Ruby",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
                  },
                ],
              },
              {
                name: "Salmon",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
                  },
                ],
              },
              {
                name: "Shimmer",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
                  },
                ],
              },

              {
                name: "Electric",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
                  },
                ],
              },
              {
                name: "Frengia",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
                  },
                ],
              },
              {
                name: "Baby Blue",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "fabric_side",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
                  },
                ],
              },
            ],
          },
          {
            name: "Glass's Color",
            options: [
              {
                name: "SKY BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
                  },
                ],
              },
              {
                name: "SOLAR YELLOW",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
                  },
                ],
              },
              {
                name: "OCEAN GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
                  },
                ],
              },
              {
                name: "ORANGE MIST",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
                  },
                ],
              },
              {
                name: "FLAME RED",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
                  },
                ],
              },
              {
                name: "CASIS PURPLE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
                  },
                ],
              },
              {
                name: "AZURE BLUE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
                  },
                ],
              },
              {
                name: "APPLE GREEN",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
                  },
                ],
              },
              {
                name: "SUNNY ORANGE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
                  },
                ],
              },
              {
                name: "SUPER WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
                  },
                ],
              },
              {
                name: "GREEN LIME",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                rules: [
                  {
                    type: "applyMaterial",
                    node: ["glass_side"],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Modesty Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Linear Workstation Sharing",
        required: false,
        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        required: false,
        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        required: false,
        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        required: false,
        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        required: false,
        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        required: false,
        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT FULL)",
                          "DESKPRO STRAIGHT FULL",
                          "40*40 Straight Leg Full",
                          "X-BENCH Full",
                          "40*40 Loop Leg Full",
                          "DESKPRO (LOOP FULL)",
                          "30*60 Straight",
                          "30*60 Straight Full",
                          "30*60 Loop",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "Livo Leg slanted",
                          "SLEEK LEG",
                          "CURVIVO",
                          "SLEEK LEG FULL",
                        ],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                          "DESKPRO SLANT FULL",
                          "40*40 Slanted Leg Full",
                          "TRIANGULAR LEG (SLANT FULL)",
                          "30*60 Slant",
                          "30*60 Slant Full",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Black",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Black.jpg",
                  },
                ],
              },
              {
                name: "Charcoal",
                thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
                  },
                ],
              },
              {
                name: "Classic Planked Walnut",
                thumbnail:
                  "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl:
                      "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
                  },
                ],
              },
              {
                name: "Everest White",
                thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
                  },
                ],
              },
              {
                name: "Lyonus Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia",
                thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
                  },
                ],
              },
              {
                name: "Sonama Oak",
                thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
                  },
                ],
              },
              {
                name: "Warm Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "wood",
                    textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Accessories",
    options: [
      {
        name: "Linear Workstation Sharing",
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",

            rules: [
              {
                type: replacePart,
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/fc5f044e-b7c4-4667-8ec6-e121602bf06e/21_5_2024/1718973989148/singlearm-1.glb",
              },
              {
                type: replacePart,
                node: [
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/2fba5329-a5fa-4878-9ddf-b874872d7e5d/21_5_2024/1718974046451/singlearm-1-180.glb",
              },
              {
                type: "show",
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
              {
                type: "hide",
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: replacePart,
                node: [
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/5ac98f00-80e1-4fdf-9445-26da36522405/21_5_2024/1718973630993/Dualarm-1.glb",
              },
              {
                type: replacePart,
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/05d384d4-1332-40c9-8ef8-76945aabd2c2/21_5_2024/1718973689449/Dualarm-1-180.glb",
              },
              {
                type: "hide",
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
              {
                type: "show",
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "Linear Workstation Non Sharing",
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",

            rules: [
              {
                type: replacePart,
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/fc5f044e-b7c4-4667-8ec6-e121602bf06e/21_5_2024/1718973989148/singlearm-1.glb",
              },
              {
                type: replacePart,
                node: [
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/2fba5329-a5fa-4878-9ddf-b874872d7e5d/21_5_2024/1718974046451/singlearm-1-180.glb",
              },
              {
                type: "show",
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
              {
                type: "hide",
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: replacePart,
                node: [
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/5ac98f00-80e1-4fdf-9445-26da36522405/21_5_2024/1718973630993/Dualarm-1.glb",
              },
              {
                type: replacePart,
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/05d384d4-1332-40c9-8ef8-76945aabd2c2/21_5_2024/1718973689449/Dualarm-1-180.glb",
              },
              {
                type: "hide",
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
              {
                type: "show",
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Sharing",
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",
            rules: [
              {
                type: "show",
                node: ["SINGLE_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["2 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/d42684ef-573f-47de-95b4-47dbadbb5feb/17_6_2024/1721221980157/2seater-SINGLE-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["4 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/269a45d6-388b-45ad-9e6d-051f5c0ce3c4/25_6_2024/1721903909867/4seater-SINGLE-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["6 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/a1172c35-bb03-41af-967c-f77fb37af8fd/22_6_2024/1721629891964/6seater-SINGLE-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["8 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/b9c0e4ec-3fae-4896-b40a-d589bade29ca/17_6_2024/1721214645931/8seater-SINGLE-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["10 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/3f574835-bb9f-4da6-9ef3-68e288795af1/17_6_2024/1721228461193/10seater-SINGLE-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["12 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/67c73faf-7e49-4775-9dd9-f280a1607374/17_6_2024/1721220350471/12seater-SINGLE-MONITOR-ARM-.glb",
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: "show",
                node: ["DUAL_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_1",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["2 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/5b08022d-0885-4da1-91be-f4a240dd923d/17_6_2024/1721221943901/2seater-DUAL-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["4 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/231b30da-9187-4036-9d4f-1e63999ffd7e/25_6_2024/1721903972861/4seater-DOUBLE-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["6 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/01f73414-396c-42bb-8057-7fe6a93d0183/17_6_2024/1721229781113/6seater-DUAL-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["8 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/2c91e8f5-9448-4327-8d1f-5568b3a4fcd1/17_6_2024/1721213424281/8seater-DUAL-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["10 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/1ff2a713-a825-4fe3-b513-a657619c7a81/17_6_2024/1721228079505/10seater-DUAL-MONITOR-ARM-.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Sharing",
                    values: ["12 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/ff3231bd-7ff3-4915-9df9-19b4ba47c28d/17_6_2024/1721215951541/12seater-DUAL-MONITOR-ARM-.glb",
              },
            ],
          },
          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation Non Sharing",
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",
            rules: [
              {
                type: "show",
                node: ["SINGLE_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["1 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/47b3d92e-8818-4991-a2f7-a67c36f7d4cf/18_6_2024/1721309007794/1-SEATER-L-SHAPE-NONSHARING-SINGLE-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["2 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/d5a35d01-7118-4916-a702-c8a5656ad59b/26_6_2024/1721986243884/2seater-SINGLE-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["3 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/f9299e42-c5ee-4912-9dff-df1e7500fa85/18_6_2024/1721306904041/3-SEATER-L-SHAPE-NONSHARING-SINGLE-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["4 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/ad839c09-ae2e-4554-9022-57a13ecc4132/18_6_2024/1721296711443/4-SEATER-L-SHAPE-NONSHARING-SINGLE-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["5 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/1230a500-e08d-4677-ad92-7ce8c3e3d1e0/18_6_2024/1721306096458/5-SEATER-L-SHAPE-NONSHARING-SINGLE-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["6 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/2d052470-c828-471a-a2b3-ae19a4e3a316/19_6_2024/1721369454601/6seater-SINGLE-MONITOR-ARM-.glb",
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: "show",
                node: ["DUAL_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_1",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["1 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/2f22dedc-c647-46cb-af55-ad894f783472/18_6_2024/1721308896672/1-SEATER-L-SHAPE-NONSHARING-DUAL-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["2 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/7064d41f-2a99-4d42-8f9a-41e80edf20c8/26_6_2024/1721986170510/2seater-DUAL-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["3 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/fd833984-ec22-4aeb-b4c6-d05f71553db4/18_6_2024/1721306385258/3-SEATER-L-SHAPE-NONSHARING-DUAL-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["4 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/ba2411e1-9dd1-4042-9cd9-cbb307278a3f/18_6_2024/1721296653508/4-SEATER-L-SHAPE-NONSHARING-DUAL-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["5 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/8941c603-49cb-4113-8ae3-32ce4aa30f7b/18_6_2024/1721306017033/5-SEATER-L-SHAPE-NONSHARING-DUAL-MONITOR-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "Curvlinear / L-shape Workstation Non Sharing",
                    values: ["6 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/dae4f0cf-b3b2-4f8e-a7ca-d5f9c03b9321/18_6_2024/1721300116892/6-SEATER-L-SHAPE-NONSHARING-DUAL-MONITOR-ARM.glb",
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "120 Deg Workstation Sharing",
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",
            rules: [
              {
                type: "show",
                node: ["SINGLE_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Sharing",
                    values: ["3 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/973b01f6-5dab-42c2-9f6d-1ef954104c4a/25_6_2024/1721904367901/3-seater-SINGLE-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Sharing",
                    values: ["6 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/721988d8-f84f-4ff4-b592-677b74c5ef24/18_6_2024/1721283172161/6-SEATER-120-SHARING-SINGLE.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Sharing",
                    values: ["9 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/49adb744-b240-4e14-8e82-cc96457a9431/18_6_2024/1721286348393/9-SEATER-120-SHARING-SINGLE.glb",
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: "show",
                node: ["DUAL_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_1",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Sharing",
                    values: ["3 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/3e38bc3d-9f7d-4a38-bfb6-049afcbdd46e/26_6_2024/1721987249125/3-seater-DUAL-ARM.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Sharing",
                    values: ["6 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/21df7415-8aa1-4c03-aa0e-1f41edab8f8e/18_6_2024/1721283020696/6-SEATER-120-SHARING-DUAL.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Sharing",
                    values: ["9 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/eb7eccca-de7e-48a6-b031-33714399e482/18_6_2024/1721285935808/9-SEATER-120-SHARING-DUAL.glb",
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "120 Deg Workstation Non Sharing",
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",
            rules: [
              {
                type: "show",
                node: ["SINGLE_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Non Sharing",
                    values: ["1 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/d45aacc3-265e-4b90-9d7e-47673feace33/26_6_2024/1721969636007/1-seater-Single-arm.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Non Sharing",
                    values: ["2 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/e2f1c368-5e8f-4dc5-b86e-39dd7aeb2b56/18_6_2024/1721291005239/2-SEATER-120-deg-SINGLE.glb",
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: "show",
                node: ["DUAL_MONITOR_ARM_1"],
              },
              {
                type: "hide",
                node: [
                  "SINGLE_MONITOR_ARM_1",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_2",
                ],
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Non Sharing",
                    values: ["1 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/b3b6398e-7b08-4351-9c08-7cfa1ac83415/26_6_2024/1721969596296/1-seater-Dual-arm.glb",
              },
              {
                type: replacePart,
                condition: [
                  {
                    feature: "120 Deg Workstation Non Sharing",
                    values: ["2 SEATER"],
                  },
                ],
                node: "monitorarm",
                pid: "https://content.helloviewer.io/uploads/ba1e0045-34af-4eb7-bd57-b37520a21a1e/18_6_2024/1721290955406/2-SEATER-120-deg-DUAL.glb",
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
