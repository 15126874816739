// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "2 SEATER",
            size: 1,
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
            ],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/hat/sharing/2seater.png",
            productId: "667ce4ce651a66629cd26cef ",
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "2 Seater",
            size: 1,
            disableFeatures: [
              "Prelam Raceway Options",
              "Chamfer Raceway Options",
              "PostForm Raceway Options",
              "Waterfall Raceway Options",
            ],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/hat/nonsharing/2seater.png",
            productId: "669a2ca70620762597b33222",
          },
        ],
      },
      // {
      //   name: "Curvlinear / L-shape Workstation SHARING",
      //   required: false,
      //   options: [
      //     {
      //       name: "Four seater",
      //       size: 2,
      //       enableOptions: ["Leveller"],
      //       Length: 1800,
      //       Depth: 900,
      //       thumbnail: "/assets/system/placeholder.svg",
      //       productId: "667583f6ab912673bf608ab2",
      //     },
      //   ],
      // },
      // {
      //   name: "Curvlinear / L-shape Workstation NON SHARING",
      //   required: false,
      //   options: [
      //     {
      //       name: "Two seater",
      //       size: 2,
      //       enableOptions: ["Leveller"],
      //       Length: 1800,
      //       Depth: 900,
      //       thumbnail: "/assets/system/placeholder.svg",
      //       productId: "66757291749d22af5874dfba",
      //     },
      //   ],
      // },
      // {
      //   name: "120 Deg Workstation SHARING",
      //   required: false,
      //   options: [
      //     {
      //       name: "Three Seater",
      //       size: 4,
      //       disableOptions: ["Leveller"],
      //       Length: 1800,
      //       Depth: 900,
      //       thumbnail: "/assets/system/placeholder.svg",
      //       productId: "667935aa441b5e59aad481c9",
      //     },
      //   ],
      // },
      // {
      //   name: "120 Deg Workstation NON SHARING",
      //   required: false,
      //   options: [
      //     {
      //       name: "One Seater",
      //       size: 4,
      //       disableOptions: ["Leveller"],
      //       Length: 1800,
      //       Depth: 900,
      //       thumbnail: "/assets/system/placeholder.svg",
      //       productId: "6679198e15b51d167f85e502",
      //     },
      //   ],
      // },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "600 mm",
          //       value: 600,
          //     },
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "600 mm",
          //       value: 600,
          //     },
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation SHARING",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation SHARING",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              [
                {
                  name: "450 mm",
                  value: 450,
                },
                {
                  name: "600 mm",
                  value: 600,
                },
                {
                  name: "900 mm",
                  value: 900,
                },
                {
                  name: "1050 mm",
                  value: 1050,
                },
                {
                  name: "1200 mm",
                  value: 1200,
                },
                {
                  name: "1350 mm",
                  value: 1350,
                },
                {
                  name: "1500 mm",
                  value: 1500,
                },
                {
                  name: "1650 mm",
                  value: 1650,
                },
                {
                  name: "1800 mm",
                  value: 1800,
                },
              ],
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "450 mm",
                value: 450,
              },
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Width1",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Width2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Table Top",
    required: false,
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PLPB STANDARD TOP",
                enableFeatures: ["Wire Manager for Standard Top"],
                disableFeatures: [
                  "Wire Manager for Chamfer Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_flipup",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Wire Manager for Chamfer Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_flipup",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB POSTFORM TOP",
                enableFeatures: ["Wire Manager for Postform Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Chamfer Top",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_flipup",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: "postform_edge",
                //   },
                // ],
              },

              // {
              //   name: "25 MM PRELAMINATED MDF BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b57ab7c7402d4fb5251f1",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b5783c90a726a227b7c03",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "prelammid",
              //         "metalmodestymid",
              //         "prelamleft",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //         "prelamright",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["workconnect"],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MDF POSTFORMED BAORD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY POST FORMED BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM WHITE MARKER LAMINATE IN PARTICLE",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MARINE PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM BWP PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PRELAMINATED E1 GRADE BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 THK COMM. PLY WITH 4 MM THK VENEER FINISH TOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //  ],
              //   },
            ],
          },
          {
            name: "Wire Manager for Standard Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_flipup"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Chamfer Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_flipup"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Postform Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_flipup"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PLPB STANDARD TOP",
                enableFeatures: ["Wire Manager for Standard Top"],
                disableFeatures: [
                  "Wire Manager for Chamfer Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_flipup",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Wire Manager for Chamfer Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_flipup",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB POSTFORM TOP",
                enableFeatures: ["Wire Manager for Postform Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Chamfer Top",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_flipup",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: "postform_edge",
                //   },
                // ],
              },

              // {
              //   name: "25 MM PRELAMINATED MDF BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b57ab7c7402d4fb5251f1",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b5783c90a726a227b7c03",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "prelammid",
              //         "metalmodestymid",
              //         "prelamleft",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //         "prelamright",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["workconnect"],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MDF POSTFORMED BAORD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY POST FORMED BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM WHITE MARKER LAMINATE IN PARTICLE",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MARINE PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM BWP PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PRELAMINATED E1 GRADE BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 THK COMM. PLY WITH 4 MM THK VENEER FINISH TOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //  ],
              //   },
            ],
          },
          {
            name: "Wire Manager for Standard Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_flipup"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Chamfer Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_flipup"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Postform Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },

              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_flipup"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Privacy",
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Raceway Mounted Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                ],
              },
              {
                name: "Sleek with aluminium beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder"],
                  },
                ],
              },
              {
                name: "Sleek with Nylon beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded"],
                  },
                ],
              },
              {
                name: " Xbench",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbench"],
                  },
                ],
              },
              {
                name: " Xbench Rail",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbenchrail"],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Top Mounted Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                ],
              },
              {
                name: "Sleek with aluminium beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_high"],
                  },
                ],
              },
              {
                name: "Sleek with Nylon beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_high"],
                  },
                ],
              },
              {
                name: " Xbench",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbench_high"],
                  },
                ],
              },
              {
                name: " Xbench Rail",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbenchrail_high"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top Mounted Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                      "raceway",
                    ],
                  },
                ],
              },
              {
                name: "Sleek with aluminium beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_high"],
                  },
                ],
              },
              {
                name: "Sleek with Nylon beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_high"],
                  },
                ],
              },
              {
                name: " Xbench",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbench_high"],
                  },
                ],
              },
              {
                name: " Xbench Rail",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_high",
                      "fullfabricrounded_high",
                      "Xbench_high",
                      "Xbenchrail_high",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbenchrail_high"],
                  },
                ],
              },
            ],
          },
          {
            name: "Raceway Mounted Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                      "raceway",
                    ],
                  },
                ],
              },
              {
                name: "Sleek with aluminium beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder", "raceway"],
                  },
                ],
              },
              {
                name: "Sleek with Nylon beading",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded", "raceway"],
                  },
                ],
              },
              {
                name: " Xbench",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbench", , "raceway"],
                  },
                ],
              },
              {
                name: " Xbench Rail",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "fullfabricrounded",
                      "Xbench",
                      "Xbenchrail",
                    ],
                  },
                  {
                    type: show,
                    node: ["Xbenchrail", , "raceway"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation SHARING",
        required: false,
        options: [
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "alumhalfandhalf",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "alumhalfandhalf",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "alumhalfandhalf",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "alumhalfandhalf",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "alumhalfandhalf",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Panel Options",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded_1",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "alumhalfandhalf_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "alumhalfandhalf_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "alumhalfandhalf_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "alumhalfandhalf_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "alumhalfandhalf_1",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "woodmodesty",
                  },
                  {
                    type: "hide",
                    node: "metalmodesty",
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "metalmodesty",
                  },
                  {
                    type: "hide",
                    node: "woodmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "Leg Modesty",
            nodeName: ["legmetalmodesty", "legwoodmodesty"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["legmetalmodesty", "legwoodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["left_leg", "right_leg", "legwoodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["legmetalmodesty"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "legmetalmodesty",
                  },
                  {
                    type: "hide",
                    node: "legwoodmodesty",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation NON SHARING",
        required: false,
        options: [
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "alumhalfandhalf",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "alumhalfandhalf",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "alumhalfandhalf",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "alumhalfandhalf",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "alumhalfandhalf",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Panel Options",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded_1",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "alumhalfandhalf_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "alumhalfandhalf_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "alumhalfandhalf_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "alumhalfandhalf_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "alumhalfandhalf_1",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "woodmodesty",
                  },
                  {
                    type: "hide",
                    node: "metalmodesty",
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "metalmodesty",
                  },
                  {
                    type: "hide",
                    node: "woodmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "Leg Modesty",
            nodeName: ["legmetalmodesty", "legwoodmodesty"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["legmetalmodesty", "legwoodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["left_leg", "right_leg", "legwoodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["legmetalmodesty"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "legmetalmodesty",
                  },
                  {
                    type: "hide",
                    node: "legwoodmodesty",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation SHARING",
        required: false,
        options: [
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "alumhalfandhalf",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "alumhalfandhalf",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "alumhalfandhalf",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "alumhalfandhalf",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "alumhalfandhalf",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Panel Options",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded_1",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "alumhalfandhalf_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "alumhalfandhalf_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "alumhalfandhalf_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "alumhalfandhalf_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "alumhalfandhalf_1",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "woodmodesty",
                  },
                  {
                    type: "hide",
                    node: "metalmodesty",
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "metalmodesty",
                  },
                  {
                    type: "hide",
                    node: "woodmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "Leg Modesty",
            nodeName: ["legmetalmodesty", "legwoodmodesty"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["legmetalmodesty", "legwoodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["left_leg", "right_leg", "legwoodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["legmetalmodesty"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "legmetalmodesty",
                  },
                  {
                    type: "hide",
                    node: "legwoodmodesty",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation NON SHARING",
        required: false,
        options: [
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "alumhalfandhalf",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "alumhalfandhalf",
                      "roundedlaminate",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "alumhalfandhalf",
                      "panelwithplants",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "alumhalfandhalf",
                      "roundedglass",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded",
                      "rectangularfabric",
                      "roundedlaminatewithmarble",
                      "roundedlaminate",
                      "panelwithplants",
                      "alumhalfandhalf",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Panel Options",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "Aluminium half and half",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["alumhalfandhalf_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "alumimium fullfabric rounded",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "alumfullfabricrounded_1",
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rectangular Fabric",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["rectangularfabric_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "alumhalfandhalf_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Laminate with Marble",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminatewithmarble_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "alumhalfandhalf_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded laminate",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["roundedlaminate_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "alumhalfandhalf_1",
                      "panelwithplants_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Panel with plants",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "alumhalfandhalf_1",
                      "roundedglass_1",
                    ],
                  },
                ],
              },
              {
                name: "Rounded Glass",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",

                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1"],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricrounded_1",
                      "rectangularfabric_1",
                      "roundedlaminatewithmarble_1",
                      "roundedlaminate_1",
                      "panelwithplants_1",
                      "alumhalfandhalf_1",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Table Modesty",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "woodmodesty",
                  },
                  {
                    type: "hide",
                    node: "metalmodesty",
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "metalmodesty",
                  },
                  {
                    type: "hide",
                    node: "woodmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "Leg Modesty",
            nodeName: ["legmetalmodesty", "legwoodmodesty"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["legmetalmodesty", "legwoodmodesty"],
                  },
                ],
              },
              {
                name: "Wood",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["left_leg", "right_leg", "legwoodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["legmetalmodesty"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: "legmetalmodesty",
                  },
                  {
                    type: "hide",
                    node: "legwoodmodesty",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Main Privacy Color Option",
    options: [
      {
        name: "SHARING",
        multiSelect: false,
        options: [
          {
            name: "Acoustic Fabric",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Main Fabric Color",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiSelect: false,
        options: [
          {
            name: "Acoustic Fabric",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Main Fabric Color",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Modesty Options",

    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: ["woodmodesty"],
                  },
                  {
                    type: "show",
                    node: "metalmodesty",
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: ["metalmodesty"],
                  },
                  {
                    type: "show",
                    node: "woodmodesty",
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/black.jpeg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/white.jpeg",
                  },
                ],
              },
              // {
              //   name: "AFC Munshell Grey",
              //   thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Graphite Grey",
              //   thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Silver Grey",
              //   thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC DA Grey",
              //   thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/dagrey.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Sky Blue",
              //   thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/blue.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Sea Green",
              //   thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/green.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Light Orange",
              //   thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/orange.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Misty Grey",
              //   thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
              //     },
              //   ],
              // },
              // {
              //   name: "AFC Nut Brown",
              //   thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
              //   rules: [
              //     {
              //       type: applyMaterial,
              //       node: ["leg", "Leg"],
              //       textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      // {
      //   name: "NON SHARING",
      //   required: false,
      //   multiSelect: false,
      //   options: [
      //     {
      //       ////
      //       name: "Side Modesty",
      //       nodeName: ["metalmodesty_side", "woodmodesty_side"],
      //       required: false,
      //       options: [
      //         {
      //           name: "none",
      //           thumbnail: "/assets/components/none.jpg",
      //           pid: "",
      //           rules: [
      //             {
      //               type: "hide",
      //               node: [
      //                 "metalmodesty",
      //                 "woodmodesty",
      //               ],
      //             },
      //           ],
      //         },
      //         {
      //           name: "Side Metal",
      //           thumbnail: "/assets/afc/wsm.png",

      //           rules: [
      //             {
      //               type: "hide",

      //               node: [
      //                 "woodmodesty",
      //               ],
      //             },
      //             {
      //               type: "show",
      //               node: "metalmodesty",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Side Wood",
      //           thumbnail: "/assets/afc/wsm.png",

      //           rules: [
      //             {
      //               type: "hide",

      //               node: [
      //                 "metalmodesty",
      //               ],
      //             },
      //             {
      //               type: "show",
      //               node: "woodmodesty",
      //             },
      //           ],
      //         },

      //       ],
      //     },
      //     {
      //       name: "PowderCoat  Options for Modesty",
      //       multiSelect: false,
      //       options: [
      //         {
      //           name: "AFC Black",
      //           thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "metalmodesty",
      //               textureUrl: "/assets/textures/Metal/black.jpeg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "AFC White",
      //           thumbnail:
      //             "/assets/textures/Metal/thumbnail/white.jpeg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "metalmodesty",
      //               textureUrl:
      //                 "/assets/textures/Metal/white.jpeg",
      //             },
      //           ],
      //         },
      //         // {
      //         //   name: "AFC Munshell Grey",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Graphite Grey",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Silver Grey",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC DA Grey",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/dagrey.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Sky Blue",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/blue.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Sea Green",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/green.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Light Orange",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/orange.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Misty Grey",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //         // {
      //         //   name: "AFC Nut Brown",
      //         //   thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
      //         //   rules: [
      //         //     {
      //         //       type: applyMaterial,
      //         //       node: ["leg", "Leg"],
      //         //       textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
      //         //     },
      //         //   ],
      //         // },
      //       ],
      //     },
      //     {
      //       name: "Wood Color Options for Modesty",
      //       multiSelect: false,
      //       options: [
      //         {
      //           // name: "Options",
      //           // required: false,
      //           // options: [
      //           //   {
      //           name: "Frosty White",
      //           thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: ["woodmodesty"],
      //               textureUrl: "/assets/textures/Wood/2k/white.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Slate Grey",
      //           thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Gothic Grey",
      //           thumbnail:
      //             "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Silver Grey",
      //           thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Highland Pine",
      //           thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/highland.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Moldau Acacia Light",
      //           thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Moldau Acacia Dark",
      //           thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Thansau Maple",
      //           thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Mangfall Beech",
      //           thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Flowery Wenge",
      //           thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Persian Walnut",
      //           thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/persian.jpg",
      //             },
      //           ],
      //         },
      //         {
      //           name: "Urban Teak",
      //           thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
      //           rules: [
      //             {
      //               type: applyMaterial,
      //               node: "woodmodesty",
      //               textureUrl: "/assets/textures/Wood/2k/urban.jpg",
      //             },
      //           ],
      //         },
      //         // ],
      //         // },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  {
    name: "Powder Coat Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/black.jpeg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/white.jpeg",
              },
            ],
          },
          // {
          //   name: "AFC Munshell Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Graphite Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Silver Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC DA Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/dagrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Sky Blue",
          //   thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/blue.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Sea Green",
          //   thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/green.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Light Orange",
          //   thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/orange.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Misty Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Nut Brown",
          //   thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/black.jpeg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/white.jpeg",
              },
            ],
          },
          // {
          //   name: "AFC Munshell Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Graphite Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Silver Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC DA Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/dagrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Sky Blue",
          //   thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/blue.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Sea Green",
          //   thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/green.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Light Orange",
          //   thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/orange.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Misty Grey",
          //   thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
          //     },
          //   ],
          // },
          // {
          //   name: "AFC Nut Brown",
          //   thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
          //   rules: [
          //     {
          //       type: applyMaterial,
          //       node: ["leg", "Leg"],
          //       textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation NON SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "120 Deg Workstation NON SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: ["leg", "Leg"],
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Frosty White",
            thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/white.jpg",
              },
            ],
          },
          {
            name: "Slate Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/highland.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Light",
            thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Dark",
            thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
              },
            ],
          },
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
              },
            ],
          },
          {
            name: "Flowery Wenge",
            thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
              },
            ],
          },
          {
            name: "Persian Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/persian.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/urban.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Frosty White",
            thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/white.jpg",
              },
            ],
          },
          {
            name: "Slate Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/highland.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Light",
            thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Dark",
            thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
              },
            ],
          },
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
              },
            ],
          },
          {
            name: "Flowery Wenge",
            thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
              },
            ],
          },
          {
            name: "Persian Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/persian.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/urban.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "Curvlinear / L-shape Workstation NON SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "120 Deg Workstation SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "120 Deg Workstation NON SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
    ],
  },
  // {
  //   name: "Glass Color",
  //   options: [
  //     {
  //       name: "SHARING",
  //       required: false,
  //       options: [
  //         {
  //           name: "Apple Green",
  //           thumbnail: "/assets/textures/Glass/Apple Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Apple Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Aqua Maring",
  //           thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Azure Blue",
  //           thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Beige",
  //           thumbnail: "/assets/textures/Glass/Beige.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Beige.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Black",
  //           thumbnail: "/assets/textures/Glass/Black.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Black.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Canary Yellow",
  //           thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Casis Purple",
  //           thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Classic Ivory",
  //           thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Crystal White",
  //           thumbnail: "/assets/textures/Glass/Crystal White.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Crystal White.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Blue",
  //           thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Bronze",
  //           thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Lavender",
  //           thumbnail: "/assets/textures/Glass/dark lavender.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/dark lavender.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Red",
  //           thumbnail: "/assets/textures/Glass/Dark Red.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Dark Red.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Ergo Grey",
  //           thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Euro Bronze Frosted",
  //           thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Euro Bronze",
  //           thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Euro Grey",
  //           thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Flame Red",
  //           thumbnail: "/assets/textures/Glass/Flame-red.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Flame-red.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Frosted White",
  //           thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Green Lime",
  //           thumbnail: "/assets/textures/Glass/Green Lime.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Green Lime.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Mediterranean Blue",
  //           thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "glass",
  //               textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Ocean Green",
  //           thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Olive Green",
  //           thumbnail: "/assets/textures/Glass/Olive Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Olive Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Orange Mist",
  //           thumbnail: "/assets/textures/Glass/orange mist.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/orange mist.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sky Blue",
  //           thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Solar Yellow",
  //           thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Steel Grey",
  //           thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sunny Orange",
  //           thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Super White",
  //           thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Tangerine Orange",
  //           thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "NON SHARING",
  //       required: false,
  //       options: [
  //         {
  //           name: "Apple Green",
  //           thumbnail: "/assets/textures/Glass/Apple Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Apple Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Aqua Maring",
  //           thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Azure Blue",
  //           thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Beige",
  //           thumbnail: "/assets/textures/Glass/Beige.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Beige.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Black",
  //           thumbnail: "/assets/textures/Glass/Black.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Black.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Canary Yellow",
  //           thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Casis Purple",
  //           thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Classic Ivory",
  //           thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Crystal White",
  //           thumbnail: "/assets/textures/Glass/Crystal White.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Crystal White.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Blue",
  //           thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Bronze",
  //           thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Lavender",
  //           thumbnail: "/assets/textures/Glass/dark lavender.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/dark lavender.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Dark Red",
  //           thumbnail: "/assets/textures/Glass/Dark Red.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Dark Red.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Ergo Grey",
  //           thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Euro Bronze Frosted",
  //           thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Euro Bronze",
  //           thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Euro Grey",
  //           thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Flame Red",
  //           thumbnail: "/assets/textures/Glass/Flame-red.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Flame-red.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Frosted White",
  //           thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Green Lime",
  //           thumbnail: "/assets/textures/Glass/Green Lime.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Green Lime.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Mediterranean Blue",
  //           thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Ocean Green",
  //           thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Olive Green",
  //           thumbnail: "/assets/textures/Glass/Olive Green.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Olive Green.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Orange Mist",
  //           thumbnail: "/assets/textures/Glass/orange mist.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/orange mist.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sky Blue",
  //           thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Solar Yellow",
  //           thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Steel Grey",
  //           thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Sunny Orange",
  //           thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Super White",
  //           thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Tangerine Orange",
  //           thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
  //           rules: [
  //             {
  //               type: applyMaterial,
  //               node: "GLASS",
  //               textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   name: "Accessories",
  //   options: [
  //     {
  //       name: "SHARING",
  //       options: [
  //         {
  //           name: "None",
  //           thumbnail: "/assets/components/none.jpg",
  //           rules: [
  //             {
  //               type: "hide",
  //               node: [
  //                 "cpu_stand",
  //                 "keyboardtray",
  //                 "keyboardmousetray",
  //                 "sliderpowerdeskport",
  //                 "powerdeskport",
  //                 "singlearm_1 , singlearm_2",
  //                 "dualarm_1 , dualarm_2",
  //               ],
  //             },
  //           ],
  //         },

  //         {
  //           name: "CPU Stand",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "show",
  //               node: ["cpu_stand"],
  //             },
  //           ],
  //         },

  //         {
  //           name: "Keyboard Tray",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "hide",
  //               node: ["keyboardmousetray"],
  //             },
  //             {
  //               type: "show",
  //               node: ["keyboardtray"],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Keyboard and Mouse Tray",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "hide",
  //               node: ["keyboardtray"],
  //             },
  //             {
  //               type: "show",
  //               node: ["keyboardmousetray"],
  //             },
  //           ],
  //         },

  //         {
  //           name: "SLIDER POWER DESKPORT WITH WIFI CHARGER 350/560X100MM",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "show",
  //               node: ["sliderpowerdeskport"],
  //             },
  //             {
  //               type: "hide",
  //               node: ["powerdeskport"],
  //             },
  //           ],
  //         },

  //         {
  //           name: "Power Deskport",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "show",
  //               node: ["powerdeskport"],
  //             },
  //             {
  //               type: "hide",
  //               node: ["sliderpowerdeskport"],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "NON SHARING",
  //       options: [
  //         {
  //           name: "None",
  //           thumbnail: "/assets/components/none.jpg",
  //           rules: [
  //             {
  //               type: "hide",
  //               node: [
  //                 "cpu_stand",
  //                 "keyboardtray",
  //                 "keyboardmousetray",
  //                 "sliderpowerdeskport",
  //                 "powerdeskport",
  //                 "singlearm_1 , singlearm_2",
  //                 "dualarm_1 , dualarm_2",
  //               ],
  //             },
  //           ],
  //         },

  //         {
  //           name: "CPU Stand",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "show",
  //               node: ["cpu_stand"],
  //             },
  //           ],
  //         },

  //         {
  //           name: "Keyboard Tray",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "hide",
  //               node: ["keyboardmousetray"],
  //             },
  //             {
  //               type: "show",
  //               node: ["keyboardtray"],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Keyboard and Mouse Tray",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "hide",
  //               node: ["keyboardtray"],
  //             },
  //             {
  //               type: "show",
  //               node: ["keyboardmousetray"],
  //             },
  //           ],
  //         },

  //         {
  //           name: "SLIDER POWER DESKPORT WITH WIFI CHARGER 350/560X100MM",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "show",
  //               node: ["sliderpowerdeskport"],
  //             },
  //             {
  //               type: "hide",
  //               node: ["powerdeskport"],
  //             },
  //           ],
  //         },

  //         {
  //           name: "Power Deskport",
  //           thumbnail: "/assets/components/nameboard.png",
  //           rules: [
  //             {
  //               type: "show",
  //               node: ["powerdeskport"],
  //             },
  //             {
  //               type: "hide",
  //               node: ["sliderpowerdeskport"],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]);

export type ConfigData = typeof ConfData3;
