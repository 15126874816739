import { Order } from "../types/types";
import axios from "axios";
import { getUserFromLocalStorage } from "./localStorage";

export const getOrders = async (
  // userId?: string,
  currentPage?: number,
  pageSize?: number
) => {
  const user = getUserFromLocalStorage();
  // const role = user.role;
  // const userId = user.account_id;
  const authToken = user.access_token;
  const whereObj = encodeURI(
    JSON.stringify({
      viewer: process.env.REACT_APP_VIEWER_ID,
      // created_by: userId,
    })
  );

  return await axios
    .get<{ _items: Order[]; _meta: any }>(
      process.env.REACT_APP_HELLOAR_API + "viewer_orders?where=" + whereObj,
      {
        params: {
          max_results: pageSize,
          page: currentPage,
          sort: "-created_at",
        },
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
    .then((res) => {
      return { _items: res.data._items, totalOrders: res.data._meta.total };
    });
};

//patch order
export const patchOrder = async (_order: Order) => {
  const order = { ..._order };
  delete order.created_at;
  delete order.updated_at;

  const user = await getUserFromLocalStorage();

  return await axios
    .patch(
      process.env.REACT_APP_HELLOAR_API + `viewer_orders/${order._id}`,
      order,
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Token ${user.access_token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error("error creating order:" + err.message);
    });
};

//delete order
export const deleteOrder = async (orderId: string) => {
  const user = getUserFromLocalStorage();
  return await axios
    .delete(process.env.REACT_APP_HELLOAR_API + "viewer_orders/" + orderId, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Token ${user.access_token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error("error deleting order:" + err.message);
    });
};

//create order
export const createOrder = async (order: Order) => {
  const user = getUserFromLocalStorage();
  return await axios
    .post(process.env.REACT_APP_HELLOAR_API + "viewer_orders", order, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Token ${user.access_token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error("error creating order:" + err.message);
    });
};

//search orders

export const searchOrders = async (
  searchTerm: string,
  currentPage?: number,
  pageSize?: number
) => {
  const user = getUserFromLocalStorage();

  const authToken = user.access_token;
  const whereObj = encodeURI(
    JSON.stringify({
      ...(searchTerm && {
        $or: [
          { "customer.name": { $regex: searchTerm, $options: "i" } },
          { "customer.email": { $regex: searchTerm, $options: "i" } },
          { "customer.phone_number": { $regex: searchTerm, $options: "i" } },
          { "customer.location": { $regex: searchTerm, $options: "i" } },
          { lead_id: { $regex: searchTerm, $options: "i" } },
        ],
      }),
      viewer: process.env.REACT_APP_VIEWER_ID,
    })
  );

  return await axios
    .get<{ _items: Order[]; _meta: any }>(
      process.env.REACT_APP_HELLOAR_API + "viewer_orders?where=" + whereObj,
      {
        params: {
          max_results: pageSize,
          page: currentPage,
          sort: "-created_at",
        },
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
    .then((res) => {
      return { _items: res.data._items, totalOrders: res.data._meta.total };
    });
};
