import { combineReducers } from "redux";
import { configurationReducer } from "./configuration";
import { configDataReducer } from "./configData";
import { userReducer } from "./user";
import { ordersReducer } from "./orders";
import { tableTypeReducer } from "./tableType";
import { tableInfoReducer } from "./tableInfo";
import { productsReducer } from "./product";
export default combineReducers({
  configuration: configurationReducer,
  configData: configDataReducer,
  user: userReducer,
  orders: ordersReducer,
  tableType: tableTypeReducer,
  tableInfo: tableInfoReducer,
  productInfo: productsReducer
});
