import { UserConfiguration } from "../../types/types";
import { configurationConstants } from "../actions/types";

const initialState: {
  primaryBarSelection: any | null;
  userConfiguration: {};
  userConfiguration2: Partial<UserConfiguration>;
  selectionStatus: {};
  disabledFeatures: string[];
  disabledOptions: string[];
} = {
  userConfiguration: {},
  userConfiguration2: {},
  primaryBarSelection: null,
  selectionStatus: { System: true },
  disabledFeatures: [],
  disabledOptions: [],
};

type ActionType = { type: any; payload: any };

export const configurationReducer = (
  state = initialState,
  action: ActionType
): typeof initialState => {
  const { type, payload } = action;
  switch (type) {
    case configurationConstants.SET_CONFIGURATION:
      return {
        ...state,
        userConfiguration: payload,
      };

    case configurationConstants.UPDATE_CONFIGURATION2:
      return {
        ...state,
        userConfiguration2: { ...state.userConfiguration2, ...payload },
      };
    case configurationConstants.DELETE_PROPERTY:
      return {
        ...state,
        userConfiguration2: {
          ...state.userConfiguration2,
          [payload]: undefined,
        },
      };
    case configurationConstants.RESET_CONFIGURATION:
      return {
        ...state,

        userConfiguration2: {},
      };
    case configurationConstants.UPDATE_SELECTION_STATUS:
      return {
        ...state,
        selectionStatus: { ...state.selectionStatus, ...payload },
      };

    case configurationConstants.SET_PRIMARY_BAR_SELECTION:
      return {
        ...state,
        primaryBarSelection: payload,
      };

    case configurationConstants.DISABLE_FEATURES:
      const newArr = [...state.disabledFeatures];
      payload.forEach((element: string) => {
        if (!newArr.includes(element)) {
          newArr.push(element);
        }
      });

      return {
        ...state,
        disabledFeatures: newArr,
      };

    case configurationConstants.ENABLE_FEATURES:
      return {
        ...state,
        disabledFeatures: state.disabledFeatures.filter(
          (item) => !payload.includes(item)
        ),
      };

    case configurationConstants.DISABLE_OPTIONS:
      const newArr2 = [...state.disabledOptions];
      payload.forEach((element: string) => {
        if (!newArr2.includes(element)) {
          newArr2.push(element);
        }
      });

      return {
        ...state,
        disabledOptions: newArr2,
      };

    case configurationConstants.ENABLE_OPTIONS:
      return {
        ...state,
        disabledOptions: state.disabledOptions.filter(
          (item) => !payload.includes(item)
        ),
      };

    default:
      return state;
  }
};
