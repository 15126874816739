import { ConfData3 } from "../../const/const";
import { Feature } from "../../types/types";
import { configDataConstants } from "../actions/types";

const initialState: {
  original: Feature[];
  current: Feature[];
} = {
  original: ConfData3 as any,
  current: ConfData3 as any,
};

type ActionType = { type: any; payload: Feature[] };

export const configDataReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case configDataConstants.SET_CONFIG_DATA:
      return {
        ...state,
        original: action.payload,
      };
    case configDataConstants.SET_CURRENT_CONFIG_DATA:
      return {
        ...state,
        current: action.payload,
      };

    case configDataConstants.RESET_CURENT_CONFIG_DATA:
      return {
        ...state,
        current: { ...state.original },
      };

    default:
      return state;
  }
};
