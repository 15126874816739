// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "1 BAY",
        required: false,
        options: [
          {
            name: "1_BAY",
            size: 1,

            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/compactor/1_bay.png",
            productId: "66605cacabe0d023908a8bfb",
          },
        ],
      },
      {
        name: "2 BAY",
        required: false,
        options: [
          {
            name: "2_BAY",
            size: 2,
            enableOptions: ["Leveller"],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/compactor/2_bay.png",
            productId: "66605eb2661a25699626374b",
          },
        ],
      },
      {
        name: "3 BAY",
        required: false,
        options: [
          {
            name: "3_BAY",
            size: 4,
            disableOptions: ["Leveller"],
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/afc/thumnail/compactor/3_bay.png",
            productId: "666064cbb6db0143d6cfb221",
          },
        ],
      },
    ],
  },
  {
    name: "1 Compartment",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "1 BAY",
        required: false,
        options: [
          {
            name: "SINGLE SIDE FIXED",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "1bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/719f25b7-fcd5-4cb9-bb57-64d36172b5fd/6_5_2024/1717667919157/1bay-single-fixed.glb",
              },
            ],
          },
          {
            name: "SINGLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "1bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/1485441b-f537-4cac-956c-ca0330f83fd2/6_5_2024/1717668988442/1bay-single-movable.glb",
              },
            ],
          },
          {
            name: "DOUBLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "1bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/5387f0e8-efe7-4930-bba0-0d3ed2fd771b/6_5_2024/1717669009381/1bay-double-movable.glb",
              },
            ],
          },
        ],
      },
      {
        name: "2 BAY",
        required: false,
        options: [
          {
            name: "SINGLE SIDE FIXED",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "2bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/56b411ab-c809-456c-ba15-37eb2868bcf3/6_5_2024/1717674515160/1-2bay-single-fixed.glb",
              },
            ],
          },
          {
            name: "SINGLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "2bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/416f0095-4fcc-4504-a599-7cb32be8c4e4/6_5_2024/1717675253751/1-2bay-single-movable.glb",
              },
            ],
          },
          {
            name: "DOUBLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "2bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/2146ead3-d178-4b46-9df5-05af8a1e8547/6_5_2024/1717675047835/1-2bay-double-movable.glb",
              },
            ],
          },
        ],
      },
      {
        name: "3 BAY",
        required: false,
        options: [
          {
            name: "SINGLE SIDE FIXED",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "3bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/552ecf1e-32a5-4173-804c-2491aab91bec/6_5_2024/1717681711396/1-3bay-single-fixed.glb",
              },
            ],
          },
          {
            name: "SINGLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "3bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/542b3001-bd6d-44d0-ba4d-208901b8a084/6_5_2024/1717681615762/1-3bay-single-movable.glb",
              },
            ],
          },
          {
            name: "DOUBLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "3bay_single_fixed",
                pid: "https://content.helloviewer.io/uploads/2913b678-faa9-42a4-983e-1ef6ec001933/6_5_2024/1717681854103/1-3bay-doube-movable.glb",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "2 Compartment",
    multiSelect: false,
    optional: false,
    options: [],
  },
  {
    name: "3 Compartment",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "1 BAY",
        required: false,
        options: [
          {
            name: "SINGLE SIDE FIXED",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "1bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/e4d2e54b-1754-43e2-b8b4-e9f3064c0e04/6_5_2024/1717669873785/2-1bay-single-fixed.glb",
              },
            ],
          },
          {
            name: "SINGLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "1bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/70a27210-4c29-4070-8bf7-ecce4f5ebdc1/6_5_2024/1717680170502/2-1bay-single-movable.glb",
              },
            ],
          },
          {
            name: "DOUBLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "1bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/7595c03f-54f7-4aea-9203-14af36717392/6_5_2024/1717670164658/2-1bay-double-movable.glb",
              },
            ],
          },
        ],
      },
      {
        name: "2 BAY",
        required: false,
        options: [
          {
            name: "SINGLE SIDE FIXED",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "2bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/fbae4541-85b8-4aa5-8790-28b6c5e404ca/7_5_2024/1717741306629/2-2bay-single-fixed.glb",
              },
            ],
          },
          {
            name: "SINGLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "2bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/eb1ddf42-2612-4ea7-ad8f-8ef1c0383bbc/6_5_2024/1717675446497/2-2bay-single-movable.glb",
              },
            ],
          },
          {
            name: "DOUBLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "2bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/27f5b143-d1e2-46f5-a577-0eed6264b18d/6_5_2024/1717675835070/2-2bay-double-movable.glb",
              },
            ],
          },
        ],
      },
      {
        name: "3 BAY",
        required: false,
        options: [
          {
            name: "SINGLE SIDE FIXED",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "3bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/a5ad4974-7b0d-4222-a8f0-26d65258590f/6_5_2024/1717684945933/2-3bay-single-fixed.glb",
              },
            ],
          },
          {
            name: "SINGLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "3bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/b675fb68-d96a-405e-af05-50281666409e/6_5_2024/1717684854364/2-3bay-single-movable.glb",
              },
            ],
          },
          {
            name: "DOUBLE SIDE MOVABLE",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: replacePart,
                node: "3bay_single_movable",
                pid: "https://content.helloviewer.io/uploads/db244d65-725c-4b0a-a535-7cff4cbe9225/6_5_2024/1717685020559/2-3bay-doube-movable.glb",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Primary Color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "1 BAY",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "2 BAY",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "3 BAY",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "main",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Secondary Color",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "1 BAY",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "2 BAY",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "3 BAY",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "movable",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  // {
  //   name: "Cushion",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "Pedestals",
  //       required: false,
  //       options: [
  //         {
  //           name: "None",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: hide,
  //               node: ["Cushion"],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Cushion",
  //           thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
  //           rules: [
  //             {
  //               type: show,
  //               node: ["Cushion"],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]);

export type ConfigData = typeof ConfData3;
