import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useAppDispatch } from "../hooks/hooks";
import { ordersConstants } from "../store/actions/types";
import Modal from "./Modal";
import closeIcon from "../assets/icons/close.svg";
import toast from "react-hot-toast";
import { createOrder, patchOrder } from "../utils/ordersApi";
import { Order } from "../types/types";
import { makeSessionRequest } from "../utils/makeSessionRequest";

export default function NewOrderForm({
  show,
  closeNewOrderForm,
  currentOrder,
}: {
  show: boolean;
  closeNewOrderForm: () => void;
  currentOrder: Order | null;
}) {
  const [formErrors, setFormErrors] = useState<any>();
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerLocation, setCustomerLocation] = useState("");
  const [leadId, setLeadId] = useState("");
  const [orderStatus, setOrderStatus] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [orderDescription, setOrderDescription] = useState<string | undefined>(
    undefined
  );

  const initialState: Partial<Order> = useMemo(
    () => ({
      cart_items: [],
      customer: {
        name: customerName,
        phone_number: customerPhoneNumber,
        email: customerEmail,
        location: customerLocation,
      },
      custom_data: {
        delivery_time: "",
        advance_payment: 0,
        company_name: "",
      },

      documents: {
        xls: "",
      },

      lead_id: leadId,

      _id: "",
      viewer: process.env.REACT_APP_VIEWER_ID,
      status: {
        status: 0,
        description: orderDescription,
      },
    }),
    [
      customerEmail,
      customerLocation,
      customerName,
      customerPhoneNumber,
      leadId,
      orderDescription,
    ]
  );

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    () => initialState
  );

  useEffect(() => {
    if (currentOrder) {
      setCustomerName(currentOrder.customer.name);
      setCustomerEmail(currentOrder.customer.email);
      setCustomerPhoneNumber(currentOrder.customer.phone_number);
      setCustomerLocation(currentOrder.customer.location);
      setOrderStatus(currentOrder.status.status);
      setOrderDescription(currentOrder.status.description);
      setLeadId(currentOrder.lead_id);
      currentOrder.custom_data?.company_name &&
        setCompanyName(currentOrder.custom_data?.company_name);
    }
  }, [currentOrder]);

  useEffect(() => {
    if (orderStatus !== 2) {
      setOrderDescription("");
    }
  }, [orderStatus]);

  function reducer(
    state: typeof initialState,
    action: {
      type: "SET_VALUES" | "RESET_FORM" | "SET_ORDER";
      payload?: { id?: any; value: any };
    }
  ) {
    switch (action.type) {
      case "SET_VALUES":
        return {
          ...state,
          [action?.payload?.id]: action?.payload?.value,
        };
      case "RESET_FORM":
        return initialState;
      case "SET_ORDER":
        return action?.payload?.value;

      default:
        return state;
    }
  }

  const dispatchRdx = useAppDispatch();

  // const user = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch({ type: "RESET_FORM" });
    if (currentOrder) {
      dispatch({
        type: "SET_ORDER",
        payload: {
          value: {
            ...currentOrder,
            //select filed doesn't reset
            status: currentOrder.status || 0,
          },
        },
      });
    }
  }, [currentOrder, initialState, show]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!customerName) {
      toast.error("Please enter your name");
      return;
    }

    if (!customerPhoneNumber.match(/([+]?\d[.91][.-\s]?)?(\d{3}?){2}\d{4}/)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    // if (!leadId) {
    //   toast.error("Please enter your Salesforce id");
    //   return;
    // }
    if (!companyName) {
      toast.error("Please enter Company Name");
      return;
    }
    if (
      !customerEmail.match(
        /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
      )
    ) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (!customerLocation) {
      toast.error("Please enter customer location");
      return;
    }
    const order: Order = {
      ...state,
      customer: {
        name: customerName,
        phone_number: customerPhoneNumber,
        email: customerEmail,
        location: customerLocation,
      },

      lead_id: leadId,
      custom_data: {
        company_name: companyName,
      },

      _id: state._id ? state._id : "",
      viewer: process.env.REACT_APP_VIEWER_ID,
      status: {
        status: orderStatus,
        description: orderDescription,
      },
    };
    makeSessionRequest(
      state._id ? "saved_quotation" : "created_quotation",
      state._id && {
        orderId: order._id,
      }
    );
    try {
      console.log(order);
      if (order._id) {
        console.log(order._id);
        const data = await patchOrder(order);
        dispatchRdx({
          type: ordersConstants.UPDATE_ORDER,
          payload: {
            ...order,
            created_at: data.created_at,
            updated_at: data.updated_at,
          },
        });
        toast.success("Order updated successfully");
      } else {
        delete order._id;
        const data = await createOrder(order);
        dispatchRdx({
          type: ordersConstants.ADD_ORDER,
          payload: {
            ...order,
            _id: data._id,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
          },
        });
        toast.success("Order created successfully");
      }

      dispatch({ type: "RESET_FORM" });
      setFormErrors(undefined);
      setCustomerName("");
      setCustomerEmail("");
      setCustomerPhoneNumber("");
      setCustomerLocation("");
      setLeadId("");
      closeNewOrderForm();
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error("Failed to create order");
    }
  };
  return (
    <div>
      <Modal show={show} onClose={() => null}>
        <form
          onSubmit={onSubmit}
          className="p-2 w-96 flex flex-col overflow-y-scroll"
          style={{ maxHeight: "85vh" }}
        >
          <div className="flex mb-4">
            <h1 className="font-bold text-lg ">
              {state._id ? "Edit Customer Details" : "New Customer Form"}
            </h1>

            <img
              className="ml-auto cursor-pointer"
              onClick={() => {
                setFormErrors(undefined);
                setCustomerName("");
                setCustomerEmail("");
                setCustomerPhoneNumber("");
                setCustomerLocation("");
                setLeadId("");
                setOrderStatus(0);
                setOrderDescription(undefined);
                dispatch({ type: "RESET_FORM" });
                dispatchRdx({
                  type: ordersConstants.SET_CURRENT_ORDER,
                  payload: null,
                });
                makeSessionRequest("quotation_closed");
                closeNewOrderForm();
              }}
              src={closeIcon}
              alt=""
            />
          </div>
          <p className="mb-2">Company Name: </p>
          <input
            className=" px-4 py-2 w-full focus:outline-none border mb-4"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <p className="mb-2">Point of Contact : </p>
          <input
            className=" px-4 py-2 w-full focus:outline-none border mb-4"
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <p className="mb-2">Point of Contact's Number: </p>
          <input
            className="px-4 py-2 w-full focus:outline-none border mb-4"
            inputMode="tel"
            value={customerPhoneNumber}
            onChange={(e) => setCustomerPhoneNumber(e.target.value)}
          />
          <p className="mb-2">Point of Contact's Email: </p>
          <input
            className="px-4 py-2 w-full focus:outline-none border mb-4"
            type="text"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
          />
          <p className="mb-2">Company Location: </p>
          <input
            className="px-4 py-2 w-full focus:outline-none border mb-4"
            type="text"
            value={customerLocation}
            onChange={(e) => setCustomerLocation(e.target.value)}
          />

          <p className="mb-2">CRM ID: </p>
          <input
            className="px-4 py-2 w-full focus:outline-none border mb-4"
            type="text"
            name="salesForceId"
            value={leadId}
            onChange={(e) => setLeadId(e.target.value)}
          />
          <p className="mb-2">Status: </p>
          <select
            onChange={(e) => setOrderStatus(parseInt(e.target.value))}
            value={orderStatus}
            className="px-4 py-2 w-full focus:outline-none border mb-4"
            name="status"
            id=""
          >
            <option value={0}>New</option>
            <option value={1}>Received</option>
            <option value={2}>Lost</option>
          </select>
          {orderStatus === 2 && (
            <>
              <p className="mb-2">Reason: </p>
              <input
                className="px-4 py-2 w-full focus:outline-none border mb-4"
                type="text"
                name="reason"
                value={orderDescription}
                onChange={(e) => setOrderDescription(e.target.value)}
              />
            </>
          )}
          {formErrors?.errors.map((err: string) => (
            <p key={err} className="text-red-500 text-sm">
              {err}
            </p>
          ))}
          <button className=" tracking-widest text-xs my-4  py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors">
            {state._id ? "Save" : "Add Customer"}
          </button>
        </form>
      </Modal>
    </div>
  );
}
