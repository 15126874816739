import React, { useEffect, useState } from "react";
import { ordersConstants } from "../store/actions/types";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import Modal from "./Modal";
import closeIcon from "../assets/icons/close.svg";
// import toast from "react-hot-toast";
import { generateQuote } from "../utils/generateQuote";
import { Order } from "../types/types";
import toast from "react-hot-toast";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import { getOrders } from "../utils/ordersApi";
// import { getIsSuperAdmin } from "../utils/getIsSuperAdmin";

export default function GenerateQuotationForm({
  show,
  closeForm,
}: {
  show: boolean;
  closeForm: () => void;
}) {
  const dispatch = useAppDispatch();
  const currentOrder: Order = useAppSelector(
    (state) => state.orders.currentOrder
  );

  const [discount, setDiscount] = useState<number | null>(null);
  const [freightAndDamage, setFreightAndDamage] = useState<number | null>(null);
  const [advancePayment, setAdvancePayment] = useState<number | null>(null);
  const [delivery, setDelivery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  // const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [generatedQuote, setGeneratedQuote] = useState<string | undefined>(
    undefined
  );
  const [isGenerateQuoteClicked, setIsGenerateQuoteClicked] = useState(false);

  useEffect(() => {
    setGeneratedQuote(
      currentOrder?.documents?.xls ? currentOrder?.documents?.xls : undefined
    );

    // setIsSuperAdmin(getIsSuperAdmin());
  }, [currentOrder]);

  useEffect(() => {
    if (!isGenerateQuoteClicked) {
      setAdvancePayment(null);
      setDiscount(null);
      setFreightAndDamage(null);
      setDelivery("");
    }
  }, [isGenerateQuoteClicked]);

  const onGenerateQuotation = async () => {
    if (!delivery) {
      toast.error("Please enter estimated delivery time");
      return;
    }
    setIsLoading(true);
    makeSessionRequest("generating_quotation", {
      orderId: currentOrder._id,
    });
    try {
      const updatedOrder = await generateQuote(
        currentOrder,
        discount || 0,
        freightAndDamage || 0,
        advancePayment || 0,
        delivery
      );
      const orders = (await getOrders()) || [];
      dispatch({ type: ordersConstants.SET_ORDERS, payload: orders._items });
      dispatch({
        type: ordersConstants.SET_CURRENT_ORDER,
        payload: updatedOrder,
      });
      makeSessionRequest("generated_quotation", {
        orderId: currentOrder._id,
      });
    } catch (error) {
      makeSessionRequest("generate_quotation_failed", {
        error,
        orderId: currentOrder._id,
      });
      console.log(error, "ERROR")
      toast.error(
        "Unable to generate quote. Please check with your Dealer if bank details have been added"
      );
    }
    setIsLoading(false);
    setIsGenerateQuoteClicked(false);
  };

  return (
    <div>
      <Modal show={show} onClose={() => null} type="quote">
        <form
          //   onSubmit={}
          className="p-2 w-96 flex flex-col items-center"
          style={{ maxHeight: "85vh" }}
        >
          <div className="flex mb-4 w-full">
            <h1 className="font-bold text-lg ">
              Generate Quotation for{" "}
              {currentOrder?.custom_data?.company_name ||
                currentOrder?.customer?.name}
            </h1>

            <img
              className="ml-auto cursor-pointer"
              onClick={() => {
                if (!isLoading) {
                  closeForm();
                  setIsGenerateQuoteClicked(false);
                  makeSessionRequest("closed_quotation_modal");
                  dispatch({
                    type: ordersConstants.SET_CURRENT_ORDER,
                    payload: null,
                  });
                }
              }}
              src={closeIcon}
              alt="close"
            />
          </div>
          {!isGenerateQuoteClicked ? (
            <div className="flex flex-col w-full">
              <button
                className={`tracking-widest text-xs my-4  py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors`}
                onClick={(e) => {
                  e.preventDefault();
                  setIsGenerateQuoteClicked(true);
                }}
              >
                {generatedQuote ? "REGENERATE QUOTATION" : "GENERATE QUOTATION"}
              </button>
              {generatedQuote && !isLoading ? (
                <a
                  href={generatedQuote}
                  onClick={() =>
                    makeSessionRequest("quotation_downloaded", {
                      orderId: currentOrder._id,
                    })
                  }
                  download
                  className={`tracking-widest text-xs my-4 cursor-pointer py-3 px-8 hover:bg-black bg-customBlack-light text-white text-center rounded-full font-bold transform transition-colors ${
                    generatedQuote === undefined || isLoading
                      ? "opacity-30"
                      : ""
                  }`}
                >
                  DOWNLOAD QUOTATION
                </a>
              ) : (
                <button
                  disabled
                  className={`tracking-widest text-xs my-4 cursor-pointer py-3 px-8 hover:bg-black bg-customBlack-light text-white text-center rounded-full font-bold transform transition-colors ${
                    generatedQuote === undefined || isLoading
                      ? "opacity-30"
                      : ""
                  }`}
                >
                  DOWNLOAD QUOTATION
                </button>
              )}
            </div>
          ) : (
            <div className="w-full flex flex-col">
              <p className="mb-2">Advance Payment Percentage: </p>
              <input
                className="px-4 py-2 w-full focus:outline-none border mb-4"
                type="number"
                value={advancePayment || ""}
                onChange={(e) => setAdvancePayment(Number(e.target.value))}
                min={0}
                max={100}
                disabled={isLoading}
                placeholder="0"
              />
              <p className="mb-2">Discount Percentage: </p>
              <input
                className="px-4 py-2 focus:outline-none border mb-4"
                type="number"
                min={0}
                max={100}
                value={discount || ""}
                onChange={(e) => setDiscount(Number(e.target.value))}
                disabled={isLoading}
                placeholder="0"
              />
              <p className="mb-2">Freight and Damage Percentage: </p>
              <input
                className="px-4 py-2 focus:outline-none border mb-4"
                type="number"
                min={0}
                max={100}
                value={freightAndDamage || ""}
                onChange={(e) => setFreightAndDamage(Number(e.target.value))}
                disabled={isLoading}
                placeholder="0"
              />
              <p className="mb-2">Estimated Delivery Time: </p>
              <input
                className="px-4 py-2 w-full focus:outline-none border mb-4"
                type="text"
                value={delivery}
                onChange={(e) => setDelivery(e.target.value)}
                disabled={isLoading}
              />

              <button
                disabled={isLoading}
                className={`tracking-widest text-xs my-4  py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors ${
                  isLoading ? "opacity-30" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  onGenerateQuotation();
                }}
              >
                {isLoading ? "GENERATING QUOTATION..." : "GENERATE QUOTE"}
              </button>
              <button
                disabled={isLoading}
                className={`tracking-widest text-xs my-4  py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors ${
                  isLoading ? "opacity-30" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setIsGenerateQuoteClicked(false);
                }}
              >
                BACK
              </button>
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
}
