import axios from "axios";
import { uuid } from "./uuid";

export const uploadFileToS3 = async (
  fileName: string,
  fileType: string,
  file: File
) => {
  const applicationId = process.env.REACT_APP_APPLICATION_ID;
  const uploadName =
    applicationId + "/" + uuid() + "/" + fileName.split(" ").join("-");

  const authToken = localStorage.getItem("token");

  const configGetS3Url = {
    url: `${process.env.REACT_APP_HELLOAR_API}signed_file`,
    params: {
      file_name: uploadName,
      file_type: fileType,
      application_id: applicationId,
    },
    headers: { Authorization: authToken },
  };
  try {
    const response = await axios.request(configGetS3Url);
    let formData = new FormData();
    let key;
    for (key in response.data.data.fields) {
      formData.append(key, response.data.data.fields[key]);
    }
    formData.append("file", file);

    await axios.post(response.data.data.url, formData);
    return response.data.uploaded_url;
  } catch (err) {
    throw new Error("Error while uploading content" + JSON.stringify(err));
  }
};
